import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { formatCurrency } from "../utils/formatters";
import { createPayment, getPaymentScheduleByCusID } from "../api/payment";
import { ReceiptDetails } from "../types/paymentsTypes";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { storage } from "../lib/configs/firebaseConfig";
import { removeBackgroundFromImage } from "../api/bgremover";
import dayjs, { Dayjs } from "dayjs";
import {
  getFinancialDataByCusNic,
  getInstallmentsCount,
} from "../api/schedule";

// Helper to upload a file to Firebase and return its download URL
const uploadImageToFirebase = async (imageUrl: string, imagePath: string) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const storageRef = ref(storage, imagePath);
    await uploadBytes(storageRef, blob);
    const downloadUrl = await getDownloadURL(storageRef);
    return downloadUrl;
  } catch (error) {
    console.error("Image upload failed:", error);
    throw error;
  }
};

// Helper to delete an image from Firebase
const deleteImageFromFirebase = async (imagePath: string) => {
  const storageRef = ref(storage, imagePath);
  try {
    await deleteObject(storageRef);
  } catch (error) {
    console.error(`Failed to delete image: ${imagePath}`, error);
  }
};

interface FinancialDataTypes {
  currentRemainingAmount: number;
  loanAmount: number;
  totalPaymentAmount: number;
}

export const useAddPayment = (scheduleCustomerNIC: string) => {
  const [customerNic, setCustomerNic] = useState(scheduleCustomerNIC || "");
  const [paidAmount, setPaidAmount] = useState("");
  const [isAddPaymentButtonEnabled, setIsAddPaymentButtonEnabled] =
    useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);
  const [isUploadSectionEnabled, setIsUploadSectionEnabled] = useState(false);
  const [loanDetails, setLoanDetails] = useState({
    scheduleId: 0,
    loanAmount: 0,
    overdueAmount: 0,
    installmentAmount: 0,
    additionalRateAmount: 0,
    repaymentFrequency: "",
    totalRemainingAmount: 0,
    paymentType: "",
    customerId: 0,
    address: "",
    fullName: "",
    customerImageURL: "",
    phoneNumber: "",
    releaseDate: "",
    minimumPayAmount: 0,
    fullLoanAmount: 0,
    lateInstallmentAmount: 0,
    interestAmount: 0,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isOpenQrScanner, setIsOpenQrScanner] = useState(false);
  const [, setQrData] = useState<string | null>(null);
  const [receiptDetails, setReceiptDetails] = useState<ReceiptDetails>({
    customer: "",
    nic: "",
    paymentDate: "",
    paymentTime: "",
    loanAmount: 0,
    totlaIntrestAmount: 0,
    paidAmount: 0,
    remainingAmount: 0,
    paymentType: "",
    repaymentFrequency: "",
    releaseDate: "",
    customerId: 0,
    minimumPayAmount: 0,
    address: "",
    fullLoanAmount: 0,
    lateInstallmentAmount: 0,
  });
  const [isReceiptModalVisible, setIsReceiptModalVisible] = useState(false);
  const [customerSignUrl, setCustomerSignUrl] = useState<string | null>(null);
  const [customerSignError, setCustomerSignError] = useState<string | null>(
    null
  );
  const [isCustomerSignVisible, setIsCustomerSignVisible] = useState(false);
  const [isClear] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCustomerUploadMode, setIsCustomerUploadMode] = useState(false);
  const [loadingImages, setLoadingImages] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [isDateChangedFirstTime, setIsDateChangedFirstTime] = useState(false);
  const [isOpenFreeInstallemnt, setIsOpenFreeIntallments] = useState(false);
  const [installmentsCount, setInstallmentCount] = useState<number>(0);
  const [installments, setInstallments] = useState(2);
  const [financialData, setFinancialData] = useState<FinancialDataTypes>();
  const [closeLoanAmount, setCloseLoanAmount] = useState<number>(0);
  const [getTotalPaymentAmount, setTotalPaymentAmount] = useState<number>(0);
  const [releaseScheduleList, setReleaseScheduleList] = useState<string[]>([]);

  // Set the default installments based on installmentCount
  useEffect(() => {
    if (installmentsCount !== undefined) {
      if (installmentsCount < 4) {
        setInstallments(1);
      } else {
        setInstallments(2);
      }
    }
  }, [installmentsCount]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInstallments(Number(e.target.value));
  };

  // get financial data for close loan
  const getFinancialDetails = async () => {
    const date = selectedDate.format("YYYY-MM-DDTHH:mm:ss.SSSSSS");
    const paymentType = loanDetails.paymentType;
    const token = localStorage.getItem("token") || "";
    try {
      const response = await getFinancialDataByCusNic(
        token,
        customerNic,
        date,
        paymentType,
        installments
      );

      if (response.success) {
        const newFinancialData = response.data;
        setFinancialData(newFinancialData.financialData);
        setCloseLoanAmount(
          newFinancialData.financialData.currentRemainingAmount
        );
        setTotalPaymentAmount(
          newFinancialData.financialData.totalPaymentAmount
        );
        // Update loanDetails with the currentRemainingAmount
        setLoanDetails((prevDetails) => ({
          ...prevDetails,
          totalRemainingAmount:
            newFinancialData.financialData.currentRemainingAmount || 0,
        }));
        setReleaseScheduleList(newFinancialData.scheduleDetails);
      }
    } catch (error) {
      console.log("error");
    } finally {
      handleOpenFreeInstallment();
      setIsUploadSectionEnabled(true);
    }
  };

  const handleOnChangeFirstTime = () => {
    setIsDateChangedFirstTime(true);
  };

  // Function to handle date changes, keeping selectedDate as a Dayjs object
  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      // Get the current time with microseconds
      const currentTimeWithMicroseconds = dayjs().format("HH:mm:ss.SSSSSS");

      // Format the updatedDate with the desired format
      const updatedDate = dayjs(
        newDate.format("YYYY-MM-DD") + "T" + currentTimeWithMicroseconds
      );

      setSelectedDate(updatedDate); // Keep updatedDate as a Dayjs object
    }
  };

  useEffect(() => {
    // Call getScheduleDetails only if selectedDate is set
    if (isDateChangedFirstTime) {
      getScheduleDetails();
    }
  }, [selectedDate]);

  // handle open qr scanner
  const handleOpenQrScanner = () => {
    setIsOpenQrScanner(true);
  };
  const handleCloseQrScanner = () => {
    setIsOpenQrScanner(false);
  };

  const handleInputChangecustomerId = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    const numericValue = value;

    if (value === "" || numericValue) {
      setCustomerNic(e.target.value);
      setPaidAmount("");
      setErrorMessage("");
      setIsUploadSectionEnabled(false);

      // Clear loan details and disable Add Payment button
      setLoanDetails({
        scheduleId: 0,
        loanAmount: 0,
        overdueAmount: 0,
        installmentAmount: 0,
        additionalRateAmount: 0,
        repaymentFrequency: "",
        totalRemainingAmount: 0,
        paymentType: "",
        customerId: 0,
        address: "",
        fullName: "",
        customerImageURL: "",
        phoneNumber: "",
        releaseDate: "",
        minimumPayAmount: 0,
        fullLoanAmount: 0,
        lateInstallmentAmount: 0,
        interestAmount: 0,
      });
      setIsAddPaymentButtonEnabled(false);
      setCustomerSignUrl("");
    }
  };

  const handleInputChangePaidAmount = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    const numericValue = Number(value);

    if (isNaN(numericValue)) {
      setErrorMessage("Invalid input. Please enter a valid number.");
      setPaidAmount(""); // Clear the paid amount if input is invalid
    } else if (value === "" || numericValue >= 0) {
      setPaidAmount(value);

      // Check if the entered amount exceeds the totalRemainingAmount
      if (numericValue > loanDetails.totalRemainingAmount) {
        setErrorMessage(
          `Invalid amount. The total remaining amount of your loan is ${formatCurrency(
            loanDetails.totalRemainingAmount
          )}`
        );
      }
      // Additional error: amount should be at least a minimum value (e.g., 10)
      else if (numericValue < loanDetails.minimumPayAmount) {
        setErrorMessage(
          `The amount should be at least ${formatCurrency(
            loanDetails.minimumPayAmount
          )}`
        );
      } else {
        setErrorMessage(""); // Clear the error message if valid
      }
    }
  };

  const handleAddPaymentClick = async () => {
    setCloseLoanAmount(0);
    setTotalPaymentAmount(0);
    setReleaseScheduleList([]);
    setIsUploadSectionEnabled(true);

    const token = localStorage.getItem("token") || "";

    try {
      const response = await getPaymentScheduleByCusID(
        customerNic,
        token,
        selectedDate.format("YYYY-MM-DDTHH:mm:ss.SSSSSS")
      );
      if (response.success) {
        setLoanDetails({
          ...response.data,
          loanAmount: response.data.loanAmount || 0,
          overdueAmount: response.data.overdueAmount || 0,
          installmentAmount: response.data.installmentAmount || 0,
          additionalRateAmount: response.data.additionalRateAmount || 0,
          totalRemainingAmount: response.data.totalRemainingAmount || 0,
          minimumPayAmount: response.data.minimumPayAmount || 0,
        });
        setIsAddPaymentButtonEnabled(true);
        setIsButtonEnabled(true);
        setIsUploadSectionEnabled(true); // Enable the upload section
      } else {
        toast.error(response.message);
        setLoanDetails({
          ...response.data,
          repaymentFrequency: "",
          minimumPayAmount: 0,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      // Set financialData.currentRemainingAmount to 0 and enable the input
      setFinancialData((prevData) => ({
        currentRemainingAmount: 0,
        loanAmount: prevData?.loanAmount || 0,
        totalPaymentAmount: prevData?.totalPaymentAmount || 0,
      }));
      setIsUploadSectionEnabled(true); // Ensure the input is enabled
    }
  };

  // get schedule details
  const getScheduleDetails = async () => {
    setCloseLoanAmount(0);

    if (!customerNic) {
      toast.error("Customer NIC is required");
      return;
    }

    const token = localStorage.getItem("token") || "";

    try {
      const response = await getPaymentScheduleByCusID(
        customerNic,
        token,
        selectedDate.format("YYYY-MM-DDTHH:mm:ss.SSSSSS")
      );
      if (response.success) {
        setLoanDetails({
          ...response.data,
          loanAmount: response.data.loanAmount || 0,
          overdueAmount: response.data.overdueAmount || 0,
          installmentAmount: response.data.installmentAmount || 0,
          additionalRateAmount: response.data.additionalRateAmount || 0,
          totalRemainingAmount: response.data.totalRemainingAmount || 0,
          minimumPayAmount: response.data.minimumPayAmount || 0,
        });
        toast.success(response.message);
        setIsAddPaymentButtonEnabled(true);
        setIsButtonEnabled(true);
        setIsUploadSectionEnabled(false);
        setPaidAmount("");
        setCustomerSignUrl("");
        setErrorMessage("");
      } else {
        toast.error(response.message);
        setLoanDetails({
          ...response.data,
          repaymentFrequency: "",
          minimumPayAmount: 0,
        });
        setIsAddPaymentButtonEnabled(false);
        setIsUploadSectionEnabled(false);
        setPaidAmount("");
      }
    } catch (error) {
      console.log(error);
      setLoanDetails({
        scheduleId: 0,
        loanAmount: 0,
        overdueAmount: 0,
        installmentAmount: 0,
        additionalRateAmount: 0,
        repaymentFrequency: "",
        totalRemainingAmount: 0,
        paymentType: "",
        customerId: 0,
        address: "",
        fullName: "",
        customerImageURL: "",
        phoneNumber: "",
        releaseDate: "",
        minimumPayAmount: 0,
        fullLoanAmount: 0,
        lateInstallmentAmount: 0,
        interestAmount: 0,
      });
      setIsAddPaymentButtonEnabled(false);
      setIsUploadSectionEnabled(false);
      setPaidAmount("");
    }
  };

  // handle create payment
  const handleCreatePayment = async () => {
    // Ensure signature is required
    if (!customerSignUrl) {
      toast.error("Customer signature is required.");
      return;
    }

    if (!paidAmount) {
      setErrorMessage("Paid amount is required");
      return;
    }

    if (Number(paidAmount) < 0) {
      setErrorMessage("Paid amount cannot be less than 0");
      toast.error("Paid amount cannot be less than 0");
      return;
    }

    // Start loading
    setIsLoading(true);

    const token = localStorage.getItem("token") || "";
    const userId = localStorage.getItem("userId");

    // Define request body
    const requestBody = {
      customerId: loanDetails.customerId,
      scheduleId: loanDetails.scheduleId,
      paidAmount: Number(paidAmount),
      remainingAmount: closeLoanAmount,
      paymentType: loanDetails.paymentType,
      scheduleDetails: releaseScheduleList,
      appUserId: Number(userId),
      pureRemainingLoanAmount: getTotalPaymentAmount || 0,
      signatureImageURL: customerSignUrl || "",
      date: selectedDate.format("YYYY-MM-DDTHH:mm:ss.SSSSSS"),
    };

    // Upload signature to Firebase first before creating payment
    let uploadedImageUrl = "";
    try {
      uploadedImageUrl = await uploadImageToFirebase(
        customerSignUrl as string,
        `Payments-signatures/${customerNic}_${Date.now()}.png`
      );
      requestBody.signatureImageURL = uploadedImageUrl;
    } catch (_error) {
      toast.error("Failed to upload signature. Please try again.");
      return;
    }

    try {
      const response = await createPayment(requestBody, token);
      if (response.success) {
        setIsUploadSectionEnabled(false);
        setLoanDetails({
          scheduleId: 0,
          loanAmount: 0,
          overdueAmount: 0,
          installmentAmount: 0,
          additionalRateAmount: 0,
          repaymentFrequency: "",
          totalRemainingAmount: 0,
          paymentType: "",
          customerId: 0,
          address: "",
          fullName: "",
          customerImageURL: "",
          phoneNumber: "",
          releaseDate: "",
          minimumPayAmount: 0,
          fullLoanAmount: 0,
          lateInstallmentAmount: 0,
          interestAmount: 0,
        });
        setCustomerNic("");
        setPaidAmount("");
        setIsAddPaymentButtonEnabled(false);
        setTotalPaymentAmount(0);
        setReleaseScheduleList([]);
        setReceiptDetails({
          customer: loanDetails.fullName,
          nic: customerNic,
          paymentDate: selectedDate.format("YYYY-MM-DD"),
          paymentTime: new Date().toLocaleTimeString(),
          loanAmount: loanDetails.loanAmount,
          paidAmount: Number(paidAmount),
          remainingAmount:
            loanDetails.totalRemainingAmount - Number(paidAmount),
          paymentType: loanDetails.paymentType,
          repaymentFrequency: loanDetails.repaymentFrequency,
          releaseDate:
            new Date(loanDetails.releaseDate).toLocaleDateString() || "",
          customerId: loanDetails.customerId,
          minimumPayAmount: loanDetails.minimumPayAmount,
          address: loanDetails.address,
          totlaIntrestAmount: loanDetails.interestAmount,
          fullLoanAmount: loanDetails.fullLoanAmount,
          lateInstallmentAmount: loanDetails.lateInstallmentAmount,
        });
        setIsReceiptModalVisible(true);
        setCustomerSignUrl("");
      } else {
        toast.error(response.message);

        // Delete the uploaded signature if payment creation fails
        if (uploadedImageUrl) {
          await deleteImageFromFirebase(uploadedImageUrl);
        }
      }
    } catch (_error) {
      toast.error("Payment failed");

      // Delete the uploaded signature if payment creation fails
      if (uploadedImageUrl) {
        await deleteImageFromFirebase(uploadedImageUrl);
      }
    } finally {
      // Stop loading after the process finishes
      setIsLoading(false);
      setCloseLoanAmount(0);
    }
  };

  // handle cancel button function
  const handleCancel = () => {
    setCustomerNic("");
    setPaidAmount("");
    setCloseLoanAmount(0);
    setTotalPaymentAmount(0);
    setReleaseScheduleList([]);
    setErrorMessage("");
    setLoanDetails({
      scheduleId: 0,
      loanAmount: 0,
      overdueAmount: 0,
      installmentAmount: 0,
      additionalRateAmount: 0,
      repaymentFrequency: "",
      totalRemainingAmount: 0,
      paymentType: "",
      customerId: 0,
      address: "",
      fullName: "",
      customerImageURL: "",
      phoneNumber: "",
      releaseDate: "",
      minimumPayAmount: 0,
      fullLoanAmount: 0,
      lateInstallmentAmount: 0,
      interestAmount: 0,
    });
    setIsAddPaymentButtonEnabled(false);
    setIsUploadSectionEnabled(false);
    setCustomerSignUrl("");
  };

  // handle get scan data
  const handleScanSuccess = (data: string | null) => {
    setQrData(data);
    if (data) {
      setCustomerNic(data);
      setLoanDetails({
        scheduleId: 0,
        loanAmount: 0,
        overdueAmount: 0,
        installmentAmount: 0,
        additionalRateAmount: 0,
        repaymentFrequency: "",
        totalRemainingAmount: 0,
        paymentType: "",
        customerId: 0,
        address: "",
        fullName: "",
        customerImageURL: "",
        phoneNumber: "",
        releaseDate: "",
        minimumPayAmount: 0,
        fullLoanAmount: 0,
        lateInstallmentAmount: 0,
        interestAmount: 0,
      });
      setIsAddPaymentButtonEnabled(false);
    }
  };

  // handle clear customer nic
  const handleClearNic = () => {
    setCustomerNic("");
    setPaidAmount("");
    setErrorMessage("");
    setLoanDetails({
      scheduleId: 0,
      loanAmount: 0,
      overdueAmount: 0,
      installmentAmount: 0,
      additionalRateAmount: 0,
      repaymentFrequency: "",
      totalRemainingAmount: 0,
      paymentType: "",
      customerId: 0,
      address: "",
      fullName: "",
      customerImageURL: "",
      phoneNumber: "",
      releaseDate: "",
      minimumPayAmount: 0,
      fullLoanAmount: 0,
      lateInstallmentAmount: 0,
      interestAmount: 0,
    });
    setIsAddPaymentButtonEnabled(false);
    setIsUploadSectionEnabled(false);
    setCustomerSignUrl("");
  };

  // Function to handle opening signature modal
  const handleSignatureModalOpen = () => {
    setIsCustomerSignVisible(true);
  };

  // Function to handle saving the customer signature URL only to localStorage
  const handleCustomerSignModalOk = (url: string | null) => {
    if (url) {
      setCustomerSignUrl(url);
      setCustomerSignError(null);
    } else {
      setCustomerSignError("Signature is required.");
    }

    setIsCustomerSignVisible(false);
  };

  // handle customer sign modal open
  const closeCustomerSignModal = () => {
    setIsCustomerSignVisible(false);
  };

  // Function to handle clearing the customer signature URL
  const handleCustomerSignModalClear = () => {
    if (customerSignUrl) {
      setCustomerSignUrl("");
      setCustomerSignError("Signature is required.");
    }
  };

  // Modify the function handling the checkbox change
  const handleCustomerUploadModeChange = (isChecked: boolean) => {
    setIsCustomerUploadMode(isChecked);
    localStorage.setItem("isCustomerUploadMode", JSON.stringify(isChecked));

    // Clear the signature URL or image URL when switching modes
    if (isChecked) {
      setCustomerSignUrl(""); // Clear signature URL if switching to upload mode
    } else {
      // Clear any uploaded image if switching to signature mode
      setCustomerSignUrl("");
    }
  };

  // Function to handle image selection and uploading
  const handleImageChangeSignatures = async (
    file: File,
    imageField: string
  ) => {
    try {
      setLoadingImages(true);

      // Convert the file to a Blob
      const blob = new Blob([file], { type: file.type });

      // Process the Blob to remove the background
      const processedBlob = await removeBackgroundFromImage(blob);

      // Create a URL from the processed Blob
      const imageUrl = URL.createObjectURL(processedBlob);

      // Upload the image URL to Firebase
      const uploadedUrl = await uploadImageToFirebase(
        imageUrl, // Pass the URL created from the Blob
        `signatures/${imageField}_${Date.now()}`
      );

      setCustomerSignUrl(uploadedUrl);
      setCustomerSignError(null);
    } catch (error) {
      setCustomerSignError("Failed to upload image. Please try again.");
      console.error("Image upload error:", error);
    } finally {
      setLoadingImages(false);
    }
  };

  // get installment count
  const getInstalllmentCount = async () => {
    const token = localStorage.getItem("token") || "";
    try {
      const response = await getInstallmentsCount(token, customerNic);
      setInstallmentCount(response);
    } catch (error) {
      console.log("error fetching installments count");
    }
  };

  // handle open free installment modal
  const handleOpenFreeInstallment = () => {
    setIsOpenFreeIntallments(!isOpenFreeInstallemnt);
    getInstalllmentCount();
    setIsUploadSectionEnabled(false);
    setPaidAmount("");
    setErrorMessage("");
    setCustomerSignUrl("");
  };

  // Add a useEffect hook to set paidAmount when financialData changes
  useEffect(() => {
    if (financialData && financialData.currentRemainingAmount !== undefined) {
      setPaidAmount(String(financialData.currentRemainingAmount));
    }
  }, [financialData]);

  return {
    customerNic,
    paidAmount,
    loanDetails,
    isAddPaymentButtonEnabled,
    isButtonEnabled,
    isUploadSectionEnabled,
    receiptDetails,
    isOpenQrScanner,
    errorMessage,
    isReceiptModalVisible,
    customerSignUrl,
    customerSignError,
    isCustomerSignVisible,
    isClear,
    isLoading,
    isCustomerUploadMode,
    loadingImages,
    selectedDate,
    isOpenFreeInstallemnt,
    installmentsCount,
    financialData,
    installments,
    handleOpenQrScanner,
    handleCloseQrScanner,
    handleInputChangecustomerId,
    handleInputChangePaidAmount,
    getScheduleDetails,
    handleCreatePayment,
    setIsReceiptModalVisible,
    handleClearNic,
    handleCancel,
    handleScanSuccess,
    handleAddPaymentClick,
    closeCustomerSignModal,
    handleCustomerSignModalOk,
    handleCustomerSignModalClear,
    handleSignatureModalOpen,
    setIsCustomerUploadMode,
    handleCustomerUploadModeChange,
    handleImageChangeSignatures,
    setSelectedDate,
    handleDateChange,
    handleOnChangeFirstTime,
    setIsOpenFreeIntallments,
    handleOpenFreeInstallment,
    setInstallmentCount,
    getFinancialDetails,
    handleInputChange,
  };
};
