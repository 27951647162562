import React from "react";
import {
  Button,
  Checkbox,
  Description,
  Input,
  Main,
  QRButton,
  SignatureModal,
  Title,
  UploadImageInput,
} from "../../common";
import { TbScan } from "react-icons/tb";
import { MdAddCircleOutline } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import { M_LastpaymentsSchedulesTable } from "../../Tables";
import { FreeInstallmentsCount, PaymentRecipt, QrScanner } from "../../modal";
import { IoCloseCircle } from "react-icons/io5";
import { AddPaymentPageProps } from "../../../types/paymentsTypes";
import { formatCurrency, formatDate } from "../../../utils/formatters";
import { useAddPayment } from "../../../hooks/useAddPayments";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const AddPaymentPage: React.FC<AddPaymentPageProps> = ({
  scheduleCustomerNIC,
}) => {
  const {
    customerNic,
    paidAmount,
    loanDetails,
    isAddPaymentButtonEnabled,
    isButtonEnabled,
    isUploadSectionEnabled,
    receiptDetails,
    isOpenQrScanner,
    errorMessage,
    isReceiptModalVisible,
    customerSignUrl,
    customerSignError,
    isCustomerSignVisible,
    isClear,
    isLoading,
    isCustomerUploadMode,
    loadingImages,
    selectedDate,
    isOpenFreeInstallemnt,
    installments,
    handleOpenQrScanner,
    handleCloseQrScanner,
    handleInputChangecustomerId,
    handleInputChangePaidAmount,
    getScheduleDetails,
    handleCreatePayment,
    setIsReceiptModalVisible,
    handleAddPaymentClick,
    handleCancel,
    handleClearNic,
    handleScanSuccess,
    closeCustomerSignModal,
    handleCustomerSignModalOk,
    handleCustomerSignModalClear,
    handleSignatureModalOpen,
    handleCustomerUploadModeChange,
    handleImageChangeSignatures,
    handleDateChange,
    handleOnChangeFirstTime,
    handleOpenFreeInstallment,
    getFinancialDetails,
    handleInputChange,
    financialData,
  } = useAddPayment(scheduleCustomerNIC);

  return (
    <div>
      {/* main title  */}
      <Title title={"Add Payment"} />

      {/* main container  */}
      <Main>
        {/* date picker  */}
        <div>
          <Description content={"Date"} />
          <DatePicker
            value={selectedDate}
            onChange={handleDateChange}
            format="YYYY-MM-DD"
            className="mb-5 w-[100%] xxs:max-w-72 py-2 bg-primaryColor-50 hover:bg-primaryColor-50 focus:outline-none focus-within:ring-0 hover:border-1 hover:border-primaryColor-100 focus-within:border-primaryColor-100 focus-within:bg-primaryColor-50"
            popupClassName=" z-10"
            maxDate={dayjs()}
            minDate={dayjs(formatDate(loanDetails.releaseDate))}
            onBlur={handleOnChangeFirstTime}
          />
        </div>

        {/* customer noc  */}
        <div className="flex items-center gap-2">
          <div className="w-[100%] md:w-[50%] group">
            <Input
              id="customerNic "
              name="customerNic "
              placeholder="Customer NIC"
              label="Customer NIC"
              type="text"
              labelWeight="font-semibold"
              value={customerNic}
              onChange={handleInputChangecustomerId}
              backIcon={
                <IoCloseCircle
                  className={`hidden mt-5 duration-300 lg:mt-6 ${
                    customerNic ? "group-hover:block " : ""
                  } `}
                  onClick={handleClearNic}
                />
              }
            />
          </div>
          <div className="mt-2 md:mt-[10px] flex gap-2">
            <QRButton
              icon={<IoSearch />}
              onClick={getScheduleDetails}
              hideTextBeforeMd={true}
              disabled={!isButtonEnabled} // Disable if customerNic is empty
            />
            <QRButton
              icon={<TbScan />}
              onClick={handleOpenQrScanner}
              text="Scan"
              hideTextBeforeMd={true}
              bgColor="bg-yellow-500"
            />
          </div>
        </div>

        {/* loan details  */}
        <div className="gap-5 md:grid md:grid-cols-2">
          <div>
            <Input
              id="paymentType"
              name="paymentType"
              placeholder="Payment Type"
              label="Payment Type"
              type="text"
              labelWeight="font-semibold"
              value={loanDetails.paymentType}
              width="w-[100%] "
              disabled
            />

            <Input
              id="loanAmount"
              name="loanAmount"
              placeholder="Rs:"
              label="Loan Amount (Rs.)"
              type="text"
              labelWeight="font-semibold"
              value={formatCurrency(loanDetails.loanAmount)}
              width="w-[100%] "
              disabled
            />
            <Input
              id="repaymentFrequency"
              name="repaymentFrequency"
              placeholder={loanDetails.repaymentFrequency ? "" : "Payment Plan"}
              label="Payment Plan"
              type="text"
              width={`w-[100%]  ${
                loanDetails.repaymentFrequency ? "font-bold" : ""
              }`}
              labelWeight="font-semibold"
              value={loanDetails.repaymentFrequency}
              disabled
            />
            <Input
              id="installmentAmount"
              name="installmentAmount"
              placeholder=""
              label="Installment Amount (Rs.)"
              type="text"
              width="w-[100%] "
              labelWeight="font-semibold"
              value={formatCurrency(loanDetails.installmentAmount)}
              disabled
            />
            <div
              className={`${loanDetails.additionalRateAmount ? "" : "hidden"}`}
            >
              <Input
                id="additionalRateAmount"
                name="additionalRateAmount"
                placeholder=""
                label="Late Payment Fee (Rs.)"
                type="text"
                width="w-[100%] "
                labelWeight="font-semibold"
                value={formatCurrency(loanDetails.additionalRateAmount)}
                disabled
              />
            </div>

            <div className={`${loanDetails.overdueAmount ? "" : "hidden"}`}>
              <Input
                id="overdueAmount"
                name="overdueAmount"
                placeholder=""
                label="Overdue Amount (Rs.)"
                type="text"
                width="w-[100%] "
                labelWeight="font-semibold"
                value={formatCurrency(loanDetails.overdueAmount)}
                disabled
              />
            </div>

            <div
              className={`${loanDetails.totalRemainingAmount ? "" : "hidden"}`}
            >
              <Input
                id="totalRemainingAmount"
                name="totalRemainingAmount"
                placeholder=""
                label="Total Remaining Amount (Rs.)"
                type="text"
                width="w-[100%] "
                labelWeight="font-semibold"
                value={formatCurrency(loanDetails.totalRemainingAmount)}
                disabled
              />
            </div>
          </div>

          {/* tables  */}
          <div
            className={`max-md:mb-4 ${loanDetails.loanAmount ? "" : "hidden"} `}
          >
            {/* customer details  */}
            <div className="flex items-center gap-3 p-1 bg-gray-300 rounded-lg shadow-3xl md:mb-3">
              {/* customer image  */}
              <img
                src={loanDetails.customerImageURL}
                alt="customer-image"
                width={100}
                height={100}
              />

              <div>
                {/* customer name  */}
                <div className="flex space-x-1">
                  <div className="font-semibold text-h7">නම : </div>
                  <div className="font-semibold text-h7">
                    {loanDetails.fullName}
                  </div>
                </div>
                {/* customer phone number  */}
                <div className="flex space-x-1">
                  <div className="font-semibold text-h7">දු.ක : </div>
                  <div className="font-semibold text-h7">
                    {loanDetails.phoneNumber}
                  </div>
                </div>
                {/* customer address  */}
                <div className="flex space-x-1">
                  <div className="font-semibold text-h7">
                    ණය නිකුත් කළ දිනය :
                  </div>
                  <div className="font-semibold text-h7">
                    {new Date(loanDetails.releaseDate).toLocaleDateString()}
                  </div>
                </div>
              </div>
            </div>
            {/* payment details table  */}
            <M_LastpaymentsSchedulesTable
              customerId={loanDetails.customerId}
              getCustomerPaymentsDetails={getScheduleDetails}
            />
          </div>
        </div>
        <div
          className={`flex justify-center mt-3 gap-3 ${
            isAddPaymentButtonEnabled ? "" : "opacity-40"
          }`}
        >
          <Button
            text="Add Payment"
            icon={<MdAddCircleOutline />}
            onClick={handleAddPaymentClick}
            disabled={!isAddPaymentButtonEnabled}
          />

          <Button
            text="Close Loan"
            onClick={handleOpenFreeInstallment}
            disabled={!isAddPaymentButtonEnabled}
          />
        </div>
      </Main>

      <div>
        <Main margin>
          <div
            className={`text-center ${
              isUploadSectionEnabled ? "" : "opacity-40 pointer-events-none"
            }`}
          >
            <Title title="Paid Amount" bgStyle={false} />
          </div>

          <div
            className={`space-y-4 ${
              isUploadSectionEnabled ? "" : "opacity-40 pointer-events-none"
            }`}
          >
            <div className="md:max-w-[50%] mx-auto">
              <Input
                id="paidAmount"
                name="paidAmount"
                placeholder="Enter the amount you have paid"
                label=""
                type="number"
                width="w-[100%]"
                labelWeight="font-semibold"
                value={paidAmount}
                onChange={handleInputChangePaidAmount}
                errorMessage={errorMessage}
                disabled={
                  financialData?.currentRemainingAmount !== 0 ||
                  !isUploadSectionEnabled
                }
              />
            </div>

            {/* Customer Signature */}
            <div>
              <Description content={"Customer Signature"} />
              <Checkbox
                onChange={(e) =>
                  handleCustomerUploadModeChange(e.target.checked)
                }
                checked={isCustomerUploadMode}
                label="Upload Image"
              />

              {isCustomerUploadMode ? (
                // Image upload input appears when the checkbox is checked
                <UploadImageInput
                  id={"customerSignatureImageURL"}
                  name={"customerSignatureImageURL"}
                  text={""}
                  placeholder={""}
                  onFileSelect={(file) => {
                    if (file) {
                      handleImageChangeSignatures(
                        file,
                        "customerSignatureImageURL"
                      );
                    }
                  }}
                  selectImage={customerSignUrl}
                  isLoading={loadingImages}
                />
              ) : (
                <div
                  className="h-40 p-2 bg-white border-2 rounded-lg cursor-pointer max-w-[100%] border-primaryColor-200"
                  onClick={handleSignatureModalOpen}
                >
                  {customerSignUrl ? (
                    <img
                      src={customerSignUrl}
                      className="object-contain w-full h-full"
                    />
                  ) : (
                    <div className="flex items-center justify-center h-full underline duration-300 text-primaryColor-200 hover:scale-105">
                      <Description
                        content="Sign here"
                        fontWeight="font-medium"
                        fontColor="text-primaryColor-200"
                        center
                      />
                    </div>
                  )}
                  {customerSignError && (
                    <div className="font-normal mt-2 text-left text-red-500 font-poppins text-[12px] md:text-[13px]">
                      {customerSignError}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div
            className={`flex justify-center gap-3 mt-5 ${
              isUploadSectionEnabled ? "" : "opacity-40 pointer-events-none"
            }`}
          >
            <Button
              text="Done"
              onClick={handleCreatePayment}
              width="w-28 md:w-40"
              isLoading={isLoading}
              disabled={
                !paidAmount ||
                isLoading ||
                !customerSignUrl ||
                errorMessage !== "" ||
                Number(paidAmount) < loanDetails.minimumPayAmount
              }
            />

            <Button
              text="Cancel"
              variant="secondary"
              onClick={handleCancel}
              width="w-28 md:w-40"
              disabled={isLoading}
            />
          </div>
        </Main>
      </div>

      {/* qr scanner  */}
      <QrScanner
        visible={isOpenQrScanner}
        onClose={handleCloseQrScanner}
        onScanSuccess={handleScanSuccess}
      />
      {isReceiptModalVisible && (
        <PaymentRecipt
          visible={isReceiptModalVisible}
          onClose={() => setIsReceiptModalVisible(false)}
          primaryBtnLabel="Download Receipt"
          primaryOnClick={() => {
            // Logic to download receipt as a PDF
            const element = document.createElement("a");
            const file = new Blob([JSON.stringify(receiptDetails, null, 2)], {
              type: "application/json",
            });
            element.href = URL.createObjectURL(file);
            element.download = "receipt.json";
            document.body.appendChild(element);
            element.click();
          }}
          receiptDetails={receiptDetails}
        />
      )}

      {/*customer Signature modal */}
      <SignatureModal
        visible={isCustomerSignVisible}
        onClose={closeCustomerSignModal}
        onOk={handleCustomerSignModalOk}
        signurl={customerSignUrl || ""}
        onClear={handleCustomerSignModalClear}
        isClear={isClear}
      />

      {/* installemtns to be released  */}
      <FreeInstallmentsCount
        visible={isOpenFreeInstallemnt}
        onClose={handleOpenFreeInstallment}
        secondaryOnClick={handleOpenFreeInstallment}
        onChange={handleInputChange}
        value={String(installments)}
        primaryOnClick={getFinancialDetails}
      />
    </div>
  );
};

export default AddPaymentPage;
