import React, { useEffect, useState } from "react";
import { Main } from "../../common";
import SettingModal from "../../common/modals/SettingModal";
import PlanSettingsCard from "../../common/cards/PlanSettingsCard";
import {
  getLoanDurations,
  getLoanrate,
  postLoanDurations,
  postLoanRate,
} from "../../../api/loan";
import toast from "react-hot-toast";
import { A_SystemRegularRates } from "../../Tables";

interface LoanDuration {
  id: number;
  day: number;
  month: number;
  week: number;
}

interface LoanRateItem {
  rateId: string;
  name: string;
  rate: string;
}

interface postLoanDataProps {
  rateId: number;
  name: string;
  rate: number;
}

type LoanRateState = {
  RegularRateId: string;
  RegularRateName: string;
  RegularRateValue: string;
  DayLatePaymentRateId: string;
  DayLatePaymentRateName: string;
  DayLatePaymentRateValue: string;
  WeekLatePaymentRateId: string;
  WeekLatePaymentRateName: string;
  WeekLatePaymentRateValue: string;
  MonthLatePaymentRateId: string;
  MonthLatePaymentRateName: string;
  MonthLatePaymentRateValue: string;
  DayOverdueDayPaymentRateId: string;
  DayOverdueDayPaymentRateName: string;
  DayOverdueDayPaymentRateValue: string;
  WeekOverdueDayPaymentRateId: string;
  WeekOverdueDayPaymentRateName: string;
  WeekOverdueDayPaymentRateValue: string;
  MonthOverdueDayPaymentRateId: string;
  MonthOverdueDayPaymentRateName: string;
  MonthOverdueDayPaymentRateValue: string;
};

const A_SystemSettingspage: React.FC = () => {
  const [isDailyModalVisible, setIsDailyModalVisible] = useState(false);
  const [isWeeklyModalVisible, setIsWeeklyModalVisible] = useState(false);
  const [isMonthlyModalVisible, setIsMonthlyModalVisible] = useState(false);
  const [isRateModalVisible, setIsRateModalVisible] = useState(false);
  const [
    isDayLatePaymentRateModalVisible,
    setIsDayLatePaymentRateModalVisible,
  ] = useState(false);
  const [
    isWeekLatePaymentRateModalVisible,
    setIsWeekLatePaymentRateModalVisible,
  ] = useState(false);
  const [
    isMonthLatePaymentRateModalVisible,
    setIsMonthLatePaymentRateModalVisible,
  ] = useState(false);

  const [
    isOverdueDayPaymentRateModalVisible,
    setIsOverdueDayPaymentRateModalVisible,
  ] = useState(false);
  const [
    isOverdueWeekPaymentRateModalVisible,
    setIsOverdueWeekPaymentRateModalVisible,
  ] = useState(false);
  const [
    isOverdueMonthPaymentRateModalVisible,
    setIsOverdueMonthPaymentRateModalVisible,
  ] = useState(false);

  const handleOpenDailyModal = () => {
    setIsDailyModalVisible(true);
  };
  const handleCloseDailyModal = () => {
    setIsDailyModalVisible(false);
  };
  const handleOpenWeeklyModal = () => {
    setIsWeeklyModalVisible(true);
  };
  const handleCloseWeeklyModal = () => {
    setIsWeeklyModalVisible(false);
  };
  const handleOpenMonthlyModal = () => {
    setIsMonthlyModalVisible(true);
  };
  const handleCloseMonthlyModal = () => {
    setIsMonthlyModalVisible(false);
  };

  const handleCloseRegularRateModal = () => {
    setIsRateModalVisible(false);
  };

  const handleOpenDayLatePaymentRateModal = () => {
    setIsDayLatePaymentRateModalVisible(true);
  };
  const handleOpenWeekLatePaymentRateModal = () => {
    setIsWeekLatePaymentRateModalVisible(true);
  };
  const handleOpenMonthLatePaymentRateModal = () => {
    setIsMonthLatePaymentRateModalVisible(true);
  };
  const handleCloseDayLatePaymentRateModal = () => {
    setIsDayLatePaymentRateModalVisible(false);
  };
  const handleCloseWeekLatePaymentRateModal = () => {
    setIsWeekLatePaymentRateModalVisible(false);
  };
  const handleCloseMonthLatePaymentRateModal = () => {
    setIsMonthLatePaymentRateModalVisible(false);
  };
  const handleOpenOverdueDayPaymentRateModal = () => {
    setIsOverdueDayPaymentRateModalVisible(true);
  };
  const handleOpenOverdueWeekPaymentRateModal = () => {
    setIsOverdueWeekPaymentRateModalVisible(true);
  };
  const handleOpenOverdueMonthPaymentRateModal = () => {
    setIsOverdueMonthPaymentRateModalVisible(true);
  };
  const handleCloseOverdueDayPaymentRateModal = () => {
    setIsOverdueDayPaymentRateModalVisible(false);
  };
  const handleCloseOverdueWeekPaymentRateModal = () => {
    setIsOverdueWeekPaymentRateModalVisible(false);
  };
  const handleCloseOverdueMonthPaymentRateModal = () => {
    setIsOverdueMonthPaymentRateModalVisible(false);
  };

  const [loanDurations, setloanDurations] = useState({
    day: "",
    month: "",
    week: "",
    id: "",
  });

  const [loanRate, setLoanRate] = useState<LoanRateState>({
    RegularRateId: "",
    RegularRateName: "",
    RegularRateValue: "",
    DayLatePaymentRateId: "",
    DayLatePaymentRateName: "",
    DayLatePaymentRateValue: "",
    WeekLatePaymentRateId: "",
    WeekLatePaymentRateName: "",
    WeekLatePaymentRateValue: "",
    MonthLatePaymentRateId: "",
    MonthLatePaymentRateName: "",
    MonthLatePaymentRateValue: "",
    DayOverdueDayPaymentRateId: "",
    DayOverdueDayPaymentRateName: "",
    DayOverdueDayPaymentRateValue: "",
    WeekOverdueDayPaymentRateId: "",
    WeekOverdueDayPaymentRateName: "",
    WeekOverdueDayPaymentRateValue: "",
    MonthOverdueDayPaymentRateId: "",
    MonthOverdueDayPaymentRateName: "",
    MonthOverdueDayPaymentRateValue: "",
  });

  useEffect(() => {
    fetchLoanDurations();
    fetchLoanRate();
  }, []);

  const fetchLoanDurations = async () => {
    const token = localStorage.getItem("token") || "";

    try {
      const response = await getLoanDurations(token);
      if (response) {
        setloanDurations({
          day: response[0].day,
          month: response[0].month,
          week: response[0].week,
          id: response[0].id,
        });
      } else {
        console.log("No response data for loan Durations");
      }
    } catch (error) {
      console.log(" get data error -", error);
    }
  };

  const fetchLoanRate = async () => {
    const token = localStorage.getItem("token") || "";

    try {
      const response: LoanRateItem[] = await getLoanrate(token);
      console.log("interest rates ", response);
      if (response) {
        const newLoanRate: Partial<LoanRateState> = {};

        response.forEach((rate) => {
          if (rate.name === "RegularRate") {
            newLoanRate.RegularRateId = rate.rateId;
            newLoanRate.RegularRateName = rate.name;
            newLoanRate.RegularRateValue = rate.rate;
          } else if (rate.name === "DayLatePaymentRate") {
            newLoanRate.DayLatePaymentRateId = rate.rateId;
            newLoanRate.DayLatePaymentRateName = rate.name;
            newLoanRate.DayLatePaymentRateValue = rate.rate;
          } else if (rate.name === "WeekLatePaymentRate") {
            newLoanRate.WeekLatePaymentRateId = rate.rateId;
            newLoanRate.WeekLatePaymentRateName = rate.name;
            newLoanRate.WeekLatePaymentRateValue = rate.rate;
          } else if (rate.name === "MonthLatePaymentRate") {
            newLoanRate.MonthLatePaymentRateId = rate.rateId;
            newLoanRate.MonthLatePaymentRateName = rate.name;
            newLoanRate.MonthLatePaymentRateValue = rate.rate;
          } else if (rate.name === "OverdueDayPaymentRate") {
            newLoanRate.DayOverdueDayPaymentRateId = rate.rateId;
            newLoanRate.DayOverdueDayPaymentRateName = rate.name;
            newLoanRate.DayOverdueDayPaymentRateValue = rate.rate;
          } else if (rate.name === "OverdueWeekPaymentRate") {
            newLoanRate.WeekOverdueDayPaymentRateId = rate.rateId;
            newLoanRate.WeekOverdueDayPaymentRateName = rate.name;
            newLoanRate.WeekOverdueDayPaymentRateValue = rate.rate;
          } else if (rate.name === "OverdueMonthPaymentRate") {
            newLoanRate.MonthOverdueDayPaymentRateId = rate.rateId;
            newLoanRate.MonthOverdueDayPaymentRateName = rate.name;
            newLoanRate.MonthOverdueDayPaymentRateValue = rate.rate;
          }
        });

        setLoanRate((prevState) => ({
          ...prevState,
          ...newLoanRate,
        }));
      } else {
        console.log("No response data for loan rates");
      }
    } catch (error) {
      console.log(" get data error -", error);
    }
  };

  const handleChangeLoanDurations = async (
    planName: string,
    planValue: number
  ) => {
    const rest: LoanDuration = {
      id: parseInt(loanDurations.id),
      day: parseInt(loanDurations.day),
      month: parseInt(loanDurations.month),
      week: parseInt(loanDurations.week),
    };

    if (planName === "DAILY") {
      rest.day = planValue;
    } else if (planName === "WEEKLY") {
      rest.week = planValue;
    } else if (planName === "MONTHLY") {
      rest.month = planValue;
    }

    const token = localStorage.getItem("token") || "";

    try {
      await postLoanDurations(rest, token);
      console.log("response");
      toast.success("Loan Duration Change Successful");
    } catch (error) {
      console.error("API Error:", error);
      toast.error("Loan Duration Change failed!");
    }

    setIsMonthlyModalVisible(false);
    setIsWeeklyModalVisible(false);
    setIsDailyModalVisible(false);
    fetchLoanDurations();
  };

  const handleChangeLoanRate = async (planName: string, planValue: number) => {
    let rest: postLoanDataProps | undefined;

    if (planName === "RegularRate") {
      rest = {
        rateId: Number(loanRate.RegularRateId),
        name: loanRate.RegularRateName,
        rate: planValue,
      };
    } else if (planName === "DayLatePaymentRate") {
      rest = {
        rateId: Number(loanRate.DayLatePaymentRateId),
        name: loanRate.DayLatePaymentRateName,
        rate: planValue,
      };
    } else if (planName === "WeekLatePaymentRate") {
      rest = {
        rateId: Number(loanRate.WeekLatePaymentRateId),
        name: loanRate.WeekLatePaymentRateName,
        rate: planValue,
      };
    } else if (planName === "MonthLatePaymentRate") {
      rest = {
        rateId: Number(loanRate.MonthLatePaymentRateId),
        name: loanRate.MonthLatePaymentRateName,
        rate: planValue,
      };
    } else if (planName === "OverdueDayPaymentRate") {
      rest = {
        rateId: Number(loanRate.DayOverdueDayPaymentRateId),
        name: loanRate.DayOverdueDayPaymentRateName,
        rate: planValue,
      };
    } else if (planName === "OverdueWeekPaymentRate") {
      rest = {
        rateId: Number(loanRate.WeekOverdueDayPaymentRateId),
        name: loanRate.WeekOverdueDayPaymentRateName,
        rate: planValue,
      };
    } else if (planName === "OverdueMonthPaymentRate") {
      rest = {
        rateId: Number(loanRate.MonthOverdueDayPaymentRateId),
        name: loanRate.MonthOverdueDayPaymentRateName,
        rate: planValue,
      };
    }

    const token = localStorage.getItem("token") || "";

    if (rest) {
      try {
        const response = await postLoanRate(rest, token);
        if (response) {
          toast.success("Rate Change Successful");
        }
      } catch (error) {
        console.error("API Error:", error);
        toast.error("Rate Change failed");
      }
      setIsRateModalVisible(false);
      setIsDayLatePaymentRateModalVisible(false);
      setIsWeekLatePaymentRateModalVisible(false);
      setIsMonthLatePaymentRateModalVisible(false);
      setIsOverdueDayPaymentRateModalVisible(false);
      setIsOverdueWeekPaymentRateModalVisible(false);
      setIsOverdueMonthPaymentRateModalVisible(false);
      fetchLoanRate();
    } else {
      toast.error("Invalid rate data");
    }
  };

  return (
    <>
      <Main>
        <div>
          <div className="flex flex-col gap-5">
            <div className="flex flex-wrap items-center justify-center w-full gap-5 duration-300 ">
              <PlanSettingsCard
                mainTitle="Daily Plan"
                content={loanDurations.day}
                keyValue="Days"
                primaryOnClickPlan={handleOpenDailyModal}
                latePaymentInterest={loanRate.DayLatePaymentRateValue}
                OverduePaymentInterest={loanRate.DayOverdueDayPaymentRateValue}
                primaryOnClickLatePayment={handleOpenDayLatePaymentRateModal}
                primaryOnClickOverDuePayments={
                  handleOpenOverdueDayPaymentRateModal
                }
              />
              <PlanSettingsCard
                mainTitle="Weekly Plan"
                content={loanDurations.week}
                keyValue="Weeks"
                primaryOnClickPlan={handleOpenWeeklyModal}
                latePaymentInterest={loanRate.WeekLatePaymentRateValue}
                OverduePaymentInterest={loanRate.WeekOverdueDayPaymentRateValue}
                primaryOnClickLatePayment={handleOpenWeekLatePaymentRateModal}
                primaryOnClickOverDuePayments={
                  handleOpenOverdueWeekPaymentRateModal
                }
              />
              <PlanSettingsCard
                mainTitle="Monthly Plan"
                content={loanDurations.month}
                keyValue="Months"
                primaryOnClickPlan={handleOpenMonthlyModal}
                latePaymentInterest={loanRate.MonthLatePaymentRateValue}
                OverduePaymentInterest={
                  loanRate.MonthOverdueDayPaymentRateValue
                }
                primaryOnClickLatePayment={handleOpenMonthLatePaymentRateModal}
                primaryOnClickOverDuePayments={
                  handleOpenOverdueMonthPaymentRateModal
                }
              />
            </div>
          </div>
        </div>

        {/* regular rates  */}
        <div>
          <A_SystemRegularRates />
        </div>
      </Main>
      <SettingModal
        visible={isRateModalVisible}
        onClose={handleCloseRegularRateModal}
        mainTitle="Edit Regular Interest Rate"
        primaryBtnLabel="Save"
        primaryOnClick={handleChangeLoanRate}
        closeIcon
        planName="RegularRate"
        inputLoanValue={loanRate.RegularRateValue}
      />

      <SettingModal
        visible={isDayLatePaymentRateModalVisible}
        onClose={handleCloseDayLatePaymentRateModal}
        mainTitle="Edit Late Payment Interest Rate"
        primaryBtnLabel="Save"
        primaryOnClick={handleChangeLoanRate}
        closeIcon
        planName="DayLatePaymentRate"
        inputLoanValue={loanRate.DayLatePaymentRateValue}
      />

      <SettingModal
        visible={isWeekLatePaymentRateModalVisible}
        onClose={handleCloseWeekLatePaymentRateModal}
        mainTitle="Edit Late Payment Interest Rate"
        primaryBtnLabel="Save"
        primaryOnClick={handleChangeLoanRate}
        closeIcon
        planName="WeekLatePaymentRate"
        inputLoanValue={loanRate.WeekLatePaymentRateValue}
      />

      <SettingModal
        visible={isMonthLatePaymentRateModalVisible}
        onClose={handleCloseMonthLatePaymentRateModal}
        mainTitle="Edit Late Payment Interest Rate"
        primaryBtnLabel="Save"
        primaryOnClick={handleChangeLoanRate}
        closeIcon
        planName="MonthLatePaymentRate"
        inputLoanValue={loanRate.MonthLatePaymentRateValue}
      />

      <SettingModal
        visible={isOverdueDayPaymentRateModalVisible}
        onClose={handleCloseOverdueDayPaymentRateModal}
        mainTitle="Edit Overdue Payment Interest"
        primaryBtnLabel="Save"
        primaryOnClick={handleChangeLoanRate}
        closeIcon
        planName="OverdueDayPaymentRate"
        inputLoanValue={loanRate.DayOverdueDayPaymentRateValue}
      />

      <SettingModal
        visible={isOverdueWeekPaymentRateModalVisible}
        onClose={handleCloseOverdueWeekPaymentRateModal}
        mainTitle="Edit Overdue Payment Interest"
        primaryBtnLabel="Save"
        primaryOnClick={handleChangeLoanRate}
        closeIcon
        planName="OverdueWeekPaymentRate"
        inputLoanValue={loanRate.WeekOverdueDayPaymentRateValue}
      />

      <SettingModal
        visible={isOverdueMonthPaymentRateModalVisible}
        onClose={handleCloseOverdueMonthPaymentRateModal}
        mainTitle="Edit Overdue Payment Interest"
        primaryBtnLabel="Save"
        primaryOnClick={handleChangeLoanRate}
        closeIcon
        planName="OverdueMonthPaymentRate"
        inputLoanValue={loanRate.MonthOverdueDayPaymentRateValue}
      />

      <SettingModal
        visible={isDailyModalVisible}
        onClose={handleCloseDailyModal}
        mainTitle="Edit Daily Loan Plan"
        primaryBtnLabel="Save"
        primaryOnClick={handleChangeLoanDurations}
        closeIcon
        planName="DAILY"
        inputLoanValue={loanDurations.day}
      />
      <SettingModal
        visible={isWeeklyModalVisible}
        onClose={handleCloseWeeklyModal}
        mainTitle="Edit Weekly Loan Plan"
        primaryBtnLabel="Save"
        primaryOnClick={handleChangeLoanDurations}
        closeIcon
        planName="WEEKLY"
        inputLoanValue={loanDurations.week}
      />
      <SettingModal
        visible={isMonthlyModalVisible}
        onClose={handleCloseMonthlyModal}
        mainTitle="Edit Monthly Loan Plan"
        primaryBtnLabel="Save"
        primaryOnClick={handleChangeLoanDurations}
        closeIcon
        planName="MONTHLY"
        inputLoanValue={loanDurations.month}
      />
    </>
  );
};

export default A_SystemSettingspage;
