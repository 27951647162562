/* eslint-disable react/prop-types */
import React, {
  FocusEventHandler,
  forwardRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { MdDone } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";

interface InputProps {
  saveCancel?: boolean;
  readOnly?: boolean;
  id: string;
  top?: boolean;
  name: string;
  frontIcon?: ReactNode;
  backIcon?: ReactNode;
  type?: string;
  label?: string;
  width?: string;
  labelWeight?: "font-bold" | "font-medium" | "font-semibold";
  placeholder: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  errorMessage?: string;
  disabled?: boolean;
  buttonIcon?: ReactNode;
  saveOnclick?: () => void;
  cancelOnclick?: () => void;
  onButtonClick?: (isEnabled: boolean) => void;
  minValue?: string;
  bgColor?: "bg-primaryColor-50" | "bg-white";
  handleNegativeMark?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  restrictSpaces?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

// eslint-disable-next-line react/display-name
const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      name,
      type = "text",
      frontIcon,
      top,
      backIcon,
      label,
      width = "",
      labelWeight = "font-semibold",
      onChange,
      placeholder,
      value,
      errorMessage,
      disabled,
      buttonIcon,
      onButtonClick,
      saveCancel,
      saveOnclick,
      cancelOnclick,
      minValue,
      handleNegativeMark,
      readOnly,
      restrictSpaces = false, // Default to false (allow spaces)
      bgColor = "bg-primaryColor-50",
      onBlur,
    },
    ref
  ) => {
    const iconColor =
      "text-green-700 text-h6 lg:text-h5 cursor-pointer mt-[14px]";
    const [isDisabled, setIsDisabled] = useState(disabled);
    const [, setIsSaveCancel] = useState(false); // state for save & cancel

    const inputRef = useRef<HTMLInputElement>(null);

    const handleButtonClick = () => {
      if (onButtonClick) {
        onButtonClick(false);
      }
      setIsDisabled(false);

      setIsSaveCancel(true);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let inputValue = e.target.value;

      // If restrictSpaces is true, disallow spaces entirely
      if (restrictSpaces) {
        inputValue = inputValue.replace(/\s/g, "");
      } else {
        // Regular expression to allow only one space between words
        inputValue = inputValue.replace(/\s+/g, " ").trimStart();
      }

      // Call onChange if provided with the formatted value
      if (onChange) {
        e.target.value = inputValue;
        onChange(e);
      }
    };

    useEffect(() => {
      setIsDisabled(disabled);
    }, [disabled]);

    return (
      <div className="mb-4">
        <div ref={ref} className={`relative ${width}`}>
          <div className={`absolute left-3 ${iconColor}`}>{frontIcon}</div>
          {!saveCancel && (
            <div
              className={`absolute right-3 ${
                top ? "top-5 lg:top-6" : ""
              } ${iconColor}`}
              onClick={handleButtonClick}
            >
              {backIcon}
            </div>
          )}
          {saveCancel && (
            <div
              className={`absolute flex right-3 mt-8 lg:mt-9 text-h5 md:text-h4 gap-2`}
            >
              <span
                className={`text-green-600 cursor-pointer`}
                onClick={saveOnclick}
              >
                <MdDone />
              </span>
              <span
                className={`text-red-600 cursor-pointer`}
                onClick={cancelOnclick}
              >
                <RxCross2 />
              </span>
            </div>
          )}
          <div
            className={`text-h8 lg:text-h7 text-primaryColor-900 ${labelWeight}`}
          >
            {label}
          </div>
          <input
            ref={inputRef}
            id={id}
            onWheel={(e) => type === "number" && e.currentTarget.blur()}
            name={name}
            type={type}
            placeholder={placeholder}
            value={value}
            min={minValue ? minValue : undefined}
            readOnly={readOnly ? readOnly : undefined}
            onKeyPress={handleNegativeMark}
            onChange={handleInputChange}
            disabled={isDisabled}
            onBlur={onBlur}
            className={`w-full mt-[3px] ${saveCancel ? "pr-[80px]" : "pr-4"} ${
              frontIcon ? "pl-9" : "pl-4"
            } ${
              backIcon ? "pr-9" : "pr-4"
            } ${bgColor} py-[7px] lg:py-[10px]  rounded-lg placeholder:text-h8 lg:placeholder:text-h8 focus:outline-primaryColor-300 placeholder:text-primaryColor-200`}
          />
          {isDisabled && (
            <button
              type="button"
              onClick={handleButtonClick}
              className="absolute text-2xl text-primaryColor-300 mt-7 -md:ml-1 ml-[-35px] bottom-[8px]"
            >
              {buttonIcon}
            </button>
          )}
        </div>
        {errorMessage && (
          <div className="font-normal text-left text-red-500 font-poppins text-[12px] md:text-[13px] ml-[1px]">
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
);

export default Input;
