// export const BASE_URL = "http://localhost:8081";

 export const BASE_URL = 'https://api.test.credimanage.com';

export const apiOptionJson = {
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
  },
};
