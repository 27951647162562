import axios from "axios";
import { BASE_URL, apiOptionJson } from "./base-url";

// Get schedule list
export const getAllSchedules = async (token: string, userId: number) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/schedules/user/${userId}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

// get schedule details by custome id
export const getPaymentSchedulesBycusID = async (
  customerId: number,
  token: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/v4/PaymentSchedule/${customerId}/details`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// get all schedule by customer nic
export const getAllSchedulesByCusNIC = async (
  token: string,
  nic: string,
  loanId: number
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/schedules/customer/${nic}/${loanId}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// get schele count
export const getInstallmentsCount = async (token: string, nic: string) => {
  try {
    const reponse = await axios.get(
      `${BASE_URL}/api/schedules/count/pending-or-overdue?nic=${nic}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return reponse.data;
  } catch (error) {
    return error;
  }
};

// get financial data by customer nic
export const getFinancialDataByCusNic = async (
  token: string,
  nic: string,
  date: string,
  paymentType: string,
  scheduleReleasedCount: number
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/schedules/financial-data?nic=${nic}&scheduleCount=${scheduleReleasedCount}&date=${date}&paymentType=${paymentType}`,
      {
        headers: {
          ...apiOptionJson.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
