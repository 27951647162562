import React, { useEffect, useState } from "react";
import type { TableProps } from "antd";
import { Empty, Form, Table, Typography } from "antd";
import { Input } from "../../common";
import { getGuaranteesUserid } from "../../../api/guarantee";
import { allCustomers } from "../../../api/customer";
import { useNavigate } from "react-router-dom";

interface Item {
  id: number;
  customerId: number;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "number" | "text";
  record: Item;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        ></Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

interface RecordType {
  id: number;
  guarantorId: string;
  fullName: string;
  address: string;
  guarantorPhoneNumber: string;
  nic: string;
  paymentId: string;
  customerName: string;
  collectorName: string;
  customerNic: string;
}

interface guranteeListTypes {
  address: string;
  collectorUsername: string;
  customerId: number;
  customerNic: string;
  fullName: string;
  loanAmount: number;
  loanReleaseStatus: false;
  loanStatus: string;
  paidAmount: number;
  startDate: string;
  totalInterest: number;
}

const C_GuaranteeListTable = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [customersData, setCustomersData] = useState<RecordType[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [, setgetallCustomers] = useState<guranteeListTypes[]>([]);
  const [selectedCollector] = useState<string>("All");
  const [selectedCustomer] = useState<string>("All");

  const navigateCustomerOveview = (customerId: number) => {
    navigate(`/customer-update-overview/${customerId}`);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "guarantorId",
      fixed: "left" as const,
      align: "center" as const,
    },
    {
      title: "Guarantor Name",
      dataIndex: "fullName",
      align: "center" as const,
    },
    {
      title: "Address",
      dataIndex: "address",
      align: "center" as const,
    },
    {
      title: "Phone Number",
      dataIndex: "guarantorPhoneNumber",
      align: "center" as const,
    },
    {
      title: "NIC",
      dataIndex: "nic",
      align: "center" as const,
    },
    {
      title: "Customer name",
      dataIndex: "customerName",
      align: "center" as const,
    },
    {
      title: "Customer NIC",
      dataIndex: "customerNic",
      align: "center" as const,
    },
    {
      title: "View",
      dataIndex: "paymentId",
      render: (_: number, record: Item) => (
        <div className="flex justify-center">
          <Typography.Link>
            <div
              className="flex justify-center w-20 px-1 font-semibold text-center text-green-600 rounded active:shadow-inner-hard active:text-primaryColor-900"
              onClick={() => navigateCustomerOveview(record.customerId)}
            >
              View
            </div>
          </Typography.Link>
        </div>
      ),
      align: "center" as const,
    },
  ];

  const mergedColumns: TableProps["columns"] = columns.map((col) => {
    if (!col) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "netValue" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  // Get all Customers
  useEffect(() => {
    const token = localStorage.getItem("token") || "";
    const userId = localStorage.getItem("userId");

    if (!userId) {
      console.error("User ID is not available");
      return;
    }
    const fetchCustomers = async () => {
      try {
        const response = await allCustomers(token, parseInt(userId));
        setgetallCustomers([{ fullName: "All" }, ...response.data]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCustomers();
  }, []);

  // get all guarantee list
  useEffect(() => {
    const getPaymentsData = async () => {
      const token = localStorage.getItem("token") || "";
      const userId = localStorage.getItem("userId") || "";

      try {
        const response = await getGuaranteesUserid(token, parseInt(userId));

        // Sort the data in descending order based on guarantorId
        const sortedData = response.data.sort(
          (a: RecordType, b: RecordType) =>
            parseInt(b.guarantorId) - parseInt(a.guarantorId)
        );

        setCustomersData(sortedData);
      } catch (error) {
        console.log(error);
      }
    };

    getPaymentsData();
  }, []);

  // Filter data based on search text, selected customer, and selected collector
  const filteredData = customersData.filter((record) => {
    const searchValue = searchText.toLowerCase();
    const fullName = record.fullName?.toLowerCase() || "";
    const address = record.address?.toLowerCase() || "";
    const guarantorPhoneNumber =
      record.guarantorPhoneNumber?.toLowerCase() || "";
    const nic = record.nic?.toLowerCase() || "";
    const customerNic = record.customerNic?.toLowerCase() || "";

    const customerName = record.customerName?.toLowerCase() || "";
    const collectorName = record.collectorName?.toLowerCase() || "";

    const matchesSearch =
      fullName.includes(searchValue) ||
      address.includes(searchValue) ||
      guarantorPhoneNumber.includes(searchValue) ||
      nic.includes(searchValue) ||
      customerNic.includes(searchValue);

    const matchesCustomer =
      selectedCustomer === "All" ||
      customerName === selectedCustomer.toLowerCase();
    const matchesCollector =
      selectedCollector === "All" ||
      collectorName === selectedCollector.toLowerCase();

    return matchesSearch && matchesCustomer && matchesCollector;
  });

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  return (
    <div>
      <div className="justify-end gap-2 xxs:flex">
        {/* filter guarantors */}
        <Input
          id={"filterGuarantee"}
          name={"filterGuarantee"}
          placeholder="Search"
          value={searchText}
          onChange={handleSearchChange}
          width="w-[100%] xxs:max-w-60 -mt-[2px] mx-auto"
        />
      </div>

      {/* table */}
      <Form form={form} component={false}>
        <Table
          scroll={{ x: "max-content" }} // Ensure the table scrolls horizontally if needed
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={filteredData} // Use filtered data
          columns={mergedColumns}
          rowClassName="editable-row"
          className="font-semibold"
          locale={{
            emptyText: <Empty description={<span>No data available</span>} />,
          }}
        />
      </Form>
    </div>
  );
};

export default C_GuaranteeListTable;
