import React, { useState } from "react";
import { Button, PrimaryModal } from "../../../common";
import {
  customerRegistration,
  customerValidate,
} from "../../../../api/customer";
import { customerRegisterTypes } from "../../../../types";
import { storage } from "../../../../lib/configs/firebaseConfig";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

// Helper to upload a file to Firebase and return its download URL
const uploadImageToFirebase = async (imageUrl: string, imagePath: string) => {
  try {
    const uniqueImagePath = `${imagePath}_${uuidv4()}`; // Append a unique ID
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const storageRef = ref(storage, uniqueImagePath);
    await uploadBytes(storageRef, blob);
    const downloadUrl = await getDownloadURL(storageRef);
    return downloadUrl;
  } catch (error) {
    console.error("Image upload failed:", error);
    throw error;
  }
};

// Helper to delete an image from Firebase
const deleteImageFromFirebase = async (imagePath: string) => {
  const storageRef = ref(storage, imagePath);
  try {
    await deleteObject(storageRef);
  } catch {
    console.error(`Failed to delete image `);
  }
};

const formatDateOnly = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const Registration: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

  // if customer register success then navigate dashboard
  const handleNavigateDashboard = () => {
    navigate("/dashboard");
  };

  const handleRegisterCustomer = async () => {
    setIsOpenConfirmModal(false);
    setIsLoading(true); // Set loading to true when starting the process

    // Array to store image paths for deletion if registration fails
    const uploadedImagePaths: string[] = [];

    try {
      const storedData1 = localStorage.getItem("customerRegisterFormData1");
      const storedData2 = localStorage.getItem("customerRegisterFormData2");
      const storedData3 = localStorage.getItem("customerRegisterFormData3");
      const storedData4 = localStorage.getItem("customerRegisterFormData4");
      const storedData5 = localStorage.getItem("customerRegisterFormData5");

      const parsedData1 = storedData1 ? JSON.parse(storedData1) : {};
      const parsedData2 = storedData2 ? JSON.parse(storedData2) : {};
      const parsedData3 = storedData3 ? JSON.parse(storedData3) : {};
      const parsedData4 = storedData4 ? JSON.parse(storedData4) : {};
      const parsedData5 = storedData5 ? JSON.parse(storedData5) : {};

      const combinedData = {
        ...parsedData1,
        ...parsedData2,
        ...parsedData3,
        ...parsedData4,
        ...parsedData5,
      };

      // check if collateral only
      const isCollateralOnly =
        localStorage.getItem("collateralOnly") === "true";

      // Check for required signatures
      if (!combinedData.customerSignatureImageURL) {
        toast.error("පාරිභෝගික අත්සන අනිවාර්ය වේ.");
        setIsLoading(false);
        return; // Stop execution if validation fails
      }

      // Skip first guarantor validation if `isCollateralOnly` is true
      if (!isCollateralOnly) {
        if (!combinedData.FGSignatureImageURL) {
          toast.error("පළමු ඇපකරුගේ අත්සන අනිවාර්ය වේ.");
          setIsLoading(false);
          return; // Stop execution if validation fails
        }
      }

      // Check if a second guarantor is required and validate their signature
      const secondGuarantorRequired =
        combinedData.selectOptionalSecondGuarantor === "SGyes";

      if (secondGuarantorRequired && !combinedData.SGSignatureImageURL) {
        toast.error("දෙවන ඇපකරුගේ අත්සන අනිවාර්ය වේ.");
        setIsLoading(false);
        return; // Stop execution if validation fails
      }

      // Check if collateral is required
      const collateralRequired =
        combinedData.selectOptionalguarantorsProduct === "PDyes";

      // Validation for required fields of second guarantor and collateral
      if (secondGuarantorRequired) {
        if (
          !combinedData.SGFullName &&
          !combinedData.SGAddress &&
          !combinedData.SGNic &&
          !combinedData.SGPhoneNumber &&
          !combinedData.SGuarantorImageURL &&
          !combinedData.SGFrontImageURL &&
          !combinedData.SGBackImageURL
        ) {
          toast.error("දෙවන ඇපකරු පිලිබඳ විස්තර අසම්පූර්ණයි.");
          setIsLoading(false);
          return; // Stop execution if validation fails
        }
      }

      if (collateralRequired) {
        if (
          (!combinedData.collateralName &&
            !combinedData.collNetValue &&
            !combinedData.collDescription &&
            !combinedData.collImgUrls) ||
          combinedData.collImgUrls.length === 0
        ) {
          toast.error("ඇප භාණ්ඩය පිලිබඳ තොරතුරු අසම්පූර්ණයි.");
          setIsLoading(false);
          return; // Stop execution if validation fails
        }
      }

      // Prepare data to validate customer existence
      const validationData = {
        customerEmail: combinedData.email || "",
        customerNIC: combinedData.nic || "",
        guarantorNIC: combinedData.FGNic || "",
      };

      const userToken = localStorage.getItem("token") || "";

      // Check if customer already exists
      const validateResponse = await customerValidate(
        validationData,
        userToken
      );
      if (validateResponse.success) {
        // Upload images to Firebase and collect their download URLs
        const uploadPromises = [
          combinedData.frontImageURL &&
            uploadImageToFirebase(
              combinedData.frontImageURL,
              `userRegistration/${combinedData.nic}_front_${formatDateOnly()}_.jpg`
            ),
          combinedData.backImageURL &&
            uploadImageToFirebase(
              combinedData.backImageURL,
              `userRegistration/${combinedData.nic}_back_${formatDateOnly()}.jpg`
            ),
          combinedData.customerImageURL &&
            uploadImageToFirebase(
              combinedData.customerImageURL,
              `userRegistration/${combinedData.nic}_profile_${formatDateOnly()}.jpg`
            ),
          combinedData.electricityBillImageURL &&
            uploadImageToFirebase(
              combinedData.electricityBillImageURL,
              `userRegistration/${combinedData.nic}_electricityBill_${formatDateOnly()}.jpg`
            ),
          combinedData.customerSignatureImageURL &&
            uploadImageToFirebase(
              combinedData.customerSignatureImageURL,
              `userRegistration/${combinedData.nic}_signature_${formatDateOnly()}.jpg`
            ),
        ];

        if (!isCollateralOnly) {
          uploadPromises.push(
            combinedData.FGuarantorImageURL &&
              uploadImageToFirebase(
                combinedData.FGuarantorImageURL,
                `userRegistration/${combinedData.nic}_fguarantorimage_${formatDateOnly()}.jpg`
              ),
            combinedData.FGFrontImageURL &&
              uploadImageToFirebase(
                combinedData.FGFrontImageURL,
                `userRegistration/${combinedData.nic}_fgfront_${formatDateOnly()}.jpg`
              ),
            combinedData.FGBackImageURL &&
              uploadImageToFirebase(
                combinedData.FGBackImageURL,
                `userRegistration/${combinedData.nic}_fgback_${formatDateOnly()}.jpg`
              ),
            combinedData.FGSignatureImageURL &&
              uploadImageToFirebase(
                combinedData.FGSignatureImageURL,
                `userRegistration/${combinedData.nic}_fgsignature_${formatDateOnly()}.jpg`
              ),
            combinedData.FGElectricityBillOrWaterImageURL &&
              uploadImageToFirebase(
                combinedData.FGElectricityBillOrWaterImageURL,
                `userRegistration/${combinedData.nic}_fgelectricityBill_${formatDateOnly()}.jpg`
              )
          );
        }

        if (secondGuarantorRequired) {
          uploadPromises.push(
            combinedData.SGuarantorImageURL &&
              uploadImageToFirebase(
                combinedData.SGuarantorImageURL,
                `userRegistration/${combinedData.nic}_sguarantorimage_${formatDateOnly()}.jpg`
              ),
            combinedData.SGFrontImageURL &&
              uploadImageToFirebase(
                combinedData.SGFrontImageURL,
                `userRegistration/${combinedData.nic}_sgfront_${formatDateOnly()}.jpg`
              ),
            combinedData.SGBackImageURL &&
              uploadImageToFirebase(
                combinedData.SGBackImageURL,
                `userRegistration/${combinedData.nic}_sgback_${formatDateOnly()}.jpg`
              ),
            combinedData.SGSignatureImageURL &&
              uploadImageToFirebase(
                combinedData.SGSignatureImageURL,
                `userRegistration/${combinedData.nic}_sgsignature_${formatDateOnly()}.jpg`
              ),
            combinedData.SGElectricityBillOrWaterImageURL &&
              uploadImageToFirebase(
                combinedData.SGElectricityBillOrWaterImageURL,
                `userRegistration/${combinedData.nic}_sgelectricityBill_${formatDateOnly()}.jpg`
              )
          );
        }

        if (collateralRequired || isCollateralOnly) {
          combinedData.collImgUrls.forEach((imgUrl: string, index: number) => {
            uploadPromises.push(
              uploadImageToFirebase(
                imgUrl,
                `userRegistration/${combinedData.nic}_collateral_${index + 1}_${formatDateOnly()}.jpg`
              )
            );
          });
        }

        const imageUrls = await Promise.all(uploadPromises);

        let collateralImageUrls = [];
        if (collateralRequired || isCollateralOnly) {
          const collateralStartIndex =
            uploadPromises.length - combinedData.collImgUrls.length;
          collateralImageUrls = imageUrls.slice(collateralStartIndex);
        }

        // Save the image paths to allow deletion if registration fails
        uploadedImagePaths.push(
          `userRegistration/${combinedData.nic}_front.jpg`,
          `userRegistration/${combinedData.nic}_back.jpg`,
          `userRegistration/${combinedData.nic}_profile.jpg`,
          `userRegistration/${combinedData.nic}_electricityBill.jpg`,
          `userRegistration/${combinedData.nic}_signature.jpg`,
          `userRegistration/${combinedData.nic}_fguarantorimage.jpg`,
          `userRegistration/${combinedData.nic}_fgfront.jpg`,
          `userRegistration/${combinedData.nic}_fgback.jpg`,
          `userRegistration/${combinedData.nic}_fgsignature.jpg`,
          `userRegistration/${combinedData.nic}_fgelectricityBill.jpg`
        );

        if (secondGuarantorRequired) {
          uploadedImagePaths.push(
            `userRegistration/${combinedData.nic}_sguarantorimage.jpg`,
            `userRegistration/${combinedData.nic}_sgfront.jpg`,
            `userRegistration/${combinedData.nic}_sgback.jpg`,
            `userRegistration/${combinedData.nic}_sgsignature.jpg`,
            `userRegistration/${combinedData.nic}_sgelectricityBill.jpg`
          );
        }

        if (collateralRequired || isCollateralOnly) {
          combinedData.collImgUrls.forEach((index: number) => {
            uploadedImagePaths.push(
              `userRegistration/${combinedData.nic}_collateral_${index + 1}.jpg`
            );
          });
        }

        const [
          frontImageURL,
          backImageURL,
          customerImageURL,
          electricityBillImageURL,
          customerSignatureImageURL,
          FGuarantorImageURL,
          FGFrontImageURL,
          FGBackImageURL,
          FGSignatureImageURL,
          FGElectricityBillOrWaterImageURL,
          SGuarantorImageURL,
          SGFrontImageURL,
          SGBackImageURL,
          SGSignatureImageURL,
          SGElectricityBillOrWaterImageURL,
        ] = imageUrls;

        // Prepare registration request body
        const requestBody: customerRegisterTypes = {
          applyreason: combinedData.loanReason || "",
          fullName: combinedData.fullName || "",
          email: combinedData.email || "",
          whatsAppNumber: combinedData.whatsAppNumber || "",
          phoneNumber: combinedData.phoneNumber || "",
          optionalContactNumber: combinedData.optionalContactNumber || "",
          address: combinedData.address || "",
          nic: combinedData.nic || "",
          policeStation: combinedData.policeStation || "",
          gramaDivision: combinedData.gramaDivision || "",
          provincialOffice: combinedData.provincialOffice || "",
          verificationMethod: combinedData.verificationMethod || "",
          frontImageURL,
          backImageURL,
          customerImageURL,
          electricityBillImageURL,
          companyName: combinedData.companyName || "",
          occupation: combinedData.occupation || "",
          companyAddress: combinedData.companyAddress || "",
          netMonthlySalary: combinedData.netMonthlySalary || "",
          guarantors: [],
          loanAmount: Number(combinedData.loanAmount) || 0,
          repaymentFrequency: combinedData.repaymentFrequency || "",
          loanDuration: String(combinedData.numberOfInstallments) || "",
          signatureImageURL: customerSignatureImageURL || "",
        };

        // Conditionally add the first guarantor details if not collateral only
        if (!isCollateralOnly) {
          requestBody.guarantors.push({
            fullName: combinedData.FGFullName || "",
            address: combinedData.FGAddress || "",
            nic: combinedData.FGNic || "",
            guarantorPhoneNumber: combinedData.FGPhoneNumber || "",
            verificationMethod: combinedData.FGVerificationMethod || "",
            gurantorImageURL: FGuarantorImageURL,
            frontImageURL: FGFrontImageURL,
            backImageURL: FGBackImageURL,
            signatureImageURL: FGSignatureImageURL,
            electricityBillOrWaterImageURL: FGElectricityBillOrWaterImageURL,
          });
        }

        // Conditionally push second guarantor data if needed
        if (secondGuarantorRequired) {
          requestBody.guarantors.push({
            fullName: combinedData.SGFullName || "",
            address: combinedData.SGAddress || "",
            nic: combinedData.SGNic || "",
            guarantorPhoneNumber: combinedData.SGPhoneNumber || "",
            verificationMethod: combinedData.SGVerificationMethod || "",
            gurantorImageURL: SGuarantorImageURL,
            frontImageURL: SGFrontImageURL,
            backImageURL: SGBackImageURL,
            signatureImageURL: SGSignatureImageURL,
            electricityBillOrWaterImageURL: SGElectricityBillOrWaterImageURL,
          });
        }

        // Conditionally add collateral data if needed
        if (collateralRequired || isCollateralOnly) {
          requestBody.collaterals = [
            {
              collateralName: combinedData.collateralName || "",
              netValue: combinedData.collNetValue || 0,
              description: combinedData.collDescription || "",
              imgUrl: collateralImageUrls,
            },
          ];
        }

        // Register the customer
        const registrationResponse = await customerRegistration(
          requestBody,
          userToken
        );
        if (registrationResponse.success) {
          // when response success then navigate dashboard
          handleNavigateDashboard();
          toast.success("ලියාපදිංචි කිරීම සාර්තකයි.");
        } else {
          if (
            registrationResponse.message ===
            `Guarantor is already linked to an active loan with status PENDING`
          ) {
            toast.error(registrationResponse.message);
          } else {
            toast.error("ලියාපදිංචි කිරීම අසාර්තකයි.");
          }
          throw new Error("ලියාපදිංචි කිරීම අසාර්තකයි.");
        }

        // If registration fails, delete all uploaded images
        for (const imagePath of uploadedImagePaths) {
          await deleteImageFromFirebase(imagePath);
        }
      } else {
        toast.error(validateResponse.message);
        throw new Error("පාරිභෝගිකයා දැනටමත් සිටී.");
      }
    } catch (error) {
      console.error("Error during registration process:", error);
    } finally {
      setIsLoading(false); // Set loading to false after the process is complete
    }
  };

  return (
    <div className="flex justify-center mt-5">
      <Button
        width="px-2"
        text={isLoading ? "ලියාපදිංචි කරමින් පවතී..." : "ලියාපදිංචි කරන්න"}
        variant="primary"
        onClick={() => {
          setIsOpenConfirmModal(true);
        }}
        disabled={isLoading}
        isLoading={isLoading}
      />

      <PrimaryModal
        visible={isOpenConfirmModal}
        mainTitle={"ලියාපදිංචිය තහවුරු කරන්න"}
        content={"මෙම පාරිභෝගිකයා ලියාපදිංචි කිරීමට ඔබට විශ්වාසද?"}
        primaryBtnLabel="ලියාපදිංචි කරන්න"
        secondaryBtnLabel="අවලංගු කරන්න"
        onClose={() => {
          setIsOpenConfirmModal(false);
        }}
        primaryOnClick={handleRegisterCustomer}
        secondaryOnClick={() => {
          setIsOpenConfirmModal(false);
        }}
      />
    </div>
  );
};

export default Registration;
