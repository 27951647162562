import React, { useEffect, useState } from "react";
import type { TableProps } from "antd";
import { Empty, Form, Table, Typography } from "antd";
import { getAllLoans } from "../../../api/loan";
import { useNavigate } from "react-router-dom";

interface Item {
  remainingAmount: number;
  amountCurrentlyPaid: number;
  loanInstallments: number;
  loanAmount: number;
  loanId: number;
  loanState: string;
  customerName: string;
  collectorName: string;
  paymentDate: string;
  dueAmount: number;
  paidAmount: number;
  balanceAmount: number;
  customerId: number;
  collectorAppUserId: number;
  managerAppUserId: number;
  managerAPPROVED: boolean;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "number" | "text";
  record: Item;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        ></Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const A_DashboardTable = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [customersData, setCustomersData] = useState<Item[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedCollateralStatus] = useState<string>("PENDING");

  const columns = [
    {
      title: "ID",
      dataIndex: "customerId",
      fixed: "left" as const,
      align: "center" as const,
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      align: "center" as const,
    },
    {
      title: "Manager Approve",
      dataIndex: "managerAPPROVED",
      align: "center" as const,
      render: (text: boolean) => {
        let set = "";
        const color = "gray";
        if (text === false) {
          set = "No";
        } else {
          set = "Yes";
        }

        return <span style={{ color, fontWeight: "bold" }}>{set}</span>;
      },
    },
    {
      title: "Loan Status",
      dataIndex: "loanState",
      align: "center" as const,
      render: (text: string) => {
        let color = "";
        if (text === "PENDING") {
          color = "gray";
        }
        return <span style={{ color, fontWeight: "bold" }}>{text}</span>;
      },
    },
    {
      title: "Loan Amount (Rs.)",
      dataIndex: "loanAmount",
      render: (amount: number) => `${amount.toFixed(2)}`,
      align: "center" as const,
    },
    {
      title: "Payment Plan",
      dataIndex: "repaymentFrequency",
      align: "center" as const,
    },
    {
      title: "Loan Duration",
      dataIndex: "loanDuration",
      align: "center" as const,
    },
    {
      title: "Loan Installments (Rs.)",
      dataIndex: "loanInstallments",
      render: (amount: number) => `${amount.toFixed(2)}`,
      align: "center" as const,
    },
    {
      title: "View",
      dataIndex: "customerId",
      align: "center" as const,
      render: (_: number, record: Item) => {
        return (
          <div className="flex justify-center">
            <Typography.Link>
              <div
                className="flex justify-center px-4 font-semibold text-center text-green-600 rounded active:shadow-inner-hard active:text-primaryColor-900"
                onClick={() =>
                  navigateCustomerOveview(
                    record.customerId,
                    record.collectorAppUserId,
                    record.managerAppUserId
                  )
                }
              >
                View
              </div>
            </Typography.Link>
          </div>
        );
      },
    },
  ];

  const mergedColumns: TableProps["columns"] = columns.map((col) => {
    if (!col) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "netValue" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  // handle navigate customer overview
  const navigateCustomerOveview = (
    customerId: number,
    collectorAppUserId: number,
    managerAppUserId: number
  ) => {
    const validCollectorAppUserId = collectorAppUserId || 0;
    const validManagerAppUserId = managerAppUserId || 0;
    navigate(
      `/approve-overview/${customerId}?CAU=${validCollectorAppUserId}&MAU=${validManagerAppUserId}`
    );
  };

  // Fetch all loans data
  useEffect(() => {
    const getLoansData = async () => {
      const token = localStorage.getItem("token") || "";
      const Id = localStorage.getItem("userId") || "";
      const userId = parseInt(Id);

      try {
        const response = await getAllLoans(userId, token);
        const sortedData = response.data.sort(
          (a: Item, b: Item) => b.loanId - a.loanId
        );

        setCustomersData(sortedData);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    getLoansData();
  }, []);

  // Filter data based on selected collateral status, collector, and search text
  const filteredData = customersData?.filter(
    (item) =>
      selectedCollateralStatus === "All" ||
      item.loanState === selectedCollateralStatus
  );

  return (
    <div>
      {/* Table */}
      <Form form={form} component={false}>
        <Table
          scroll={{ x: "max-content" }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={filteredData}
          columns={mergedColumns}
          rowClassName="editable-row"
          className="font-semibold"
          locale={{
            emptyText: <Empty description={<span>No data available</span>} />,
          }}
          loading={loading}
        />
      </Form>
    </div>
  );
};

export default A_DashboardTable;
