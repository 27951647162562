import React from "react";
import { CiEdit } from "react-icons/ci";

const PlanSettingsCard: React.FC<{
  mainTitle: string;
  content?: string;
  latePaymentInterest?: string;
  OverduePaymentInterest?: string;
  keyValue?: "Days" | "Weeks" | "Months";
  primaryBtnLabel?: string;
  primaryOnClickPlan: () => void;
  primaryOnClickLatePayment: () => void;
  primaryOnClickOverDuePayments: () => void;
}> = ({
  mainTitle,
  content,
  keyValue = "Days",
  latePaymentInterest,
  OverduePaymentInterest,
  primaryOnClickPlan,
  primaryOnClickLatePayment,
  primaryOnClickOverDuePayments,
}) => {
  return (
    <div className=" overflow-hidden bg-white rounded-lg shadow-md w-full  sm:max-w-[350px]">
      <div className="text-center text-white bg-primaryColor-500 ">
        <h2 className="py-3 text-lg font-semibold">{mainTitle}</h2>
      </div>
      {/* days, weeks, months  */}
      <div className="relative flex p-4 font-semibold text-center bg-gray-100">
        <div className="flex gap-3 ">
          <p className="text-lg text-black">{content}</p>
          <p className="text-base text-slate-500">{keyValue}</p>
        </div>

        <button
          onClick={primaryOnClickPlan}
          className="absolute border-2 rounded cursor-pointer right-2 text-primaryColor-500 border-primaryColor-500"
        >
          <CiEdit className="w-6 h-6" />
        </button>
      </div>

      <hr className="h-0.5 bg-primaryColor-300 w-[95%] mx-auto" />

      {/* late payment interest  */}
      <div>
        <div className="relative flex p-4 font-semibold text-center bg-gray-100">
          <div className="flex gap-3 ">
            <p className="text-lg text-black">{latePaymentInterest}%</p>
            <p className="text-base text-slate-500">Late Payment Interest</p>
          </div>

          <button
            onClick={primaryOnClickLatePayment}
            className="absolute border-2 rounded cursor-pointer right-2 text-primaryColor-500 border-primaryColor-500"
          >
            <CiEdit className="w-6 h-6" />
          </button>
        </div>
      </div>

      <hr className="h-0.5 bg-primaryColor-300 opacity-50 w-[95%] mx-auto" />

      {/* over due payment interest  */}
      <div>
        <div className="relative flex p-4 pr-10 font-semibold text-center bg-gray-100">
          <div className="flex gap-3 ">
            <p className="text-lg text-black">{OverduePaymentInterest}%</p>
            <p className="text-base text-slate-500">
              Overdue Payment Interest Rate
            </p>
          </div>

          <button
            onClick={primaryOnClickOverDuePayments}
            className="absolute border-2 rounded cursor-pointer right-2 text-primaryColor-500 border-primaryColor-500"
          >
            <CiEdit className="w-6 h-6" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanSettingsCard;
