import React, {
  ChangeEvent,
  FocusEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Checkbox,
  Description,
  Input,
  Main,
  PrimaryModal,
  RadioButton,
  SignatureModal,
  UploadImageInput,
} from "../../../common";
import {
  Step4OptionSchema,
  Step4Schema,
} from "../../../../lib/validation/customer-register-val";
import { z } from "zod";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { storage } from "../../../../lib/configs/firebaseConfig";
import useImageCompressor from "../../../../hooks/useImageCompressor";
import { useGetGuaratorDetails } from "../../../../hooks/useGetGuarantorDetails";
import { removeBackgroundFromImage } from "../../../../api/bgremover";
import { useGetUserName } from "../../../../hooks/useGetUserName";

interface Step4Props {
  setSelect: (step: number) => void;
}

const Step4: React.FC<Step4Props> = ({ setSelect }) => {
  const { compressImage } = useImageCompressor();
  const [isFirstGuarantorSignatureUrl, setIsFirstGuarantorSignatureUrl] =
    useState<string | null>(null);
  const [isSecondGuarantorSignatureUrl, setISSecondGuarantorSignatureUrl] =
    useState<string | null>(null);
  const [isSignatureModalVisible, setIsSignatureModalVisible] = useState(false);
  const [isSecondSignatureModalVisible, setIsSecondSignatureModalVisible] =
    useState(false);

  const [isUploadMode, setIsUploadMode] = useState(false);
  const [isSecondUploadMode, setIsSecondUploadMode] = useState(false);
  const [isCollateralOnly, setIsCollateralOnly] = useState(true);

  const {
    guarantorDetails,
    guarantorSecondDetails,
    firstSuccess,
    secondSuccess,
    getGuarantorDetailsByNic,
    getSecondGuarantorDetailsByNic,
  } = useGetGuaratorDetails();

  // handle close ask collateral only modal
  const handleCloseAskCollateralOnly = () => {
    localStorage.setItem("collateralOnly", "false");
    setIsCollateralOnly(false);
  };
  const handleNavigateNext = () => {
    localStorage.setItem("collateralOnly", "true");
    setSelect(5);
  };

  // Function to handle first guarantor signature checkbox change
  const handleFirstGuarantorCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;
    setIsUploadMode(isChecked);
    localStorage.setItem("isFirstUploadMode", JSON.stringify(isChecked)); // Save state to localStorage

    if (!isChecked) {
      // If checkbox is unchecked, clear the signature field
      setFormData((prevData) => ({ ...prevData, FGSignatureImageURL: "" }));
    } else {
      // If checkbox is checked, clear the selected image
      setFormData((prevData) => ({ ...prevData, FGSignatureImageURL: "" }));
    }
  };

  // Function to handle second guarantor signature checkbox change
  const handleSecondGuarantorCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;
    setIsSecondUploadMode(isChecked);
    localStorage.setItem("isSecondUploadMode", JSON.stringify(isChecked)); // Save state to localStorage

    if (!isChecked) {
      // If checkbox is unchecked, clear the signature field
      setFormData((prevData) => ({ ...prevData, SGSignatureImageURL: "" }));
    } else {
      // If checkbox is checked, clear the selected image
      setFormData((prevData) => ({ ...prevData, SGSignatureImageURL: "" }));
    }
  };

  // Load checkbox states from localStorage on component mount
  useEffect(() => {
    const savedFirstUploadMode = localStorage.getItem("isFirstUploadMode");
    if (savedFirstUploadMode !== null) {
      setIsUploadMode(JSON.parse(savedFirstUploadMode));
    }

    const savedSecondUploadMode = localStorage.getItem("isSecondUploadMode");
    if (savedSecondUploadMode !== null) {
      setIsSecondUploadMode(JSON.parse(savedSecondUploadMode));
    }
  }, []);

  const [formData, setFormData] = useState({
    FGFullName: "",
    FGAddress: "",
    FGNic: "",
    FGPhoneNumber: "",
    FGVerificationMethod: "",
    FGuarantorImageURL: "",
    FGFrontImageURL: "",
    FGBackImageURL: "",
    FGElectricityBillOrWaterImageURL: "",
    FGSignatureImageURL: "",
    selectOptionalSecondGuarantor: "SGno",
    SGFullName: "",
    SGAddress: "",
    SGNic: "",
    SGPhoneNumber: "",
    SGVerificationMethod: "",
    SGFrontImageURL: "",
    SGuarantorImageURL: "",
    SGBackImageURL: "",
    SGElectricityBillOrWaterImageURL: "",
    SGSignatureImageURL: "",
  });
  const [errors, setErrors] = useState<Record<string, string>>({}); // State to hold validation errors
  const [isFirstValidation, setIsFirstValidation] = useState(false); // State to check if initial validation has been done
  // Loading states for image uploads
  const [loadingImages, setLoadingImages] = useState<Record<string, boolean>>({
    FGuarantorImageURL: false,
    FGFrontImageURL: false,
    FGBackImageURL: false,
    FGElectricityBillOrWaterImageURL: false,
    SGFrontImageURL: false,
    SGuarantorImageURL: false,
    SGBackImageURL: false,
    SGElectricityBillOrWaterImageURL: false,
  });

  const inputRefs = useRef<
    Record<string, HTMLInputElement | HTMLDivElement | null>
  >({
    FGFullName: null,
    FGAddress: null,
    FGNic: null,
    FGPhoneNumber: null,
    FGVerificationMethod: null,
    FGFrontImageURL: null,
    FGuarantorImageURL: null,
    FGBackImageURL: null,
    FGElectricityBillOrWaterImageURL: null,
    FGSignatureImageURL: null,
    SGFullName: null,
    SGAddress: null,
    SGNic: null,
    SGPhoneNumber: null,
    SGVerificationMethod: null,
    SGFrontImageURL: null,
    SGuarantorImageURL: null,
    SGBackImageURL: null,
    SGElectricityBillOrWaterImageURL: null,
    SGSignatureImageURL: null,
  });

  // Function to clear first guarantor details
  const clearFirstGuarantor = () => {
    setFormData((prevData) => ({
      ...prevData,
      FGFullName: "",
      FGAddress: "",
      FGNic: "",
      FGPhoneNumber: "",
      FGVerificationMethod: "",
      FGuarantorImageURL: "",
      FGFrontImageURL: "",
      FGBackImageURL: "",
      FGElectricityBillOrWaterImageURL: "",
      FGSignatureImageURL: "",
    }));
    setIsFirstGuarantorSignatureUrl(null);
    setIsUploadMode(false);
    setSelectedFirstGuarantorIdentityOption("");
    localStorage.removeItem("FGNic");
  };

  // Function to clear second guarantor details
  const clearSecondGuarantor = () => {
    setFormData((prevData) => ({
      ...prevData,
      SGFullName: "",
      SGAddress: "",
      SGNic: "",
      SGPhoneNumber: "",
      SGVerificationMethod: "",
      SGFrontImageURL: "",
      SGuarantorImageURL: "",
      SGBackImageURL: "",
      SGElectricityBillOrWaterImageURL: "",
      SGSignatureImageURL: "",
    }));
    setISSecondGuarantorSignatureUrl(null);
    setIsSecondUploadMode(false);
    setSelectedSecondGuarantorIdentityOption("");
    localStorage.removeItem("SGNic");
  };

  //handling First Guarantor Identity
  const [
    selectedFirstGuarantorIdentityOption,
    setSelectedFirstGuarantorIdentityOption,
  ] = useState<string>("SGno");
  //handling second Guarantor Identity
  const [
    selectedSecondGuarantorIdentityOption,
    setSelectedSecondGuarantorIdentityOption,
  ] = useState<string>("");

  // Functions for handling First Guarantor Identity radio button changes
  const handleFirstGuarantorIdentityRadioButtonChange = (value: string) => {
    setFirstGuarantorsIdUploadDropdown(true);
    setSelectedFirstGuarantorIdentityOption(value);

    setFormData({ ...formData, FGVerificationMethod: value });
    setErrors({ ...errors, FGVerificationMethod: "" });
  };
  // Functions for handling second Guarantor Identity radio button changes
  const handleSecondGuarantorIdentityRadioButtonChange = (value: string) => {
    setSGNicUploadDropdown(true);
    setSelectedSecondGuarantorIdentityOption(value);
    setFormData({ ...formData, SGVerificationMethod: value });
    setErrors({ ...errors, SGVerificationMethod: "" });
  };

  const [firstGuarantorsIdUploadDropdown, setFirstGuarantorsIdUploadDropdown] =
    useState<boolean>(false);

  const [secondGuarantorsIdUploadDropdown, setSGNicUploadDropdown] =
    useState<boolean>(false);

  // Function to handle input changes and validate form on change
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    let fristGurantererrors;
    let secondGurantererrors;
    if (isFirstValidation) {
      const updatedFristFormData = { ...formData, [name]: value };
      fristGurantererrors = validateForm(updatedFristFormData);

      if (secondGuarantor || openSecondGuarantorsection) {
        const updatedSecondFormData = { ...formData, [name]: value };
        secondGurantererrors = validateOptionalSection(updatedSecondFormData);
      }
      const combinedErrors = {
        ...fristGurantererrors,
        ...secondGurantererrors,
      };
      setErrors(combinedErrors);
    }
  };

  const { fetchData, userName } = useGetUserName();

  // Handle image upload to Firebase with deletion of the previous image
  const uploadImageToFirebase = async (
    file: File,
    id: string,
    previousImageUrl: string | null = null
  ) => {
    fetchData();
    setLoadingImages((prev) => ({ ...prev, [id]: true })); // Set loading state for the image
    try {
      // If there's a previous image URL, delete it first
      if (previousImageUrl) {
        const previousRef = ref(storage, previousImageUrl);
        await deleteObject(previousRef);
      }

      // Compress the image to reduce its size by 50%
      const compressedFile = await compressImage(file, 0.5);

      // If compression failed, exit the function early
      if (!compressedFile) {
        throw new Error("Image compression failed");
      }

      // Upload the new image
      const storageRef = ref(storage, `userRegistration/${userName}_${id}.jpg`);
      await uploadBytes(storageRef, compressedFile);
      const downloadUrl = await getDownloadURL(storageRef);

      // Update the formData with the new image URL
      setFormData((prevData) => ({
        ...prevData,
        [id]: downloadUrl,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: "",
      }));
    } catch (error) {
      console.error("Error uploading image:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: "Failed to upload image",
      }));
    } finally {
      setLoadingImages((prev) => ({ ...prev, [id]: false })); // Remove loading state
    }
  };

  // Handle signature upload to Firebase
  const uploadSignatureToFirebase = async (
    signatureUrl: string,
    id: string
  ) => {
    fetchData();
    try {
      const response = await fetch(signatureUrl); // Fetch the signature image
      const blob = await response.blob(); // Convert to Blob format

      const storageRef = ref(
        storage,
        `userRegistration/${userName}_${id}_signature.jpg`
      );
      await uploadBytes(storageRef, blob);
      const downloadUrl = await getDownloadURL(storageRef);

      return downloadUrl; // Return the download URL to store in formData
    } catch (error) {
      console.error("Error uploading signature:", error);
      throw error;
    }
  };

  // Function to upload signature to Firebase
  const uploadSignatureToFirebaseImages = async (
    imageBlob: Blob,
    id: string
  ): Promise<string> => {
    fetchData();
    try {
      // Create a reference to the file location in Firebase
      const storageRef = ref(
        storage,
        `userRegistration/${userName}_${id}_signature.jpg`
      );

      // Upload the background-removed image as a Blob
      await uploadBytes(storageRef, imageBlob); // Upload the Blob directly
      const downloadUrl = await getDownloadURL(storageRef); // Get the download URL

      return downloadUrl; // Return the URL for the uploaded image
    } catch (error) {
      console.error("Error uploading signature:", error);
      throw error;
    }
  };

  // Helper function to check if the URL is a valid Firebase URL
  const isFirebaseUrl = (url: string | null) => {
    return url ? url.includes("firebase") : false;
  };

  // Handle image select and trigger Firebase upload, with deletion of previous image
  const handleImageChange = (file: File | null, id: keyof typeof formData) => {
    const previousImageUrl = formData[id];

    // Check if the previous URL is a valid Firebase URL, skip upload if it is
    if (isFirebaseUrl(previousImageUrl)) {
      return;
    }

    if (file) {
      uploadImageToFirebase(file, id, previousImageUrl);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [id]: "",
      }));
    }
  };

  // handle images change signatures
  const handleImageChangeSignatures = async (
    file: File | null,
    id: keyof typeof formData
  ) => {
    if (!file) {
      // If no file is selected, clear the corresponding field in formData
      setFormData((prevData) => ({
        ...prevData,
        [id]: "",
      }));
      return;
    }

    try {
      // Set loading state for the image
      setLoadingImages((prev) => ({ ...prev, [id]: true }));

      // Convert the file to a Blob
      const imageBlob = new Blob([file], { type: file.type });

      // Call the background removal function
      const processedBlob = await removeBackgroundFromImage(imageBlob);

      // Check if the processed blob is valid
      if (!processedBlob || processedBlob.size === 0) {
        throw new Error("Background removal failed: Received empty blob");
      }

      // Upload the background-removed image to Firebase
      const uploadedUrl = await uploadSignatureToFirebaseImages(
        processedBlob,
        id
      );

      // Update the formData with the uploaded image URL
      setFormData((prevData) => ({
        ...prevData,
        [id]: uploadedUrl,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: "",
      }));
    } catch (error) {
      console.error("Error handling image change:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: "Failed to upload image",
      }));
    } finally {
      // Remove loading state
      setLoadingImages((prev) => ({ ...prev, [id]: false }));
    }
  };

  // check second garantor optional validation
  const validateOptionalSection = (data: typeof formData) => {
    try {
      Step4OptionSchema.parse(data);

      return {};
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          newErrors[err.path[0]] = err.message;
        });
        return newErrors;
      }
      return {};
    }
  };

  // Function to handle registration step1
  const handleNext = async () => {
    setIsFirstValidation(true);
    const formErrors = validateForm(formData);
    const optionalErrors =
      secondGuarantor || openSecondGuarantorsection
        ? validateOptionalSection(formData)
        : {};

    const combinedErrors = { ...formErrors, ...optionalErrors };
    if (Object.keys(combinedErrors).length === 0) {
      localStorage.setItem(
        "customerRegisterFormData4",
        JSON.stringify(formData)
      );
      setSelect(5);
    } else {
      setErrors(combinedErrors);
      scrollToFirstError(combinedErrors);
    }
  };

  const scrollToFirstError = (newErrors: Record<string, string>) => {
    const orderedErrors: Record<string, string> = {};

    Object.keys(formData).forEach((key) => {
      if (newErrors[key]) {
        orderedErrors[key] = newErrors[key];
      }
    });

    const firstErrorKey = Object.keys(orderedErrors)[0];

    if (firstErrorKey && inputRefs.current[firstErrorKey]) {
      const element = inputRefs.current[firstErrorKey];
      const elementOffsetTop =
        element.getBoundingClientRect().top + window.scrollY;

      // Scroll to the element smoothly
      window.scrollTo({
        top: elementOffsetTop - 80, // Offset by 20px from the top
        behavior: "smooth",
      });
    }
  };

  // Function to validate form data using Zod schema
  const validateForm = (data: typeof formData) => {
    try {
      Step4Schema.parse(data);
      return {};
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          newErrors[err.path[0]] = err.message;
        });
        return newErrors;
      }
      return {};
    }
  };

  // First guaranter sign handle
  // handle open signature modal
  const handleFirstSignatureModalOpen = () => {
    setIsSignatureModalVisible(true);
  };
  const handleFirstSignatureModalClose = () => {
    setIsSignatureModalVisible(false);
  };
  // Handle first guarantor signature submission
  const handleFirstSignatureModalOk = async (url: string | null) => {
    setIsSignatureModalVisible(false);

    if (url) {
      try {
        const uploadedSignatureUrl = await uploadSignatureToFirebase(
          url,
          "FGSignatureImageURL"
        );
        setFormData({ ...formData, FGSignatureImageURL: uploadedSignatureUrl });
        setErrors({ ...errors, FGSignatureImageURL: "" });
      } catch (_error) {
        setErrors({
          ...errors,
          FGSignatureImageURL: "Failed to upload signature",
        });
      }
    }
  };

  // Handle second guarantor signature submission
  const handleSecondSignatureModalOk = async (url: string | null) => {
    setIsSecondSignatureModalVisible(false);

    if (url) {
      try {
        const uploadedSignatureUrl = await uploadSignatureToFirebase(
          url,
          "SGSignatureImageURL"
        );
        setFormData({ ...formData, SGSignatureImageURL: uploadedSignatureUrl });
        setErrors({ ...errors, SGSignatureImageURL: "" });
      } catch (_error) {
        setErrors({
          ...errors,
          SGSignatureImageURL: "Failed to upload signature",
        });
      }
    }
  };
  // handle clear first guaranter signature
  const handleFirstSignatureModalClear = () => {
    setIsFirstGuarantorSignatureUrl("");

    setFormData({ ...formData, FGSignatureImageURL: "" });
  };
  // handle clear second guaranter signature
  const handleSecondSignatureModalClear = () => {
    setISSecondGuarantorSignatureUrl("");

    setFormData({ ...formData, SGSignatureImageURL: "" });
  };

  // handle open signature modal
  const handleSecondSignatureModalOpen = () => {
    setIsSecondSignatureModalVisible(true);
  };
  const handleSecondSignatureModalClose = () => {
    setIsSecondSignatureModalVisible(false);
  };

  const [secondGuarantor, setsecondGuarantor] = useState(false);
  const [optionalSecondGuarantorSelect, setoptionalSecondGuarantorSelect] =
    useState(false);
  const [selectedSecondGuranterOption, setSelectedSecondGuranterOption] =
    useState<string>("SGno");
  const [openSecondGuarantorsection, setopenSecondGuarantorsection] =
    useState(false);

  // select radio button for enable Second Guarantor
  const handleRadioButtonChange = (value: string) => {
    setSelectedSecondGuranterOption(value);
    setFormData({ ...formData, selectOptionalSecondGuarantor: value });
    setErrors({ ...errors, selectOptionalSecondGuarantor: "" });

    if (value === "SGno") {
      setopenSecondGuarantorsection(false);

      setErrors({
        ...errors,
        SGFullName: "",
        SGAddress: "",
        SGNic: "",
        SGPhoneNumber: "",
        SGVerificationMethod: "",
        SGFrontImageURL: "",
        SGuarantorImageURL: "",
        SGBackImageURL: "",
        SGElectricityBillOrWaterImageURL: "",
        SGSignatureImageURL: "",
      });
    } else {
      setopenSecondGuarantorsection(true);
    }
  };

  //enable Second Guarantor section when page loading
  useEffect(() => {
    const savedData = localStorage.getItem("customerRegisterFormData3");
    if (savedData) {
      const parsedData = JSON.parse(savedData);

      if (parsedData.loanAmount >= 50000) {
        setsecondGuarantor(true);
        setoptionalSecondGuarantorSelect(false);

        setFormData({ ...formData, selectOptionalSecondGuarantor: "SGyes" });
        setErrors({ ...errors, selectOptionalSecondGuarantor: "" });
      } else {
        setsecondGuarantor(false);
        setoptionalSecondGuarantorSelect(true);
        setISSecondGuarantorSignatureUrl("");
        setSelectedSecondGuarantorIdentityOption("");
        setFormData({
          ...formData,
          SGFullName: "",
          SGAddress: "",
          SGNic: "",
          SGPhoneNumber: "",
          SGVerificationMethod: "",
          SGFrontImageURL: "",
          SGuarantorImageURL: "",
          SGBackImageURL: "",
          SGElectricityBillOrWaterImageURL: "",
          SGSignatureImageURL: "",
        });
      }
    }
  }, []);

  useEffect(() => {
    const savedData = localStorage.getItem("customerRegisterFormData3");
    if (savedData) {
      const parsedData = JSON.parse(savedData);

      if (parsedData.loanAmount >= 200000) {
        setIsCollateralOnly(false);
      }
    }
  }, [isCollateralOnly]);

  // Load data from local storage or use hook data on component mount
  useEffect(() => {
    const savedData = localStorage.getItem("customerRegisterFormData4");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setFormData((prevData) => ({ ...prevData, ...parsedData }));
      setFirstGuarantorsIdUploadDropdown(true);

      setSelectedSecondGuranterOption(parsedData.selectOptionalSecondGuarantor);
      if (parsedData.FGVerificationMethod) {
        setSelectedFirstGuarantorIdentityOption(
          parsedData.FGVerificationMethod
        );
      }

      if (parsedData.selectOptionalSecondGuarantor === "SGyes") {
        setopenSecondGuarantorsection(true);
      } else {
        setopenSecondGuarantorsection(false);
      }

      if (parsedData.SGVerificationMethod) {
        setSelectedSecondGuarantorIdentityOption(
          parsedData.SGVerificationMethod
        );
        setSGNicUploadDropdown(true);
      }

      if (parsedData.FGSignatureImageURL) {
        setIsFirstGuarantorSignatureUrl(parsedData.FGSignatureImageURL);
      }

      if (parsedData.SGSignatureImageURL) {
        setISSecondGuarantorSignatureUrl(parsedData.SGSignatureImageURL);
      }
    } else {
      // Populate initial data using custom hook
      setFormData((prevData) => ({
        ...prevData,
        // first guarantor
        FGFullName: guarantorDetails?.fullName || prevData.FGFullName,
        FGAddress: guarantorDetails?.address || prevData.FGAddress,
        FGNic: guarantorDetails?.nic || prevData.FGNic,
        FGPhoneNumber:
          guarantorDetails?.guarantorPhoneNumber || prevData.FGPhoneNumber,
        FGuarantorImageURL:
          guarantorDetails?.gurantorImageURL || prevData.FGuarantorImageURL,
        FGFrontImageURL:
          guarantorDetails?.frontImageURL || prevData.FGFrontImageURL,
        FGBackImageURL:
          guarantorDetails?.backImageURL || prevData.FGBackImageURL,
        FGElectricityBillOrWaterImageURL:
          guarantorDetails?.electricityBillOrWaterImageURL ||
          prevData.FGElectricityBillOrWaterImageURL,
        FGVerificationMethod:
          guarantorDetails?.verificationMethod || prevData.FGVerificationMethod,

        // second guarantor
        SGFullName: guarantorSecondDetails?.fullName || prevData.SGFullName,
        SGAddress: guarantorSecondDetails?.address || prevData.SGAddress,
        SGNic: guarantorSecondDetails?.nic || prevData.SGNic,
        SGPhoneNumber:
          guarantorSecondDetails?.guarantorPhoneNumber ||
          prevData.SGPhoneNumber,
        SGuarantorImageURL:
          guarantorSecondDetails?.gurantorImageURL ||
          prevData.FGuarantorImageURL,
        SGFrontImageURL:
          guarantorSecondDetails?.frontImageURL || prevData.FGFrontImageURL,
        SGBackImageURL:
          guarantorSecondDetails?.backImageURL || prevData.FGBackImageURL,
        SGElectricityBillOrWaterImageURL:
          guarantorSecondDetails?.electricityBillOrWaterImageURL ||
          prevData.FGElectricityBillOrWaterImageURL,
        SGVerificationMethod:
          guarantorSecondDetails?.verificationMethod ||
          prevData.FGVerificationMethod,
      }));
      setSelectedFirstGuarantorIdentityOption(
        guarantorDetails?.verificationMethod || ""
      );
      setSelectedSecondGuarantorIdentityOption(
        guarantorSecondDetails?.verificationMethod || ""
      );
      if (guarantorDetails?.verificationMethod) {
        setFirstGuarantorsIdUploadDropdown(true);
      }
      if (guarantorSecondDetails?.verificationMethod) {
        setSGNicUploadDropdown(true);
      }
    }
  }, [guarantorDetails, guarantorSecondDetails, firstSuccess, secondSuccess]);

  // Function to save NIC to localStorage on blur
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "FGNic") {
      localStorage.setItem("FGNic", value);
      getGuarantorDetailsByNic();
    }
  };

  // Function to save NIC to localStorage on blur
  const handleSecondBlur = (e: FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "SGNic") {
      localStorage.setItem("SGNic", value);
      getSecondGuarantorDetailsByNic();
    }
  };

  // Custom handler if needed
  const customKeyPressHandler = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "-" || event.key === "e") {
      event.preventDefault();
    }
  };

  // Define 'id' to be one of the keys of formData
  const handleFileSelect = (file: File | null, id: keyof typeof formData) => {
    if (file) {
      const previousImageUrl = formData[id]; // No more TypeScript error
      uploadImageToFirebase(file, id, previousImageUrl);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [id]: "", // Clear the URL if no file is selected
      }));
    }
  };

  return (
    <div>
      <div>
        <Main>
          <div className="flex justify-between">
            <Description
              content="ඇපකරුවන් පිලිබඳ විස්තරය"
              fontWeight="font-bold"
              fontSize="text-h6 lg:text-h5 3xl:text-h3"
            />
            {/* clear button  */}
            {firstSuccess && (
              <div
                onClick={clearFirstGuarantor}
                className="flex items-center px-5 py-1 border rounded-md cursor-pointer hover:shadow-3xl active:shadow-inner border-primaryColor-300"
              >
                <Description content="Clear" />
              </div>
            )}
          </div>
          <div>
            <div className="my-4">
              <Description
                content="පළමු ඇපකරු"
                fontWeight="font-bold"
                fontSize="text-h7 lg:text-h6 3xl:text-h3"
              />
            </div>
            <div className="grid md:gap-3 md:grid-cols-2">
              <Input
                ref={(el) => (inputRefs.current.FGNic = el)}
                id={"FGNic"}
                name={"FGNic"}
                placeholder={"ජාතික හැඳුනුම්පත් අංකය"}
                label="ජාතික හැඳුනුම්පත් අංකය*"
                labelWeight="font-bold"
                onChange={handleChange}
                onBlur={handleBlur}
                value={formData.FGNic}
                errorMessage={errors.FGNic}
                restrictSpaces
              />
              <Input
                ref={(el) => (inputRefs.current.FGFullName = el)}
                id={"FGFullName"}
                name={"FGFullName"}
                placeholder={"නම"}
                label="නම*"
                labelWeight="font-bold"
                onChange={handleChange}
                value={formData.FGFullName}
                errorMessage={errors.FGFullName}
              />
            </div>
            <div className="grid md:gap-3 md:grid-cols-2">
              <Input
                ref={(el) => (inputRefs.current.FGAddress = el)}
                id={"FGAddress"}
                name={"FGAddress"}
                placeholder={"ලිපිනය"}
                label="ලිපිනය*"
                labelWeight="font-bold"
                onChange={handleChange}
                value={formData.FGAddress}
                errorMessage={errors.FGAddress}
              />
              <Input
                ref={(el) => (inputRefs.current.FGPhoneNumber = el)}
                id={"FGPhoneNumber"}
                name={"FGPhoneNumber"}
                type="number"
                placeholder={"දුරකථන අංකය"}
                label="දුරකථන අංකය*"
                labelWeight="font-bold"
                onChange={handleChange}
                value={formData.FGPhoneNumber}
                errorMessage={errors.FGPhoneNumber}
                handleNegativeMark={customKeyPressHandler}
                restrictSpaces
              />
            </div>

            <div>
              <Description
                content="පළමු ඇපකරුගෙ ඡායාරූපය*"
                fontWeight="font-bold"
              />

              <UploadImageInput
                ref={(el) => (inputRefs.current.FGuarantorImageURL = el)}
                id={"FGuarantorImageURL"}
                name={"FGuarantorImageURL"}
                text={" "}
                placeholder={""}
                onFileSelect={(file) =>
                  handleFileSelect(file, "FGuarantorImageURL")
                }
                errorMessage={errors.FGuarantorImageURL}
                selectImage={
                  formData.FGuarantorImageURL
                    ? `${formData.FGuarantorImageURL}`
                    : ""
                }
                isLoading={loadingImages.FGuarantorImageURL}
              />
            </div>

            <div className="mt-5">
              <Description
                content="අනන්‍යතාව සහතික කිරීම සඳහා පහත ඒවායින් එකක් තෝරාගන්න*"
                fontWeight="font-bold"
              />
            </div>

            <div
              className="flex flex-wrap gap-5 mt-3 lg:gap-10"
              ref={(el) => (inputRefs.current.FGVerificationMethod = el)}
            >
              <RadioButton
                id={"firstGuarantorNic"}
                label={"ජාතික හැඳුනුම්පත"}
                checked={selectedFirstGuarantorIdentityOption === "NIC"}
                onChange={() =>
                  handleFirstGuarantorIdentityRadioButtonChange("NIC")
                }
              />
              <RadioButton
                id={"firstGuarantorDrivingLicense"}
                label={"රියදුරු බලපත්‍රය"}
                checked={
                  selectedFirstGuarantorIdentityOption === "DRIVER_LICENCE"
                }
                onChange={() =>
                  handleFirstGuarantorIdentityRadioButtonChange(
                    "DRIVER_LICENCE"
                  )
                }
              />
              <RadioButton
                id={"firstGuarantorPassport"}
                label={"විදේශ ගමන් බලපත්‍රය"}
                checked={selectedFirstGuarantorIdentityOption === "PASSPORT"}
                onChange={() =>
                  handleFirstGuarantorIdentityRadioButtonChange("PASSPORT")
                }
              />
            </div>

            {errors.FGVerificationMethod && (
              <div className="font-normal text-left text-red-500 font-poppins text-[12px] md:text-[13px] ml-[1px] ">
                {errors.FGVerificationMethod}
              </div>
            )}

            {firstGuarantorsIdUploadDropdown && (
              <div className="grid grid-cols-2 gap-4 mb-4">
                <UploadImageInput
                  ref={(el) => (inputRefs.current.FGFrontImageURL = el)}
                  id={"FGFrontImageURL"}
                  name={"FGFrontImageURL"}
                  text={"ඉදිරිපස"}
                  placeholder={""}
                  onFileSelect={(file) =>
                    handleImageChange(file, "FGFrontImageURL")
                  }
                  errorMessage={errors.FGFrontImageURL}
                  selectImage={
                    formData.FGFrontImageURL
                      ? `${formData.FGFrontImageURL}`
                      : ""
                  }
                  isLoading={loadingImages.FGFrontImageURL}
                />
                <UploadImageInput
                  ref={(el) => (inputRefs.current.FGBackImageURL = el)}
                  id={"FGBackImageURL"}
                  name={"FGBackImageURL"}
                  text={"පසුපස"}
                  placeholder={""}
                  onFileSelect={(file) =>
                    handleImageChange(file, "FGBackImageURL")
                  }
                  errorMessage={errors.FGBackImageURL}
                  selectImage={
                    formData.FGBackImageURL ? `${formData.FGBackImageURL}` : ""
                  }
                  isLoading={loadingImages.FGBackImageURL}
                />
              </div>
            )}

            <div className="mt-8 mb-8">
              <Description
                content="ජල බිල හෝ විදුලි බිලෙහි පිටපතක්  අමුණන්න*"
                fontWeight="font-bold"
              />
              <UploadImageInput
                ref={(el) =>
                  (inputRefs.current.FGElectricityBillOrWaterImageURL = el)
                }
                id={"FGElectricityBillOrWaterImageURL"}
                name={"FGElectricityBillOrWaterImageURL"}
                text={""}
                placeholder={""}
                onFileSelect={(file) =>
                  handleImageChange(file, "FGElectricityBillOrWaterImageURL")
                }
                errorMessage={errors.FGElectricityBillOrWaterImageURL}
                selectImage={
                  formData.FGElectricityBillOrWaterImageURL
                    ? `${formData.FGElectricityBillOrWaterImageURL}`
                    : ""
                }
                isLoading={loadingImages.FGElectricityBillOrWaterImageURL}
              />
            </div>

            <div>
              <Description
                content="අත්සන"
                fontWeight="font-bold"
                fontSize="text-h7 lg:text-h6 3xl:text-h3"
              />
              <div className="flex items-center gap-2 mt-2">
                <Checkbox
                  onChange={handleFirstGuarantorCheckboxChange}
                  checked={isUploadMode}
                  label="Upload Image"
                />
              </div>

              {isUploadMode ? (
                // Image upload input appears when the checkbox is checked
                <UploadImageInput
                  ref={(el) => (inputRefs.current.FGSignatureImageURL = el)}
                  id={"FGSignatureImageURL"}
                  name={"FGSignatureImageURL"}
                  text={""}
                  placeholder={""}
                  onFileSelect={(file) =>
                    handleImageChangeSignatures(file, "FGSignatureImageURL")
                  }
                  selectImage={
                    formData.FGSignatureImageURL
                      ? `${formData.FGSignatureImageURL}`
                      : ""
                  }
                  isLoading={loadingImages.FGSignatureImageURL}
                />
              ) : (
                // Signature area appears when the checkbox is unchecked
                <div
                  className="h-40 p-2 mt-2 bg-white border-2 rounded-lg cursor-pointer max-w-[100%] border-primaryColor-200"
                  onClick={handleFirstSignatureModalOpen}
                  ref={(el) => (inputRefs.current.FGSignatureImageURL = el)}
                >
                  {formData.FGSignatureImageURL ? (
                    <img
                      src={formData.FGSignatureImageURL}
                      className="object-contain w-full h-full"
                    />
                  ) : (
                    <div className="flex items-center justify-center h-full underline duration-300 text-primaryColor-200 hover:scale-105">
                      <Description
                        content="Sign here"
                        fontWeight="font-medium"
                        fontColor="text-primaryColor-200"
                        center
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            {errors.FGSignatureImageURL && (
              <div className="font-normal text-left text-red-500 font-poppins text-[12px] md:text-[13px] ml-[1px] ">
                {errors.FGSignatureImageURL}
              </div>
            )}
          </div>
        </Main>

        <Main>
          {optionalSecondGuarantorSelect && (
            <>
              <div>
                <div className="flex justify-between">
                  <Description
                    content="දෙවන ඇපකරුවෙකු අවශ්‍යද?"
                    fontWeight="font-bold"
                  />
                  {/* clear button  */}
                  {secondSuccess && (
                    <div
                      onClick={clearSecondGuarantor}
                      className="flex items-center px-5 py-1 border rounded-md cursor-pointer hover:shadow-3xl active:shadow-inner border-primaryColor-300"
                    >
                      <Description content="Clear" />
                    </div>
                  )}
                </div>
                <div className="flex gap-5 mt-3 lg:gap-10">
                  <RadioButton
                    id={"SecondGuarantYes"}
                    label={"ඔව්"}
                    checked={selectedSecondGuranterOption === "SGyes"}
                    onChange={() => handleRadioButtonChange("SGyes")}
                  />
                  <RadioButton
                    id={"SecondGuarantNo"}
                    label={"නැත"}
                    checked={selectedSecondGuranterOption === "SGno"}
                    onChange={() => handleRadioButtonChange("SGno")}
                  />
                </div>
              </div>
              {errors.selectOptionalSecondGuarantor && (
                <div className="font-normal text-left text-red-500 font-poppins text-[12px] md:text-[13px] ml-[1px] ">
                  {errors.selectOptionalSecondGuarantor}
                </div>
              )}
            </>
          )}
          {(secondGuarantor || openSecondGuarantorsection) && (
            <>
              <div className="mt-10">
                <div className="my-4">
                  <Description
                    content="දෙවන ඇපකරු"
                    fontWeight="font-bold"
                    fontSize="text-h7 lg:text-h6 3xl:text-h3"
                  />
                </div>
                <div className="grid md:gap-3 md:grid-cols-2">
                  <Input
                    ref={(el) => (inputRefs.current.SGNic = el)}
                    id={"SGNic"}
                    name={"SGNic"}
                    placeholder={"ජාතික හැඳුනුම්පත් අංකය"}
                    label="ජාතික හැඳුනුම්පත් අංකය*"
                    labelWeight="font-bold"
                    onChange={handleChange}
                    onBlur={handleSecondBlur}
                    value={formData.SGNic}
                    errorMessage={errors.SGNic}
                    restrictSpaces
                  />
                  <Input
                    ref={(el) => (inputRefs.current.SGFullName = el)}
                    id={"SGFullName"}
                    name={"SGFullName"}
                    placeholder={"නම"}
                    label="නම*"
                    labelWeight="font-bold"
                    onChange={handleChange}
                    value={formData.SGFullName}
                    errorMessage={errors.SGFullName}
                  />
                </div>
                <div className="grid md:gap-3 md:grid-cols-2">
                  <Input
                    ref={(el) => (inputRefs.current.SGAddress = el)}
                    id={"SGAddress"}
                    name={"SGAddress"}
                    placeholder={"ලිපිනය"}
                    label="ලිපිනය*"
                    labelWeight="font-bold"
                    onChange={handleChange}
                    value={formData.SGAddress}
                    errorMessage={errors.SGAddress}
                  />
                  <Input
                    ref={(el) => (inputRefs.current.SGPhoneNumber = el)}
                    id={"SGPhoneNumber"}
                    name={"SGPhoneNumber"}
                    placeholder={"දුරකථන අංකය"}
                    label="දුරකථන අංකය*"
                    type="number"
                    labelWeight="font-bold"
                    onChange={handleChange}
                    value={formData.SGPhoneNumber}
                    errorMessage={errors.SGPhoneNumber}
                    handleNegativeMark={customKeyPressHandler}
                    restrictSpaces
                  />
                </div>

                <div>
                  <Description
                    content="දෙවන ඇපකරුගෙ ඡායාරූපය*"
                    fontWeight="font-bold"
                  />

                  <UploadImageInput
                    ref={(el) => (inputRefs.current.SGuarantorImageURL = el)}
                    id={"SGuarantorImageURL"}
                    name={"SGuarantorImageURL"}
                    text={" "}
                    placeholder={""}
                    onFileSelect={(file) =>
                      handleFileSelect(file, "SGuarantorImageURL")
                    }
                    errorMessage={errors.SGuarantorImageURL}
                    selectImage={
                      formData.SGuarantorImageURL
                        ? `${formData.SGuarantorImageURL}`
                        : ""
                    }
                    isLoading={loadingImages.SGuarantorImageURL}
                  />
                </div>

                <div className="mt-5">
                  <Description
                    content="අනන්‍යතාව සහතික කිරීම සඳහා පහත ඒවායින් එකක් තෝරාගන්න*"
                    fontWeight="font-bold"
                  />
                </div>

                <div
                  className="flex flex-wrap gap-5 mt-3 mb-3 lg:gap-10"
                  ref={(el) => (inputRefs.current.SGVerificationMethod = el)}
                >
                  <RadioButton
                    id={"secondGuarantorNic"}
                    label={"ජාතික හැඳුනුම්පත"}
                    checked={selectedSecondGuarantorIdentityOption === "NIC"}
                    onChange={() =>
                      handleSecondGuarantorIdentityRadioButtonChange("NIC")
                    }
                  />
                  <RadioButton
                    id={"secondGuarantorDrivingLicense"}
                    label={"රියදුරු බලපත්‍රය"}
                    checked={
                      selectedSecondGuarantorIdentityOption === "DRIVER_LICENCE"
                    }
                    onChange={() =>
                      handleSecondGuarantorIdentityRadioButtonChange(
                        "DRIVER_LICENCE"
                      )
                    }
                  />
                  <RadioButton
                    id={"secondGuarantorPassport"}
                    label={"විදේශ ගමන් බලපත්‍රය"}
                    checked={
                      selectedSecondGuarantorIdentityOption === "PASSPORT"
                    }
                    onChange={() =>
                      handleSecondGuarantorIdentityRadioButtonChange("PASSPORT")
                    }
                  />
                </div>
                {errors.SGVerificationMethod && (
                  <div className="font-normal text-left text-red-500 font-poppins text-[12px] md:text-[13px] ml-[1px] ">
                    {errors.SGVerificationMethod}
                  </div>
                )}

                {secondGuarantorsIdUploadDropdown && (
                  <div className="grid mb-3 xxs:gap-4 xxs:grid-cols-2">
                    <UploadImageInput
                      ref={(el) => (inputRefs.current.SGFrontImageURL = el)}
                      id={"SGFrontImageURL"}
                      name={"SGFrontImageURL"}
                      text={"ඉදිරිපස"}
                      placeholder={""}
                      onFileSelect={(file) =>
                        handleImageChange(file, "SGFrontImageURL")
                      }
                      errorMessage={errors.SGFrontImageURL}
                      selectImage={
                        formData.SGFrontImageURL
                          ? `${formData.SGFrontImageURL}`
                          : ""
                      }
                      isLoading={loadingImages.SGFrontImageURL}
                    />
                    <UploadImageInput
                      ref={(el) => (inputRefs.current.SGBackImageURL = el)}
                      id={"SGBackImageURL"}
                      name={"SGBackImageURL"}
                      text={"පසුපස"}
                      placeholder={""}
                      onFileSelect={(file) =>
                        handleImageChange(file, "SGBackImageURL")
                      }
                      errorMessage={errors.SGBackImageURL}
                      selectImage={
                        formData.SGBackImageURL
                          ? `${formData.SGBackImageURL}`
                          : ""
                      }
                      isLoading={loadingImages.SGBackImageURL}
                    />
                  </div>
                )}

                <div className="mt-8 mb-8">
                  <Description
                    content="ජල බිල හෝ විදුලි බිලෙහි පිටපතක්  අමුණන්න"
                    fontWeight="font-bold"
                  />
                  <UploadImageInput
                    ref={(el) =>
                      (inputRefs.current.SGElectricityBillOrWaterImageURL = el)
                    }
                    id={"SGElectricityBillOrWaterImageURL"}
                    name={"SGElectricityBillOrWaterImageURL"}
                    text={""}
                    placeholder={""}
                    onFileSelect={(file) =>
                      handleImageChange(
                        file,
                        "SGElectricityBillOrWaterImageURL"
                      )
                    }
                    errorMessage={errors.SGElectricityBillOrWaterImageURL}
                    selectImage={
                      formData.SGElectricityBillOrWaterImageURL
                        ? `${formData.SGElectricityBillOrWaterImageURL}`
                        : ""
                    }
                    isLoading={loadingImages.SGElectricityBillOrWaterImageURL}
                  />
                </div>

                {/*second guarantor signature  */}
                <div>
                  <Description
                    content="අත්සන"
                    fontWeight="font-bold"
                    fontSize="text-h7 lg:text-h6 3xl:text-h3"
                  />
                  <div className="mt-2">
                    <Checkbox
                      onChange={handleSecondGuarantorCheckboxChange}
                      checked={isSecondUploadMode}
                      label="Upload Image"
                    />
                  </div>

                  {isSecondUploadMode ? (
                    // Image upload input appears when the checkbox is checked
                    <div className="mb-5">
                      <UploadImageInput
                        ref={(el) =>
                          (inputRefs.current.SGSignatureImageURL = el)
                        }
                        id={"SGSignatureImageURL"}
                        name={"SGSignatureImageURL"}
                        text={""}
                        placeholder={""}
                        onFileSelect={(file) =>
                          handleImageChangeSignatures(
                            file,
                            "SGSignatureImageURL"
                          )
                        }
                        selectImage={
                          formData.SGSignatureImageURL
                            ? `${formData.SGSignatureImageURL}`
                            : ""
                        }
                        isLoading={loadingImages.SGSignatureImageURL}
                      />
                    </div>
                  ) : (
                    // Signature area appears when the checkbox is unchecked
                    <div
                      className="h-40 p-2 mt-2 mb-5 bg-white border-2 rounded-lg cursor-pointer max-w-[100%] border-primaryColor-200"
                      onClick={handleSecondSignatureModalOpen}
                      ref={(el) => (inputRefs.current.SGSignatureImageURL = el)}
                    >
                      {formData.SGSignatureImageURL ? (
                        <img
                          src={formData.SGSignatureImageURL}
                          className="object-contain w-full h-full"
                        />
                      ) : (
                        <div className="flex items-center justify-center h-full underline duration-300 text-primaryColor-200 hover:scale-105">
                          <Description
                            content="Sign here"
                            fontWeight="font-medium"
                            fontColor="text-primaryColor-200"
                            center
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {errors.SGSignatureImageURL && (
                  <div className="font-normal text-left text-red-500 font-poppins text-[12px] md:text-[13px] ml-[1px] ">
                    {errors.SGSignatureImageURL}
                  </div>
                )}
              </div>
            </>
          )}
          <div className="flex justify-center">
            <Button text="ඊළඟ පිටුවට යන්න" onClick={handleNext} />
          </div>
        </Main>

        <div>
          {/*first guaranter signature modal */}
          <SignatureModal
            visible={isSignatureModalVisible}
            onClose={handleFirstSignatureModalClose}
            onOk={handleFirstSignatureModalOk}
            signurl={
              isFirstGuarantorSignatureUrl ? isFirstGuarantorSignatureUrl : ""
            }
            onClear={handleFirstSignatureModalClear}
          />

          {/*second guaranter signature modal */}
          <SignatureModal
            visible={isSecondSignatureModalVisible}
            onClose={handleSecondSignatureModalClose}
            onOk={handleSecondSignatureModalOk}
            onClear={handleSecondSignatureModalClear}
            signurl={
              isSecondGuarantorSignatureUrl ? isSecondGuarantorSignatureUrl : ""
            }
          />

          {/*  has not guarantors */}
          {isCollateralOnly && (
            <PrimaryModal
              visible={isCollateralOnly}
              mainTitle={"ඇප භාණ්ඩයක් පමණක් තබන්නේද?"}
              content={""}
              primaryBtnLabel="Yes"
              secondaryBtnLabel="No"
              primaryOnClick={handleNavigateNext}
              secondaryOnClick={handleCloseAskCollateralOnly}
              onClose={handleCloseAskCollateralOnly}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Step4;
