import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import {
  Button,
  Description,
  Input,
  Logo,
  PrimaryModal,
  PrimaryTitle,
} from "../../common";
import { IoMailOutline } from "react-icons/io5";
import { TfiLock } from "react-icons/tfi";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { ForgetPassword } from "../../modal";
import { loginSchema } from "../../../lib/validation/login-val";
import toast from "react-hot-toast";
import { login } from "../../../api/auth";

const LoginPage: React.FC = () => {
  const navigate = useNavigate(); // Hook to navigate between routes
  const [passwordVisible, setPasswordVisible] = useState(false); // State to toggle password visibility
  const [isOpenForgetPassword, setIsOpenForgetPassword] = useState(false); // State to manage forget password modal visibility
  const [formData, setFormData] = useState({
    identifier: "",
    password: "",
  }); // State to hold form data
  const [errors, setErrors] = useState<Record<string, string>>({}); // State to hold validation errors
  const [isFirstValidation, setIsFirstValidation] = useState(false); // State to check if initial validation has been done
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenLoginSuccess, setIsOpenSuccess] = useState(false);

  // handle close successful moda
  const handleCloseSuccessModal = () => {
    setIsOpenSuccess(false);
  };

  // Function to open forget password modal
  const handleOpenForgetPassword = () => {
    setIsOpenForgetPassword(true);
  };

  // Function to close forget password modal
  const handleCloseForgetPassword = () => {
    setIsOpenForgetPassword(false);
  };

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Function to handle input changes and validate form on change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Remove spaces from identifier input
    const sanitizedValue =
      name === "identifier" ? value.replace(/\s+/g, "") : value;

    setFormData({
      ...formData,
      [name]: sanitizedValue,
    });

    if (isFirstValidation) {
      validateForm({ ...formData, [name]: sanitizedValue });
    }
  };

  // Function to validate form data using Zod schema
  const validateForm = (data: typeof formData) => {
    try {
      loginSchema.parse(data);
      setErrors({});
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          newErrors[err.path[0]] = err.message;
        });
        setErrors(newErrors);
        return false;
      }
      return false;
    }
  };

  // Function to handle login button click
  const handleLoginClick = async () => {
    setIsFirstValidation(true);
    if (validateForm(formData)) {
      // start button loading
      setIsLoading(true);
      // login api
      try {
        const response = await login(formData);
        if (response.success) {
          setIsOpenSuccess(true);
          // Save token in local storage
          localStorage.setItem("token", response.data.token);
        } else {
          // If response failed
          toast.error(response.message);
        }
      } catch (response) {
        console.log("system Error",response);
        toast.error("System Error");
      } finally {
        // end button loading
        setIsLoading(false);
      }
    }
  };

  // handle navigate dashboard
  const handleNavigateDashbaord = () => {
    navigate("/dashboard");
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleLoginClick();
    }
  };

  return (
    <main
      className="flex items-center justify-center w-screen h-screen bg-white"
      style={{
        backgroundImage: "url(/images/loginBackground.png)",
        backgroundSize: "cover", // Cover the entire screen
        backgroundPosition: "center", // Center the image
      }}
      onKeyDown={handleKeyDown}
    >
      <div className="w-full max-w-[500px] flex flex-col items-center">
        <div className="absolute top-16">
          {/* Application name */}
          <Logo />
        </div>

        {/* Login form */}
        <div className="flex flex-col items-center w-full px-5 ">
          {/* Screen name */}
          <PrimaryTitle
            text="Login Here"
            fontWeight="font-bold"
            center
            fontSize="text-h5 md:text-h4 lg:text-h3 3xl:text-h2"
          />
          <div className="w-full mt-3">
            {/* Email or phone number input */}
            <Input
              frontIcon={<IoMailOutline className="lg:mt-0.5" />}
              id="identifier"
              name="identifier"
              placeholder="Email Address or Phone Number"
              value={formData.identifier}
              onChange={handleChange}
              errorMessage={errors.identifier}
              bgColor="bg-white"
            />
            {/* Password input */}
            <Input
              frontIcon={<TfiLock />}
              backIcon={
                passwordVisible ? (
                  <AiOutlineEyeInvisible onClick={togglePasswordVisibility} />
                ) : (
                  <AiOutlineEye onClick={togglePasswordVisibility} />
                )
              }
              id="password"
              name="password"
              type={passwordVisible ? "text" : "password"}
              placeholder="Enter Password"
              value={formData.password}
              onChange={handleChange}
              errorMessage={errors.password}
              bgColor="bg-white"
            />
          </div>

          {/* Forget password link */}
          <div onClick={handleOpenForgetPassword} className="cursor-pointer">
            <Description
              fontWeight="font-bold"
              content="Forgot Password?"
              center
            />
          </div>

          {/* Login button */}
          <div className="flex justify-center mt-5">
            <Button
              text="Login"
              fontWeight="font-bold"
              onClick={handleLoginClick}
              isLoading={isLoading}
              disabled={isLoading ? true : false}
            />
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="absolute bottom-5">
        <Description
          content={"Developed By Zynovatex"}
          fontWeight="font-semibold"
        />
      </div>

      {/* Forget password modal */}
      <ForgetPassword
        visible={isOpenForgetPassword}
        onClose={handleCloseForgetPassword}
        onOk={function (): void {
          throw new Error("Function not implemented.");
        }}
      />

      {/* login Successfully modal  */}
      <PrimaryModal
        visible={isOpenLoginSuccess}
        mainTitle={"Login Successfully"}
        content={"You are Successfully Login."}
        onClose={handleCloseSuccessModal}
        primaryBtnLabel="Go to Dashboard"
        primaryOnClick={handleNavigateDashbaord}
      />
    </main>
  );
};

export default LoginPage;
