import React, { ChangeEvent } from "react";
import { Modal } from "antd";
import { Button, Input, PrimaryTitle } from "../common";

interface BlacklistOverviewProps {
  visible: boolean;
  onClose?: () => void;
  closeIcon?: React.ReactNode;
  primaryOnClick?: () => void;
  secondaryOnClick?: () => void;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const FreeInstallmentsCount: React.FC<BlacklistOverviewProps> = ({
  visible,
  onClose,
  closeIcon,
  primaryOnClick,
  secondaryOnClick,
  onChange,
  value,
}) => {
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      centered
      footer={null}
      closeIcon={closeIcon}
      className="custom-modal"
    >
      <div className="p-5">
        <PrimaryTitle
          text={"Installments to be released"}
          fontSize="text-h5 md:text-h4 lg:text-h3 3xl:text-h2"
          center
        />

        <div className="mt-5">
          {/* installments count  */}
          <Input
            id="releasedInstallmentsCount"
            name="releasedInstallmentsCount"
            placeholder="Enter the count"
            label=""
            type="number"
            width="w-[100%]"
            labelWeight="font-semibold"
            value={value}
            onChange={onChange}
            minValue="0"
          />
          <div className="flex justify-center gap-4 mt-4">
            <Button
              text={"Cancel"}
              onClick={secondaryOnClick}
              variant="secondary"
            />

            <Button
              text={"Done"}
              onClick={primaryOnClick}
              // disabled={Number(value) === 0}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FreeInstallmentsCount;
