import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Button,
  Description,
  Dropdown,
  Input,
  LoanAmountDetails,
  RadioButton,
} from "../../../common";
import { z } from "zod";
import { Step3Schema } from "../../../../lib/validation/customer-register-val";
import { CiEdit } from "react-icons/ci";
import { calculateInterest } from "../../../../api/calculation";
import toast from "react-hot-toast";
import {
  getIntrestRates,
  getLoanDurations,
  updateIntrestRate,
} from "../../../../api/loan";
import { useGetCustomerDetails } from "../../../../hooks/useGetCustomerDetails";

interface Step3Props {
  setSelect: (step: number) => void;
}

const Step3: React.FC<Step3Props> = ({ setSelect }) => {
  const [selectedOption, setSelectedOption] = useState<string>("");

  const { customerDetails } = useGetCustomerDetails();

  const [formData, setFormData] = useState({
    companyName: "",
    occupation: "",
    companyAddress: "",
    netMonthlySalary: "",
    loanAmount: "",
    repaymentFrequency: "",
    selectedNumberofInstallment: "",
    startDate: "",
    endDate: "",
    numberOfInstallments: "0",
    getInterestAmount: "0",
    getinstallmentAmount: "0",
  });
  const [loanDurations, setloanDurations] = useState({
    day: "",
    month: "",
    week: "",
    id: "",
  });

  const inputRefs = useRef<
    Record<string, HTMLInputElement | HTMLDivElement | null>
  >({
    companyName: null,
    occupation: null,
    companyAddress: null,
    netMonthlySalary: null,
    loanAmount: null,
    repaymentFrequency: null,
    selectedNumberofInstallment: null,
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isFirstValidation, setIsFirstValidation] = useState(false);
  const [isOpenPaymentSection, setIsOpenPaymentSection] = useState(false);
  const [selectedinstallments, setSelectedInstallments] = useState("වාරික");
  const [interestRates, setInterestRates] = useState<{ rate: number }[]>([]);
  const [slectedIntrestRate, setSelectedIntrestRate] = useState(0);
  const [selectedIntrest, setSelectedIntrest] = useState("");
  const [isAscending, setIsAscending] = useState(true);

  // handle update interest rate
  const handleUpdateIntrestRate = async () => {
    const token = localStorage.getItem("token") || "";
    const newRate = selectedIntrest || "";

    try {
      const resposne = await updateIntrestRate(token, Number(newRate));
      if (resposne === "Regular rate updated successfully.") {
        handleCalculate();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedIntrest) {
      handleUpdateIntrestRate();
    }
  }, [slectedIntrestRate]);

  // Function to fetch interest rates from API
  useEffect(() => {
    const handleGetInterestRates = async () => {
      const token = localStorage.getItem("token") || "";
      try {
        const response = await getIntrestRates(token);
        if (response) {
          setInterestRates(response.data);
        } else {
          console.log("No interest rates available");
        }
      } catch (error) {
        console.error("Failed to fetch interest rates", error);
      }
    };

    handleGetInterestRates();
  }, []);

  const handleInterestRateSelect = (selectedRate: string) => {
    setSelectedIntrestRate(Number(selectedRate));
    setSelectedIntrest(selectedRate);
    localStorage.setItem("newInterestRate", selectedRate);
  };

  useEffect(() => {
    const newInterestRate = Number(localStorage.getItem("newInterestRate"));
    if (newInterestRate) {
      setSelectedIntrestRate(newInterestRate);
    }
  }, []);

  // select radio button
  const handleRadioButtonChange = (value: string) => {
    let installmentsValue = "";
    let installmentsText = "";
    switch (value) {
      case "DAILY":
        installmentsValue = String(loanDurations.day);
        installmentsText = "දින වාරික";
        break;
      case "WEEKLY":
        installmentsValue = String(loanDurations.week);
        installmentsText = "සති වාරික";
        break;
      case "MONTHLY":
        installmentsValue = String(loanDurations.month);
        installmentsText = "මාසික වාරික";
        break;
      default:
        break;
    }

    setSelectedInstallments(installmentsText);
    setSelectedOption(value);
    setFormData({
      ...formData,
      repaymentFrequency: value,
      selectedNumberofInstallment: installmentsValue,
    });

    setErrors({
      ...errors,
      repaymentFrequency: "",
      selectedNumberofInstallment: "",
    });
  };

  // Function to validate form data using Zod schema
  const validateForm = (data: typeof formData) => {
    try {
      Step3Schema.parse(data);
      setErrors({});
      return { isValid: true, newErrors: {} };
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          newErrors[err.path[0]] = err.message;
        });
        setErrors(newErrors);
        return { isValid: false, newErrors };
      }
      return { isValid: false, newErrors: {} };
    }
  };

  //   check input fields, validation
  const validateBeforeCalculate = () => {
    const tempErrors = {
      ...errors,
      repaymentFrequency: "",
      selectedNumberofInstallment: "",
      loanAmount: "",
      slectedIntrestRate: "", // Add this field to store the interest rate validation error
    };

    if (!formData.repaymentFrequency) tempErrors.repaymentFrequency = "අවශ්යයි";
    if (!formData.selectedNumberofInstallment) {
      tempErrors.selectedNumberofInstallment = "අවශ්යයි";
    } else if (parseInt(formData.selectedNumberofInstallment) <= 0) {
      tempErrors.selectedNumberofInstallment =
        "වාරික ගණන 0 ට වඩා වැඩි විය යුතුය";
    }
    if (!formData.loanAmount) {
      tempErrors.loanAmount = "අවශ්යයි";
    } else if (parseFloat(formData.loanAmount) <= 0) {
      tempErrors.loanAmount = "ණය මුදලේ වටිනාකම 0 ට වඩා වැඩි විය යුතුය";
    }

    // Validate the interest rate dropdown
    if (!slectedIntrestRate || slectedIntrestRate === 0) {
      tempErrors.slectedIntrestRate = "පොලී අනුපාතය තෝරන්න";
    }

    setErrors(tempErrors);

    return !Object.values(tempErrors).some((error) => error !== "");
  };

  // Function to handle input changes and validate form on change
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (isFirstValidation) {
      validateForm({ ...formData, [name]: value });
    }
  };

  const calculateAndUpdateForm = async () => {
    if (
      formData.selectedNumberofInstallment &&
      parseFloat(formData.loanAmount) >= 0
    ) {
      const requestBody = {
        repaymentFrequency: formData.repaymentFrequency,
        loanAmount: parseFloat(formData.loanAmount), // Ensure loanAmount is a double
        duration: parseInt(formData.selectedNumberofInstallment, 10),
      };

      try {
        const response = await calculateInterest(requestBody);

        if (response.success) {
          setFormData({
            ...formData,
            startDate: response.data.startDate,
            endDate: response.data.endDate,
            numberOfInstallments: String(response.data.numberOfInstallments),
            getInterestAmount: String(response.data.totalInterest.toFixed(2)),
            getinstallmentAmount: String(
              response.data.installmentAmount.toFixed(2)
            ),
          });
          setIsOpenPaymentSection(true);
        } else {
          toast.error(response.response.data.message);
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    }
  };
  useEffect(() => {
    const newSelectedRate = localStorage.getItem("newInterestRate");
    if (newSelectedRate) {
      calculateAndUpdateForm();
    }
  }, [formData.selectedNumberofInstallment, formData.loanAmount]);

  //get loan durations
  useEffect(() => {
    const fetchLoanDurations = async () => {
      const token = localStorage.getItem("token") || "";

      try {
        const response = await getLoanDurations(token);
        if (response) {
          setloanDurations({
            day: response[0].day,
            month: response[0].month,
            week: response[0].week,
            id: response[0].id,
          });
        } else {
          console.log("No response data for loan Durations");
        }
      } catch (error) {
        console.log(" get data error -", error);
      }
    };

    fetchLoanDurations();
  }, []);

  // Function to handle registration step1
  const handleNext = async () => {
    setIsFirstValidation(true);
    const { isValid, newErrors } = validateForm(formData);

    if (isValid && validateBeforeCalculate()) {
      localStorage.setItem(
        "customerRegisterFormData3",
        JSON.stringify(formData)
      );
      setSelect(4);
    } else {
      // required fields
      scrollToFirstError(newErrors);
    }
  };

  const scrollToFirstError = (newErrors: Record<string, string>) => {
    const orderedErrors: Record<string, string> = {};

    Object.keys(formData).forEach((key) => {
      if (newErrors[key]) {
        orderedErrors[key] = newErrors[key];
      }
    });

    const firstErrorKey = Object.keys(orderedErrors)[0];

    if (firstErrorKey && inputRefs.current[firstErrorKey]) {
      const element = inputRefs.current[firstErrorKey];
      const elementOffsetTop =
        element.getBoundingClientRect().top + window.scrollY;

      // Scroll to the element smoothly
      window.scrollTo({
        top: elementOffsetTop - 80, // Offset by 20px from the top
        behavior: "smooth",
      });
    }
  };

  const handleCalculate = async () => {
    if (validateBeforeCalculate()) {
      setFormData({
        ...formData,
        startDate: "",
        endDate: "",
        numberOfInstallments: "",
        getInterestAmount: "",
        getinstallmentAmount: "",
      });

      try {
        const requestBody = {
          repaymentFrequency: formData.repaymentFrequency,
          loanAmount: parseFloat(formData.loanAmount), // Ensure loanAmount is a double
          duration: parseInt(formData.selectedNumberofInstallment, 10),
        };

        const response = await calculateInterest(requestBody);

        if (response.success) {
          setFormData({
            ...formData,
            startDate: response.data.startDate,
            endDate: response.data.endDate,
            numberOfInstallments: String(response.data.numberOfInstallments),
            getInterestAmount: String(response.data.totalInterest.toFixed(2)),
            getinstallmentAmount: String(
              response.data.installmentAmount.toFixed(2)
            ),
          });
          setIsOpenPaymentSection(true);
        } else {
          toast.error(response.response.data.message);
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    }
  };

  // Populate formData from localStorage first, then update specific fields from customerDetails
  useEffect(() => {
    const savedData = localStorage.getItem("customerRegisterFormData3");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setFormData((prevData) => ({ ...prevData, ...parsedData }));
      setSelectedOption(parsedData.repaymentFrequency || "");
    } else if (customerDetails) {
      // Update only the fields you need from customerDetails, like companyName
      setFormData((prevData) => ({
        ...prevData,
        companyName: customerDetails.companyName || "",
        occupation: customerDetails.occupation || "",
        companyAddress: customerDetails.companyAddress || "",
      }));
    }
  }, [customerDetails]);

  // Custom handler if needed
  const customKeyPressHandler = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "-" || event.key === "e") {
      event.preventDefault();
    }
  };

  return (
    <div>
      <div className="grid md:gap-3 md:grid-cols-2">
        <Input
          ref={(el) => (inputRefs.current.companyName = el)}
          id={"companyName"}
          name={"companyName"}
          placeholder={"සේවා ස්ථානය "}
          label="සේවා ස්ථානය*"
          labelWeight="font-bold"
          onChange={handleChange}
          value={formData.companyName}
          errorMessage={errors.companyName}
        />

        <Input
          ref={(el) => (inputRefs.current.occupation = el)}
          id={"occupation"}
          name={"occupation"}
          placeholder={"රැකියාව "}
          label="රැකියාව*"
          labelWeight="font-bold"
          onChange={handleChange}
          value={formData.occupation}
          errorMessage={errors.occupation}
        />
      </div>

      <Input
        ref={(el) => (inputRefs.current.companyAddress = el)}
        id={"companyAddress"}
        name={"companyAddress"}
        placeholder={"සේවා ස්ථානයෙ ලිපිනය "}
        label="සේවා ස්ථානයෙ ලිපිනය*"
        labelWeight="font-bold"
        onChange={handleChange}
        value={formData.companyAddress}
        errorMessage={errors.companyAddress}
      />

      <Input
        ref={(el) => (inputRefs.current.netMonthlySalary = el)}
        id={"netMonthlySalary"}
        name={"netMonthlySalary"}
        placeholder={"දළ මාසික ආදායම (රු) "}
        type="number"
        label="දළ මාසික ආදායම (රු)*"
        labelWeight="font-bold"
        width="sm:w-[50%]"
        onChange={handleChange}
        value={formData.netMonthlySalary}
        errorMessage={errors.netMonthlySalary}
        minValue="0"
        handleNegativeMark={customKeyPressHandler}
        restrictSpaces
      />

      <Input
        ref={(el) => (inputRefs.current.loanAmount = el)}
        id={"loanAmount"}
        name={"loanAmount"}
        type="number"
        placeholder={"ඉල්ලුම් කරන ණය මුදල (රු) "}
        label="ඉල්ලුම් කරන ණය මුදල (රු)*"
        labelWeight="font-bold"
        width="sm:w-[50%]"
        onChange={handleChange}
        value={formData.loanAmount}
        errorMessage={errors.loanAmount}
        minValue="0"
        handleNegativeMark={customKeyPressHandler}
        restrictSpaces
      />

      {/* interest rate  */}
      <div className="sm:w-[50%]  ">
        <Dropdown
          label="පොලී අනුපාතය*"
          placeholder="පොලී අනුපාතය තෝරන්න"
          options={interestRates.map((rateObj) => `${rateObj.rate}`)}
          selectedOption={slectedIntrestRate ? `${slectedIntrestRate}%` : ""}
          onOptionSelect={handleInterestRateSelect}
          fontWeight="font-bold"
          errorMessage={errors.slectedIntrestRate}
          onClick={() => {
            // Sort the interest rates and toggle sorting direction
            const sortedRates = [...interestRates].sort((a, b) => {
              return isAscending ? a.rate - b.rate : b.rate - a.rate;
            });
            setInterestRates(sortedRates);
            setIsAscending(!isAscending);
          }}
          isAscending={isAscending}
          sortEnable={true}
        />
      </div>

      <div>
        <Description content="ගෙවීම් සැලසුම*" fontWeight="font-bold" />
        <div className="flex flex-wrap gap-5 mt-3 lg:gap-10">
          <RadioButton
            id={"dailyInstallments"}
            label={"දින වාරික"}
            checked={selectedOption === "DAILY"}
            onChange={() => handleRadioButtonChange("DAILY")}
          />
          <RadioButton
            id={"weeklyInstallments"}
            label={"සති වාරික"}
            checked={selectedOption === "WEEKLY"}
            onChange={() => handleRadioButtonChange("WEEKLY")}
          />
          <RadioButton
            id={"monthlyInstallments"}
            label={"මාසික වාරික"}
            checked={selectedOption === "MONTHLY"}
            onChange={() => handleRadioButtonChange("MONTHLY")}
          />
        </div>
        {errors.repaymentFrequency && (
          <div className="font-normal text-left text-red-500 font-poppins text-[12px] md:text-[13px] ml-[1px] ">
            {errors.repaymentFrequency}
          </div>
        )}
      </div>

      <div className="mt-3 ">
        <Input
          ref={(el) => (inputRefs.current.selectedNumberofInstallment = el)}
          id={"selectedNumberofInstallment"}
          name={"selectedNumberofInstallment"}
          placeholder={`ණය ගෙවිය යුතු ${selectedinstallments} ගණන`}
          label={`ණය ගෙවිය යුතු ${selectedinstallments} ගණන*`}
          type="number"
          labelWeight="font-bold"
          width="sm:w-[50%]"
          onChange={handleChange}
          value={formData.selectedNumberofInstallment}
          errorMessage={errors.selectedNumberofInstallment}
          buttonIcon={<CiEdit />}
          disabled={true}
          minValue="0"
          handleNegativeMark={customKeyPressHandler}
          restrictSpaces
        />
      </div>

      <div className="flex justify-start mt-5">
        <Button
          text="ගණනය කරන්න"
          onClick={handleCalculate}
          fontSize="text-h9 md:text-h8"
          width="w-36"
        />
      </div>
      {isOpenPaymentSection && (
        <div className="">
          <div className="grid mt-5 md:gap-3 md:grid-cols-2 ">
            <Input
              type="date"
              id={"startDate"}
              name={"startDate"}
              placeholder={"ගෙවීම් ආරම්භක දිනය"}
              label="ගෙවීම් ආරම්භක දිනය"
              labelWeight="font-bold"
              onChange={handleChange}
              value={formData.startDate}
              errorMessage={errors.startDate}
              minValue={formData.startDate}
              disabled
            />

            <Input
              type="date"
              id={"endDate"}
              name={"endDate"}
              placeholder={"ගෙවීම් අවසාන දිනය"}
              label="ගෙවීම් අවසාන දිනය"
              labelWeight="font-bold"
              onChange={handleChange}
              value={formData.endDate}
              errorMessage={errors.endDate}
              minValue={formData.startDate}
              disabled
            />
          </div>

          <div>
            <div className="grid sm:gap-3 sm:grid-cols-2">
              <LoanAmountDetails
                content={"වාරික ගණන"}
                number={formData.numberOfInstallments}
              />

              <LoanAmountDetails
                content={"පොලිය"}
                number={formData.getInterestAmount}
              />
            </div>

            <LoanAmountDetails
              content={"වාරික මුදල (රු)"}
              number={formData.getinstallmentAmount}
            />
          </div>
        </div>
      )}

      <div className="flex justify-center mt-5">
        <Button text="ඊළඟ පිටුවට යන්න" onClick={handleNext} />
      </div>
    </div>
  );
};

export default Step3;
