import React, { useEffect, useState } from "react";
import type { TableProps } from "antd";
import { Empty, Form, Table, Typography } from "antd";
import { Description, Dropdown, Input } from "../../common";
import { allCustomers, getCustomerforLoanReview } from "../../../api/customer";
import { useNavigate } from "react-router-dom";
import { getCollectors } from "../../../api/employee";
import { QRGenerate, ScheduleListModal } from "../../modal";
import { CustomerData } from "../../../types";

interface Item {
  id: number;
  customerId: string;
  fullName: string;
  collectorUsername: string;
  address: string;
  startDate: string;
  loanStatus: string;
  collImgUrl?: string;
  loanAmount?: number;
  loanReleaseStatus: boolean;
  customerNic: string;
  loanId: number;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "number" | "text";
  record: Item;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        ></Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

interface collectorsTypes {
  collectorUserName: string;
}

const A_AllCustomersTable = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [customersData, setCustomersData] = useState<Item[]>([]);
  const [selectedLoanStatus, setSelectedLoanStatus] = useState<string>("All");
  const [searchText, setSearchText] = useState<string>("");
  const [allCollectors, setAllCollectors] = useState<collectorsTypes[]>([]);
  const [selectedCollector, setSelectedCollector] = useState<string>("All");
  const [qrModalVisible, setQrModalVisible] = useState<boolean>(false);
  const [selectedCustomerDetails, setSelectedCustomerDetails] =
    useState<CustomerData>();
  const [getCustomerNIC, setCustoemrNIC] = useState<string>("");
  const [isOpenCScheduleList, setIsOpenScheduleList] = useState(false);
  const [getCustomerName, setCustomerName] = useState<string>("");
  const [getCustomerAddress, setCustomerAddress] = useState<string>("");
  const [getCustomerLoanId, setGetCustomerLoanId] = useState<number>(0);

  // handle schedule list modal open
  const handleOpenScheduleListModal = (
    customerNic: string,
    fullName: string,
    address: string,
    loanId: number
  ) => {
    setCustoemrNIC(customerNic);
    setCustomerName(fullName);
    setCustomerAddress(address);
    setGetCustomerLoanId(loanId);
    setIsOpenScheduleList(true);
  };
  const handleCloseScheduleMOdal = () => {
    setIsOpenScheduleList(false);
  };

  const handleQRModalOpen = async (customerId: string) => {
    try {
      const token = localStorage.getItem("token") || "";

      // Fetch customer details for the selected customer ID
      const response = await getCustomerforLoanReview(
        Number(customerId),
        token
      );
      const customerData = response.data;

      // Set the customer details in the state for the QR modal
      setSelectedCustomerDetails(customerData);

      // Open the QR modal
      setQrModalVisible(true);
    } catch (error) {
      console.error("Error fetching customer details: ", error);
    }
  };

  const handleQRModalClose = () => {
    setQrModalVisible(false);
  };

  // navigate edit customer overview
  const handleNavigate = (customerId: string) => {
    navigate(`/customer-update-overview/${customerId}`);
  };

  // customers table  column
  const columns = [
    {
      title: "ID",
      dataIndex: "customerId",
      fixed: "left" as const,
      align: "center" as const,
      sorter: (a: Item, b: Item) =>
        parseInt(a.customerId) - parseInt(b.customerId),
    },
    {
      title: "Customer Name",
      dataIndex: "fullName",
      align: "center" as const,
      sorter: (a: Item, b: Item) => a.fullName.localeCompare(b.fullName),
    },
    {
      title: "Registered By",
      dataIndex: "collectorUsername",
      align: "center" as const,
    },
    {
      title: "Customer Address",
      dataIndex: "address",
      align: "center" as const,
    },
    {
      title: "Applied Date",
      dataIndex: "startDate",
      align: "center" as const,
      sorter: (a: Item, b: Item) =>
        new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
    },
    {
      title: "Loan Amount (Rs.)",
      dataIndex: "loanAmount",
      render: (amount: number) => `${amount.toFixed(2)}`,
      align: "center" as const,
      sorter: (a: Item, b: Item) => (a.loanAmount || 0) - (b.loanAmount || 0),
    },
    {
      title: "Loan Status",
      dataIndex: "loanStatus",
      align: "center" as const,
      render: (text: string) => {
        let color = "";
        if (text === "PENDING") {
          color = "gray";
        } else if (text === "APPROVED") {
          color = "green";
        } else if (text === "COMPLETE") {
          color = "blue";
        } else if (text === "REJECTED") {
          color = "#ff4d4f";
        } else if (text === "LEGAL_ACTION") {
          color = "#a8071a";
        }
        return <span style={{ color, fontWeight: "bold" }}>{text}</span>;
      },
    },
    {
      title: "Cash Release Status",
      dataIndex: "loanReleaseStatus",
      align: "center" as const,
      render: (text: boolean) => {
        const statusText = text ? "RELEASED" : "PENDING";
        const color = text ? "green" : "gray";
        return <span style={{ color, fontWeight: "bold" }}>{statusText}</span>;
      },
    },
    {
      title: "View",
      dataIndex: "customerOverview",
      align: "center" as const,
      render: (_: number, record: Item) => {
        return (
          <div className="flex justify-center">
            <Typography.Link>
              <div
                onClick={() => handleNavigate(record.customerId)}
                className="flex justify-center w-20 px-1 font-semibold text-center text-green-600 rounded active:shadow-inner-hard active:text-primaryColor-900"
              >
                View
              </div>
            </Typography.Link>
          </div>
        );
      },
    },
    {
      title: "QR Generate",
      dataIndex: "qrGenerate",
      align: "center" as const,
      render: (_: number, record: Item) => {
        return record.loanStatus === "APPROVED" && record.loanReleaseStatus ? (
          <div className="flex justify-center">
            <Typography.Link>
              <div
                onClick={() => handleQRModalOpen(record.customerId)}
                className="flex justify-center w-20 px-1 font-semibold text-center text-green-600 rounded active:shadow-inner-hard active:text-primaryColor-900"
              >
                Generate
              </div>
            </Typography.Link>
          </div>
        ) : (
          <div className="flex justify-center">-</div>
        );
      },
    },
    {
      title: "Installments",
      dataIndex: "customerOverview",
      align: "center" as const,
      render: (_: number, record: Item) => {
        return record.loanStatus === "APPROVED" ||
          (record.loanStatus === "COMPLETE" && record.loanReleaseStatus) ? (
          <div className="flex justify-center">
            <Typography.Link>
              <div
                onClick={() =>
                  handleOpenScheduleListModal(
                    record.customerNic,
                    record.fullName,
                    record.address,
                    record.loanId
                  )
                }
                className="flex justify-center w-20 px-1 font-semibold text-center text-green-600 rounded active:shadow-inner-hard active:text-primaryColor-900"
              >
                Download
              </div>
            </Typography.Link>
          </div>
        ) : (
          <div className="flex justify-center">-</div>
        );
      },
    },
  ];

  const mergedColumns: TableProps["columns"] = columns.map((col) => {
    if (!col) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "number" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  // Get all collectors
  useEffect(() => {
    const token = localStorage.getItem("token") || "";

    const fetchCollectors = async () => {
      try {
        const response = await getCollectors(token);
        setAllCollectors([{ collectorUserName: "All" }, ...response]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCollectors();
  }, []);

  // Fetch all customers data
  useEffect(() => {
    const getCustomersData = async () => {
      const token = localStorage.getItem("token") || "";
      const userId = localStorage.getItem("userId");

      if (!userId) {
        console.error("User ID is not available");
        return;
      }

      try {
        const response = await allCustomers(token, parseInt(userId));

        // Sort the customersData by customerId in ascending order
        const sortedData = response.data.sort(
          (a: Item, b: Item) => parseInt(b.customerId) - parseInt(a.customerId)
        );

        setCustomersData(sortedData);
      } catch (error) {
        console.log(error);
      }
    };

    getCustomersData();
  }, []);

  // Loan status options
  const loanStatus = [
    "All",
    "PENDING",
    "APPROVED",
    "COMPLETE",
    "REJECTED",
    "LEGAL_ACTION",
  ];

  // Filter data based on selected loan status, search text, and selected collector
  const filteredData = customersData
    .filter(
      (customer) =>
        (selectedLoanStatus === "All" ||
          customer.loanStatus === selectedLoanStatus) &&
        (selectedCollector === "All" ||
          customer.collectorUsername === selectedCollector)
    )
    .filter((customer) => {
      const searchValue = searchText.toLowerCase();
      const fullName = customer.fullName.toLowerCase();
      const address = customer.address.toLowerCase();
      return fullName.includes(searchValue) || address.includes(searchValue);
    });

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleCollectorSelect = (option: string) => {
    setSelectedCollector(option);
  };

  // Options for the manager dropdown
  const collectors = allCollectors.map(
    (response) => response.collectorUserName
  );

  // Calculate totals for filtered data
  const totalLoanAmount = filteredData.reduce(
    (sum, item) => sum + (item.loanAmount || 0),
    0
  );

  // Calculate approved and completed loan amounts
  const approvedLoanAmount = filteredData
    .filter((item) => item.loanStatus === "APPROVED")
    .reduce((sum, item) => sum + (item.loanAmount || 0), 0);

  const completedLoanAmount = filteredData
    .filter((item) => item.loanStatus === "COMPLETE")
    .reduce((sum, item) => sum + (item.loanAmount || 0), 0);

  // Footer component for totals
  const footer = () => {
    const isAllSelected =
      selectedLoanStatus === "REJECTED" ||
      selectedLoanStatus === "PENDING" ||
      selectedLoanStatus === "APPROVED" ||
      selectedLoanStatus === "COMPLETE";

    // Get approved and completed amounts total
    const isApprovedOrCompleteSelected = selectedLoanStatus === "All";

    return (
      <div className="space-y-1">
        {/* Loan Amount Total */}
        {isAllSelected && (
          <div className="xxs:flex w-[100%] max-w-96 justify-between">
            <div className="xxs:w-44">
              <Description
                content="Loan Amount Total "
                fontWeight="font-bold"
              />
              <Description
                content={`(${selectedLoanStatus})`}
                fontSize="text-h9"
                fontColor={"text-gray-400"}
              />
            </div>
            <Description content={`Rs. ${totalLoanAmount.toFixed(2)}`} />
          </div>
        )}

        {/* Get approved and completed amounts */}
        {isApprovedOrCompleteSelected && (
          <div className="xxs:flex w-[100%] max-w-96 justify-between">
            <div className="xxs:w-44">
              <Description
                content="Loan Amount Total "
                fontWeight="font-bold"
              />
              <Description
                content=" (APPROVED, COMPLETE ) "
                fontSize="text-h9"
                fontColor={"text-gray-400"}
              />
            </div>
            <Description
              content={`Rs. ${(
                approvedLoanAmount + completedLoanAmount
              ).toFixed(2)}`}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {" "}
      {/* mobile view filters  */}
      <div className="md:hidden">
        <div className="grid xxs:gap-3 xxs:grid-cols-2 ">
          {/* filter guarantee by collectors */}
          <Dropdown
            label="Loan Status"
            fontSize="text-h9"
            fontColor={"text-gray-400"}
            options={loanStatus}
            selectedOption={selectedLoanStatus}
            onOptionSelect={(option: string) => setSelectedLoanStatus(option)}
            placeholder="Loan Status"
            // width="w-[100%] xxs:max-w-60"
          />
          {/* filter guarantee by customer */}
          <Dropdown
            label="Collector"
            fontSize="text-h9"
            fontColor={"text-gray-400"}
            options={collectors}
            selectedOption={selectedCollector}
            onOptionSelect={handleCollectorSelect}
            placeholder="Collector"
            // width="w-[100%] md:max-w-60"
          />
        </div>
        {/* filter guarantors */}
        <div>
          <Input
            id={"filterCustomers"}
            name={"filterCustomers"}
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
            width="w-[100%] xxs:max-w-60 -mt-[2px]"
          />
        </div>
      </div>
      {/* web view filters  */}
      <div className="flex items-center justify-between max-md:hidden">
        <div className="w-2/3 space-x-3 ">
          {/* filter guarantee by collectors */}
          <Dropdown
            label="Loan Status"
            fontSize="text-h9"
            fontColor={"text-gray-400"}
            options={loanStatus}
            selectedOption={selectedLoanStatus}
            onOptionSelect={(option: string) => setSelectedLoanStatus(option)}
            placeholder="Loan Status"
            width="w-[100%] xxs:max-w-48"
          />

          {/* filter guarantee by customer */}
          <Dropdown
            label="Collector"
            fontSize="text-h9"
            fontColor={"text-gray-400"}
            options={collectors}
            selectedOption={selectedCollector}
            onOptionSelect={handleCollectorSelect}
            placeholder="Collector"
            width="w-[100%] md:max-w-48"
          />
        </div>
        {/* filter guarantors */}
        <div>
          <Input
            id={"filterCustomers"}
            name={"filterCustomers"}
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
            width="w-[100%] xxs:max-w-60 mt-4"
          />
        </div>
      </div>
      {/* Filter by loan status and search */}
      <div className="justify-between gap-2 xxs:flex"></div>
      {/* Table */}
      <Form form={form} component={false}>
        <Table
          scroll={{ x: "max-content" }} // Ensure the table scrolls horizontally if needed
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={filteredData}
          columns={mergedColumns}
          rowClassName="editable-row"
          className="font-semibold"
          footer={footer}
          locale={{
            emptyText: <Empty description={<span>No data available</span>} />,
          }}
        />
      </Form>
      {/* qr generate */}
      <QRGenerate
        visible={qrModalVisible}
        onClose={handleQRModalClose}
        customerId={String(selectedCustomerDetails?.customerId) || ""}
        customerName={selectedCustomerDetails?.fullName || ""}
        customerAddress={selectedCustomerDetails?.address || ""}
        loanStartDate={
          selectedCustomerDetails?.loan[0].startDate
            ? new Date(selectedCustomerDetails.loan[0].startDate)
                .toISOString()
                .slice(0, 10)
            : ""
        }
        loneAmount={String(selectedCustomerDetails?.loan[0].loanAmount) || ""}
        numberOfInstallment={
          selectedCustomerDetails?.loan[0].loanDuration || ""
        }
        paymentPlan={selectedCustomerDetails?.loan[0].repaymentFrequency || ""}
        installmentAmount={
          String(selectedCustomerDetails?.loan[0].installmentAmount) || ""
        }
        imgUrl={selectedCustomerDetails?.customerImageURL || ""}
        customerNIC={selectedCustomerDetails?.nic || ""}
      />
      {/* schedule list  */}
      <ScheduleListModal
        visible={isOpenCScheduleList}
        customerNic={getCustomerNIC}
        onClose={handleCloseScheduleMOdal}
        customerName={getCustomerName}
        customerAddress={getCustomerAddress}
        loanID={getCustomerLoanId}
      />
    </div>
  );
};

export default A_AllCustomersTable;
