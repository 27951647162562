import { useEffect, useState } from "react";
import { getCurrentUser } from "../api/auth";

export const useGetUserName = () => {
  const [userName, setUserName] = useState<string | null>(null);

  const fetchData = async () => {
    const token = localStorage.getItem("token") || "";
    try {
      const userData = await getCurrentUser(token);

      setUserName(userData.data.username);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { userName, fetchData };
};
