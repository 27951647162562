import { z } from "zod";

export const registerSchema = z
  .object({
    firstName: z
      .string()
      .max(255, "Maximum should be 255 characters")
      .regex(
        /^[A-Za-z\u0D80-\u0DFF\s]+$/,
        "firstName must not contain special letters and numbers"
      )
      .nonempty("First name is required"),
    lastName: z
      .string()
      .max(255, "Maximum should be 255 characters")
      .regex(
        /^[A-Za-z\u0D80-\u0DFF\s]+$/,
        "lastName must not contain special letters and numbers"
      )
      .nonempty("Last name is required"),
    username: z
      .string()
      .nonempty("Username is required")
      .min(3, "Minimum should be 3 characters")
      .max(20, "Maximum should be 20 characters"),
    email: z
      .string()
      .email("Invalid email format")
      .max(254, "Maximum should be 254 characters")
      .nonempty("Email is required")
      .refine((value) => /\S+@\S+\.\S+/.test(value), {
        message: "Invalid email format",
      }),
    contactNumber: z
      .string()
      .regex(/^\d+$/, "must contain only numbers")
      .min(10, "Phone number must be 10 digits")
      .max(10, "Phone number must be 10 digits")
      .nonempty("required"),
    role: z.string().nonempty("Role is required"),
    supervisedBy: z.string().optional(),
    password: z
      .string()
      .nonempty("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .max(16, "Password must be at most 16 characters long")
      .refine((value) => /[A-Z]/.test(value), {
        message: "Password must contain at least one uppercase letter",
      })
      .refine((value) => /\d/.test(value), {
        message: "Password must contain at least one number",
      })
      .refine((value) => /[!@#$%^&*(),.?":{}|<>]/.test(value), {
        message: "Password must contain at least one symbol",
      }),
    confirmPassword: z.string().nonempty("Confirm password is required"),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"], // Attach the error message to the confirmPassword field
  })
  .refine(
    (data) => {
      // Conditionally require `supervisedBy` if `role` is "COLLECTOR"
      if (data.role === "COLLECTOR" && !data.supervisedBy) {
        return false;
      }
      return true;
    },
    {
      message: "Supervisor is required for COLLECTOR role",
      path: ["supervisedBy"], // Attach the error message to the supervisedBy field
    }
  );
