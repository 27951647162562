import { z } from "zod";

export const userUpdateSchema = z.object({
  firstName: z.object({
    value: z
      .string()
      .max(255, "Maximum should be 255 characters")
      .regex(
        /^[A-Za-z\u0D80-\u0DFF\s]+$/,
        "firstName must not contain special letters and numbers"
      )
      .nonempty("First name is required")
      .trim(),
  }),
  lastName: z.object({
    value: z
      .string()
      .max(255, "Maximum should be 255 characters")
      .regex(
        /^[A-Za-z\u0D80-\u0DFF\s]+$/,
        "lastName must not contain special letters and numbers"
      )
      .nonempty("Last name is required")
      .trim(),
  }),
  email: z.object({
    value: z
      .string()
      .email("Invalid email format")
      .max(254, "Maximum should be 254 characters")
      .nonempty("Email is required")
      .trim()
      .refine((value) => /\S+@\S+\.\S+/.test(value), {
        message: "Invalid email format",
      }),
  }),
  contactNumber: z.object({
    value: z
      .string()
      .regex(/^\d+$/, "must contain only numbers")
      .min(10, "Phone number must be 10 digits")
      .max(10, "Phone number must be 10 digits")
      .nonempty("required")
      .trim(),
  }),
  username: z.object({
    value: z
      .string()
      .nonempty("Username is required")
      .max(20, "Maximum should be 20 characters")
      .min(3, "Minimum should be 3 characters")
      .trim(),
  }),
});
