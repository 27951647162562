import React, { useEffect, useState } from "react";
import type { TableProps } from "antd";
import { Empty, Form, Table, Typography } from "antd";
import { AddNewInterestRate, Button, PrimaryModal, Title } from "../../common";
import {
  addInterestRate,
  deleteinterestRate,
  getIntrestRates,
} from "../../../api/loan";
import toast from "react-hot-toast";

interface Item {
  rateId: number;
  id: number;
  rate: number;
}

const A_SystemRegularRates = () => {
  const [form] = Form.useForm();
  const [interestRates, setInterestRates] = useState<Item[]>([]);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [selectedInterestid, setIsSelectedInterestId] = useState(0);
  const [isOpenAddNewInterestModal, setIsOpenAddNewInterestModal] =
    useState(false);
  const [newRate, setNewRate] = useState<number>(0);

  // handle open confrim modal
  const handleOpenConfirmModal = (id: number) => {
    setIsSelectedInterestId(id);
    setIsOpenConfirmModal(true);
  };

  // handle delete intererst rate
  const handlenaDeleteInterestRate = async () => {
    const token = localStorage.getItem("token") || "";
    const rateId = selectedInterestid || 0;

    try {
      await deleteinterestRate(token, rateId);
      toast.success("Delete Successfull.");
      setIsOpenConfirmModal(false);
      handleGetInterestRates();
    } catch (error) {
      console.log(error);
    }
  };

  // Function to fetch interest rates from API
  const handleGetInterestRates = async () => {
    const token = localStorage.getItem("token") || "";
    try {
      const response = await getIntrestRates(token);
      if (response.success) {
        // Assuming the response is an array of rates
        const formattedRates = response.data.map(
          (item: Item, index: number) => ({
            rateId: index + 1,
            rate: item.rate,
            id: item.id,
          })
        );
        setInterestRates(formattedRates);
      } else {
        console.log("No interest rates available");
      }
    } catch (error) {
      console.error("Failed to fetch interest rates", error);
    }
  };
  useEffect(() => {
    handleGetInterestRates();
  }, []);

  const columns = [
    {
      title: "Interest Rate",
      dataIndex: "rate",
      align: "center" as const,
      sorter: (a: Item, b: Item) => (a.rate || 0) - (b.rate || 0),
    },
    {
      title: "Delete",
      dataIndex: "id",
      align: "center" as const,
      width: 150,
      render: (_: number, record: Item) => {
        return (
          <div className="flex justify-center">
            <Typography.Link>
              <div
                className="flex justify-center px-4 font-semibold text-center text-green-600 rounded active:shadow-inner-hard active:text-primaryColor-900"
                onClick={() => handleOpenConfirmModal(record.id)}
              >
                Delete
              </div>
            </Typography.Link>
          </div>
        );
      },
    },
  ];

  const mergedColumns: TableProps["columns"] = columns.map((col) => {
    if (!col) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "number" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  // handle add new interest rate
  const handleAddNewInterestRate = async () => {
    const token = localStorage.getItem("token") || "";
    if (newRate !== 0) {
      try {
        const response = await addInterestRate(token, newRate);
        if (response.success) {
          toast.success(response.message);
          setNewRate(0);
          setIsOpenAddNewInterestModal(false);
          handleGetInterestRates();
        } else {
          toast.error(response.response.data.message);
        }
      } catch (error) {
        console.log("Error:", error);
      }
    } else {
      toast.error("Enter New Rate!");
    }
  };

  // handle change for newRate
  const handleChangeNewRate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Check if the input is empty
    if (value === "") {
      setNewRate(0);
    } else {
      const parsedValue = parseFloat(value);
      // Set newRate only if it's within the valid range (0 to 100)
      if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 100) {
        setNewRate(parsedValue);
      }
    }
  };

  // handle close add new rate modal
  const handleCloseModal = () => {
    setIsOpenAddNewInterestModal(false);
    setNewRate(0);
  };

  return (
    <div className="mt-16 ">
      {/* main title  */}
      <Title title={"Regular Rates"} bgStyle={false} className="text-center" />
      {/* Table */}
      <div className="max-w-[500px] mx-auto">
        <Form form={form} component={false}>
          <Table
            scroll={{ x: "max-content" }}
            bordered
            dataSource={interestRates}
            columns={mergedColumns}
            className="font-semibold"
            locale={{
              emptyText: <Empty description={<span>No data available</span>} />,
            }}
          />
        </Form>

        {/* add button  */}
        <div
          className={`${interestRates.length === 0 ? "mt-2" : "-mt-12"} relative w-40`}
        >
          <Button
            text={"Add Interest"}
            fontSize="text-h8"
            width="px-2"
            onClick={() => setIsOpenAddNewInterestModal(true)}
          />
        </div>
      </div>

      {/* delete confirm modal  */}
      <PrimaryModal
        visible={isOpenConfirmModal}
        mainTitle={"Delete Interest Rate"}
        content={"Are you sure delele this interest rate?"}
        primaryBtnLabel="Yes"
        primaryOnClick={handlenaDeleteInterestRate}
        secondaryBtnLabel="No"
        secondaryOnClick={() => setIsOpenConfirmModal(false)}
        onClose={() => setIsOpenConfirmModal(false)}
      />

      {/* add new interest modal  */}
      <AddNewInterestRate
        visible={isOpenAddNewInterestModal}
        onClose={handleCloseModal}
        value={newRate.toString()}
        handleAddNewInterestRate={handleAddNewInterestRate}
        handleChangeNewRate={handleChangeNewRate}
      />
    </div>
  );
};

export default A_SystemRegularRates;
