import React, { useState, useEffect } from "react";
import { IoIosNotificationsOutline, IoMdNotifications } from "react-icons/io";
import { A_Sidebar, C_Sidebar, M_Sidebar } from "./Sidebars";
import { getCurrentUser } from "../../api/auth";
import { Description, NotificationCard } from "../common";
import {
  getNotifications,
  getReadNotifications,
  isReadNotification,
  markAllNotificationsAsRead,
} from "../../api/notification";
import { useNavigate, useLocation } from "react-router-dom";
import {
  CashReleaseCusOverview,
  CustomerPassword,
  QrGenerateRelease,
} from "../modal";
import ReleasedConformationModal from "../common/modals/ReleasedConformationModal";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { storage } from "../../lib/configs/firebaseConfig";
import toast from "react-hot-toast";
import {
  getAllLoans,
  getAssignedManagerCollector,
  relaseFunds,
} from "../../api/loan";
import {
  Item,
  notificationTypes,
  userDataTypes,
} from "../../types/headerTypes";
import { getCustomerforLoanReview } from "../../api/customer";
import dayjs, { Dayjs } from "dayjs";

// Helper to upload a file to Firebase and return its download URL
const uploadImageToFirebase = async (imageUrl: string, imagePath: string) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const storageRef = ref(storage, imagePath);
    await uploadBytes(storageRef, blob);
    const downloadUrl = await getDownloadURL(storageRef);
    return downloadUrl;
  } catch (error) {
    console.error("Image upload failed:", error);
    throw error;
  }
};

// Helper to delete an image from Firebase
const deleteImageFromFirebase = async (imagePath: string) => {
  const storageRef = ref(storage, imagePath);
  try {
    await deleteObject(storageRef);
  } catch (error) {
    console.error(`Failed to delete image: ${imagePath}`, error);
  }
};

const Header: React.FC = () => {
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [ProfilePic, setProfilePic] = useState("");
  const [Sidebars, setSidebars] = useState<JSX.Element | null>(null);
  const [userData, setUserData] = useState<userDataTypes>();
  const [userRole, setUserRole] = useState<string | null>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [notifications, setNotifications] = useState<notificationTypes[]>([]);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationsLength, setNotificationLength] = useState(0);
  const [selectedOption, setSelectedOption] = useState("unread");
  const [getAllReadNotification, setGetAllReadNotification] = useState<
    notificationTypes[]
  >([]);
  const [overviewVisible, setOverviewVisible] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [isSuccessRelease, setIsSuccessRelease] = useState(false);
  const [selectedLoanId, setSelectedLoanId] = useState<number | null>(null);
  const [SelectedCustomerId, setSelectedCustomerId] = useState<number | null>(
    null
  );
  const location = useLocation();
  const [yesLoading, setYesLoading] = useState(false);
  const [yesDisabled, setYesDisabled] = useState(false);
  const [customerPassword, setCustomerPassword] = useState<string>("");
  const [qrModalVisible, setQrModalVisible] = useState<boolean>(false);
  const [customersData, setCustomersData] = useState<Item[]>([]);
  const [assignCollectorId, setassignCollectorId] = useState<number | null>(
    null
  );
  const [assignManagerId, setassignManagerId] = useState<number | null>(null);
  const [passwordModalOpen, setPassowrdModalOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());

  // Function to handle date changes, keeping selectedDate as a Dayjs object
  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      // Get the current time with microseconds
      const currentTimeWithMicroseconds = dayjs().format("HH:mm:ss.SSSSSS");

      // Format the updatedDate with the desired format
      const updatedDate = dayjs(
        newDate.format("YYYY-MM-DD") + "T" + currentTimeWithMicroseconds
      );

      setSelectedDate(updatedDate); // Keep updatedDate as a Dayjs object
    }
  };

  const fetchData = async () => {
    const token = localStorage.getItem("token") || "";
    const Id = localStorage.getItem("userId") || "";
    const userId = parseInt(Id);
    try {
      const response = await getAllLoans(userId, token);

      const sortedData = response.data.sort(
        (a: Item, b: Item) => b.loanId - a.loanId
      );
      setCustomersData(sortedData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Close the notification dropdown when the route changes
    setIsNotificationOpen(false);
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token") || "";
      try {
        const userData = await getCurrentUser(token);
        setUserData(userData);
        setUserRole(userData.data.role);
        localStorage.setItem("userId", userData.data.userId);

        setUserName(userData.data.username);

        // Set sidebar and profile picture based on role
        switch (userData.data.role) {
          case "ADMIN":
            setSidebars(<A_Sidebar />);
            setProfilePic("/images/admin.png");
            break;
          case "MANAGER":
            setSidebars(<M_Sidebar />);
            setProfilePic("/images/manager.png");
            break;
          case "COLLECTOR":
            setSidebars(<C_Sidebar />);
            setProfilePic("/images/collector.png");
            break;
          default:
            console.log("Can't Identify User!");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();

    const updateDateTime = () => {
      const now = new Date();
      setCurrentDate(
        now.toLocaleDateString(undefined, {
          weekday: "long",
          day: "numeric",
          month: "long",
        })
      );
      setCurrentTime(
        now.toLocaleTimeString(undefined, {
          hour: "numeric",
          minute: "numeric",
        })
      );
    };

    updateDateTime();
    const intervalId = setInterval(updateDateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchNotifications = async () => {
      const token = localStorage.getItem("token") || "";
      const userId = localStorage.getItem("userId");

      try {
        const response = await getNotifications(token, Number(userId));
        // Filter out only unread notifications
        const fetchedNotifications = response.filter(
          (notification: { read: boolean }) => !notification.read
        );

        setNotifications(fetchedNotifications);
        setNotificationLength(fetchedNotifications.length);

        // Update unread notification count
        setHasUnreadNotifications(fetchedNotifications.length > 0);
      } catch (error) {
        console.log("Error fetching notifications:", error);
      }
    };

    if (userRole) {
      fetchNotifications();
    }
  }, [userRole, notifications.length]); // Add notifications.length for real-time updates

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const dropdown = document.querySelector(".notification-dropdown");
      const notificationIcon = document.querySelector(".Frame");

      // Check if the click is outside the dropdown and icon, and close the dropdown if so
      if (
        dropdown &&
        !dropdown.contains(event.target as Node) &&
        !notificationIcon?.contains(event.target as Node)
      ) {
        setIsNotificationOpen(false);
      }
    };

    if (isNotificationOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isNotificationOpen]);

  // Handle marking notification as read
  const isReadNotifi = async (
    id: number,
    customerId: number,
    type: string,
    managerId: number,
    collectorId: number
  ) => {
    const token = localStorage.getItem("token") || "";

    setSelectedCustomerId(customerId);

    try {
      const response = await getCustomerforLoanReview(customerId, token);
      if (response.success) {
        try {
          await isReadNotification(id, token);
          const updatedNotifications = notifications.filter(
            (notification) => notification.id !== id
          );
          setNotifications(updatedNotifications);

          // Update unread notifications count
          setNotificationLength(updatedNotifications.length);
          setHasUnreadNotifications(updatedNotifications.length > 0);
          if (response.data.loan[0].loanReleaseStatus) {
            navigate(`/customer-update-overview/${customerId}`);
          } else {
            if (type === "Registered") {
              navigate(
                `/approve-overview/${customerId}?CAU=${collectorId}&MAU=${managerId}`
              );
            } else {
              if (
                (type === "Approved" && userRole === "ADMIN") ||
                userRole === "MANAGER"
              ) {
                setOverviewVisible(true);
              } else {
                navigate(`/customer-update-overview/${customerId}`);
              }
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Mark all notifications as read and close dropdown
  const markAllAsRead = async () => {
    const token = localStorage.getItem("token") || "";
    const userId = Number(localStorage.getItem("userId"));

    try {
      await markAllNotificationsAsRead(userId, token);
      setNotifications([]);
      setHasUnreadNotifications(false);
      setIsNotificationOpen(false); // Close the dropdown
    } catch (error) {
      console.log(error);
    }
  };

  // handle get all read notifications

  const handleGetAllReadNotifications = async () => {
    const token = localStorage.getItem("token") || "";
    const userId = Number(localStorage.getItem("userId"));

    try {
      const response = await getReadNotifications(token, userId);
      setGetAllReadNotification(response);
    } catch (error) {
      console.log("Error fetching notifications:", error);
    }
  };
  useEffect(() => {
    handleGetAllReadNotifications();
  }, []);

  // handle release funds with loading and disabling button
  const handleReleaseFunds = async () => {
    setYesLoading(true);
    setYesDisabled(true);

    const customerSignImgUrl = localStorage.getItem("customerSignature");
    const employeeSignatureurl = localStorage.getItem("employeeSignature");
    const customerPassord = localStorage.getItem("password");
    const token = localStorage.getItem("token") || "";
    const managerIdString = localStorage.getItem("userId");

    setCustomerPassword(customerPassord || "");

    if (!managerIdString) {
      console.error("Manager ID is not found in localStorage");
      return;
    }

    const managerId = Number(managerIdString);
    if (isNaN(managerId)) {
      console.error("Manager ID is not a valid number");
      return;
    }

    if (selectedLoanId === null) {
      console.error("No loan selected for release");
      return;
    }

    if (!customerSignImgUrl || !employeeSignatureurl) {
      console.error("Signatures not found in localStorage");
      return;
    }

    const customerSignaturePath = `signatures/customer/${selectedLoanId}-customer-signature.png`;
    const employeeSignaturePath = `signatures/employee/${selectedLoanId}-employee-signature.png`;

    try {
      // Upload customer signature
      const customerDownloadUrl = await uploadImageToFirebase(
        customerSignImgUrl,
        customerSignaturePath
      );

      // Upload employee signature
      const employeeDownloadUrl = await uploadImageToFirebase(
        employeeSignatureurl,
        employeeSignaturePath
      );

      const customerSignatureImgUrl = customerDownloadUrl;
      const employeeSignatureImgUrl = employeeDownloadUrl;
      const password = customerPassord || "";

      const response = await relaseFunds(
        selectedLoanId,
        managerId,
        token,
        customerSignatureImgUrl,
        employeeSignatureImgUrl,
        password,
        selectedDate.format("YYYY-MM-DDTHH:mm:ss.SSSSSS")
      );
      if (response.success) {
        setIsSuccessRelease(true);
        toast.success(response.message);
        setQrModalVisible(true);
      } else {
        await deleteImageFromFirebase(customerSignaturePath);
        await deleteImageFromFirebase(employeeSignaturePath);
        toast.error(response.message);
      }

      await fetchData();
    } catch (error) {
      console.error("Error during release:", error);

      await deleteImageFromFirebase(customerSignaturePath);
      await deleteImageFromFirebase(employeeSignaturePath);

      toast.error("Failed to release funds.");
    } finally {
      localStorage.removeItem("customerSignature");
      localStorage.removeItem("employeeSignature");
      setYesLoading(false);
      setYesDisabled(false);
      setModalVisible(false);
    }
  };

  const handleOpenNotifications = () => {
    setIsNotificationOpen(!isNotificationOpen);
    handleGetAllReadNotifications();
  };

  const handleOverviewClose = () => {
    setOverviewVisible(false);
    setSelectedLoanId(null);
  };

  const handlePrimaryModalClose = () => {
    setModalVisible(false);
  };

  // handle close wr modal and open passowrd modal
  const handleCloseQrOpenPassword = () => {
    setQrModalVisible(false);
    setPassowrdModalOpen(true);
  };

  const handleQRModalClose = () => {
    setQrModalVisible(false);
    setPassowrdModalOpen(true);
    setSelectedLoanId(null);
  };

  const handleClosePasswordModal = () => {
    setPassowrdModalOpen(false);
    localStorage.removeItem("password");
  };

  useEffect(() => {
    const getManagerAndCollector = async () => {
      const token = localStorage.getItem("token") || "";
      if (SelectedCustomerId !== 0) {
        // get manager and collector
        try {
          const response = await getAssignedManagerCollector(
            token,
            Number(SelectedCustomerId)
          );

          setassignManagerId(response.data.loanDetailsList[0].managerAppUserId);
          setassignCollectorId(
            response.data.loanDetailsList[0].collectorAppUserId
          );
          setSelectedLoanId(response.data.loanDetailsList[0].loanId);
        } catch {
          console.log("no data available");
        }
      }
    };

    getManagerAndCollector();
  }, [SelectedCustomerId]);

  return (
    <div className="sticky top-0 z-50 flex items-center bg-white shadow-lg">
      <div className="z-50 ml-3 duration-300 hover:shadow-inner hover:bg-gray-200 rounded-xl">
        {Sidebars}
      </div>
      <div className="flex items-center justify-end w-[100%] p-4 bg-white">
        <div className="flex items-center md:space-x-4">
          <div className="hidden text-gray-500 md:block">{currentDate}</div>
          <div className="h-4 mx-2 border-[1px] border-gray-500 rounde hidden md:block"></div>
          <div className="hidden text-gray-500 md:block">{currentTime}</div>

          <div
            className="relative mr-5 text-2xl cursor-pointer md:text-3xl sm:mr-10 Frame"
            onClick={handleOpenNotifications}
          >
            {hasUnreadNotifications ? (
              <div>
                <span className="absolute z-10 flex items-center justify-center w-5 h-5 ml-4 font-semibold border-2 border-white rounded-full text-h8 bg-primaryColor-200">
                  {notificationsLength}
                </span>
                <IoMdNotifications className="animate-pulse " />
              </div>
            ) : (
              <IoIosNotificationsOutline />
            )}

            {isNotificationOpen && (
              <div className="absolute items-center mt-8 overflow-y-auto bg-white rounded-md shadow-lg w-60 -right-36 md:right-0 md:mt-7 md:w-80 max-h-64 notification-dropdown">
                <div>
                  <span className="absolute z-10 flex items-center gap-2 ml-3 font-semibold text-h8 notification-buttons">
                    <div
                      className={`${selectedOption === "unread" ? "text-primaryColor-600 font-bold" : "text-primaryColor-200"}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedOption("unread");
                      }}
                    >
                      Unread
                    </div>
                    <div
                      className={`${selectedOption === "read" ? "text-primaryColor-600 font-bold" : "text-primaryColor-200"}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedOption("read");
                      }}
                    >
                      Read
                    </div>
                  </span>

                  <div
                    onClick={markAllAsRead}
                    className={`mr-3 -mb-2 font-semibold text-right text-gray-400 duration-300 text-h8 hover:text-zinc-900 ${selectedOption === "unread" && notifications.length > 0 ? "" : "hidden"}`}
                  >
                    Mark Read
                  </div>
                </div>
                <div
                  className={` ${selectedOption === "unread" && notifications.length > 0 ? "" : "mb-7"}`}
                />
                {/* unread notifications  */}
                {notifications.length > 0 ? (
                  notifications.map((notification) => (
                    <div
                      key={notification.id}
                      onClick={() =>
                        isReadNotifi(
                          notification.id,
                          notification.customerId,
                          notification.type,
                          notification.collectorId,
                          notification.managerId
                        )
                      }
                      className={`${selectedOption === "unread" ? "" : "hidden"}`}
                    >
                      <NotificationCard
                        title={notification.type}
                        description={notification.message}
                        dateTime={notification.createdAt}
                      />
                    </div>
                  ))
                ) : (
                  <div
                    className={`p-4 text-center ${selectedOption === "read" ? "hidden" : ""}`}
                  >
                    <Description
                      content="You have no new notifications."
                      fontColor="text-gray-400"
                      center
                    />
                  </div>
                )}
                {/* read notifications  */}
                {getAllReadNotification.length > 0 ? (
                  getAllReadNotification.map((getAllReadNotification) => (
                    <div
                      key={getAllReadNotification.id}
                      onClick={() =>
                        isReadNotifi(
                          getAllReadNotification.id,
                          getAllReadNotification.customerId,
                          getAllReadNotification.type,
                          getAllReadNotification.managerId,
                          getAllReadNotification.collectorId
                        )
                      }
                      className={` text-gray-200 ${selectedOption === "read" ? " " : "hidden"}`}
                    >
                      <NotificationCard
                        title={getAllReadNotification.type}
                        description={getAllReadNotification.message}
                        dateTime={getAllReadNotification.createdAt}
                      />
                    </div>
                  ))
                ) : (
                  <div
                    className={`p-4 text-center ${selectedOption === "unread" ? "hidden" : ""}`}
                  >
                    <Description
                      content="You have no new notifications."
                      fontColor="text-gray-400"
                      center
                    />
                  </div>
                )}
              </div>
            )}
          </div>

          {userData && (
            <div className="px-3 pb-1 rounded-lg bg-primaryColor-50 md:bg-white md:pb-0">
              <div className="text-right">
                <div className="EmployeeName text-black text-lg font-bold font-['Poppins'] leading-relaxed">
                  {userName}
                </div>
                <div className="role text-neutral-500 text-xs font-normal font-['Poppins'] leading-none">
                  {userRole}
                </div>
              </div>
            </div>
          )}

          {userData && (
            <div>
              <img src={ProfilePic} alt="User Logo" />
            </div>
          )}
        </div>
      </div>
      {/* cash release overview  */}
      <CashReleaseCusOverview
        visible={overviewVisible}
        onClose={handleOverviewClose}
        primaryBtnLabel="Release"
        primaryOnClick={() => {
          setOverviewVisible(false);
          setModalVisible(true);
          setIsSuccessRelease(false);
        }}
        secondaryOnClick={handleOverviewClose}
        userDetails={SelectedCustomerId || 0}
      />

      {/* confirm message  */}
      <ReleasedConformationModal
        visible={modalVisible}
        onClose={handlePrimaryModalClose}
        mainTitle="Confirm Release"
        content="Are you sure you want to release funds for this loan?"
        primaryBtnLabel="Yes"
        secondaryBtnLabel="No"
        primaryOnClick={handleReleaseFunds}
        secondaryOnClick={handlePrimaryModalClose}
        collectorId={assignCollectorId ? assignCollectorId : 0}
        managerId={assignManagerId ? assignManagerId : 0}
        loanId={selectedLoanId ? selectedLoanId : 0}
        yesDisabled={yesDisabled}
        yesLoading={yesLoading}
        isSuccessRelease={isSuccessRelease}
        selectedDate={selectedDate}
        handleDateChange={handleDateChange}
      />

      {/* qr generate */}
      <QrGenerateRelease
        visible={qrModalVisible}
        onClose={handleQRModalClose}
        customerId={selectedLoanId?.toString() || ""}
        customerName={
          customersData.find((item) => item.loanId === selectedLoanId)
            ?.customerName || ""
        }
        customerAddress={
          customersData.find((item) => item.loanId === selectedLoanId)
            ?.customerAddress || ""
        } // Pass the actual address here
        loanStartDate={
          customersData.find((item) => item.loanId === selectedLoanId)
            ?.startDate || ""
        }
        loneAmount={
          customersData
            .find((item) => item.loanId === selectedLoanId)
            ?.loanAmount.toString() || ""
        }
        numberOfInstallment={
          customersData
            .find((item) => item.loanId === selectedLoanId)
            ?.loanDuration.toString() || ""
        }
        paymentPlan={
          customersData.find((item) => item.loanId === selectedLoanId)
            ?.repaymentFrequency || ""
        }
        installmentAmount={
          customersData
            .find((item) => item.loanId === selectedLoanId)
            ?.loanInstallments.toString() || ""
        }
        imgUrl={
          customersData.find((item) => item.loanId === selectedLoanId)
            ?.customerImageURL || ""
        }
        customerNIC={
          customersData.find((item) => item.loanId === selectedLoanId)
            ?.customerNIC || ""
        }
        closeModal={handleCloseQrOpenPassword}
      />

      {/* customer password modal  */}
      <CustomerPassword
        visible={passwordModalOpen}
        onClose={handleClosePasswordModal}
        customerNIC={
          customersData.find((item) => item.loanId === selectedLoanId)
            ?.customerNIC || ""
        }
        customerUserNameValue={
          customersData.find((item) => item.loanId === selectedLoanId)
            ?.customerNIC || ""
        }
        closeModal={handleClosePasswordModal}
        customerPassword={customerPassword}
      />
    </div>
  );
};

export default Header;
