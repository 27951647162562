import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Button,
  Description,
  Input,
  RadioButton,
  UploadImageInput,
} from "../../../common";
import { Step2Schema } from "../../../../lib/validation/customer-register-val";
import { z } from "zod";
import { storage } from "../../../../lib/configs/firebaseConfig";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import useImageCompressor from "../../../../hooks/useImageCompressor";
import { useGetCustomerDetails } from "../../../../hooks/useGetCustomerDetails";
import { useGetUserName } from "../../../../hooks/useGetUserName";

interface Step2Props {
  setSelect: (step: number) => void;
}
const Step2: React.FC<Step2Props> = ({ setSelect }) => {
  const { compressImage } = useImageCompressor();
  const location = useLocation();
  const [verificationMethod, setSelectedOption] = useState<string>("");

  const [borrowerIdUploadDropdown, setBorrowerIdUploadDropdown] =
    useState<boolean>(false);

  const [formData, setFormData] = useState<{
    verificationMethod: string;
    frontImageURL: string | null;
    backImageURL: string | null;
    customerImageURL: string | null;
    electricityBillImageURL: string | null;
    phoneNumber: string;
    email: string;
    optionalContactNumber: string;
    whatsAppNumber: string;
  }>({
    verificationMethod: "",
    frontImageURL: "",
    backImageURL: "",
    customerImageURL: "",
    electricityBillImageURL: "",
    phoneNumber: "",
    email: "",
    optionalContactNumber: "",
    whatsAppNumber: "",
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isFirstValidation, setIsFirstValidation] = useState(false);

  const [loadingImages, setLoadingImages] = useState<Record<string, boolean>>({
    frontImageURL: false,
    backImageURL: false,
    customerImageURL: false,
    electricityBillImageURL: false,
  }); // State to manage loading for each image

  const inputRefs = useRef<
    Record<string, HTMLInputElement | HTMLDivElement | null>
  >({
    verificationMethod: null,
    frontImageURL: null,
    backImageURL: null,
    customerImageURL: null,
    electricityBillImageURL: null,
    phoneNumber: null,
    email: null,
    optionalContactNumber: null,
    whatsAppNumber: null,
  });

  const { customerDetails } = useGetCustomerDetails();

  // select radio button
  const handleRadioButtonChange = (value: string) => {
    setSelectedOption(value);
    setFormData({
      ...formData,
      verificationMethod: value,
    });
    setErrors({ ...errors, verificationMethod: "" });
    setBorrowerIdUploadDropdown(true);
  };

  // Function to validate form data using Zod schema
  const validateForm = (data: typeof formData) => {
    try {
      Step2Schema.parse(data);
      setErrors({});
      return { isValid: true, newErrors: {} };
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          newErrors[err.path[0]] = err.message;
        });
        setErrors(newErrors);
        return { isValid: false, newErrors };
      }
      return { isValid: false, newErrors: {} };
    }
  };

  // Function to handle input changes and validate form on change
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (isFirstValidation) {
      validateForm({ ...formData, [name]: value });
    }
  };

  // Handle deletion of Firebase images when route changes
  useEffect(() => {
    const handleRouteChange = async () => {
      const currentPath = location.pathname;

      // If route is not customer-register or customer-register-overview, delete images from Firebase
      if (
        currentPath !== "/customer-register" &&
        currentPath !== "/customer-register-overview"
      ) {
        // Delete each uploaded image if the URL is not null
        if (formData.frontImageURL) {
          await deleteImageFromFirebase(formData.frontImageURL);
        }
        if (formData.backImageURL) {
          await deleteImageFromFirebase(formData.backImageURL);
        }
        if (formData.customerImageURL) {
          await deleteImageFromFirebase(formData.customerImageURL);
        }
        if (formData.electricityBillImageURL) {
          await deleteImageFromFirebase(formData.electricityBillImageURL);
        }

        // Remove images from localStorage
        localStorage.removeItem("customerRegisterFormData2");
      }
    };

    handleRouteChange();
  }, [location, formData]);

  // Function to delete image from Firebase
  const deleteImageFromFirebase = async (imageUrl: string) => {
    if (!imageUrl) return;

    const imageRef = ref(storage, imageUrl);
    try {
      await deleteObject(imageRef);
    } catch (_error) {
      toast.error("Failed to delete previous image.");
    }
  };

  const { fetchData, userName } = useGetUserName();

  // Function to upload image to Firebase and replace previous one
  const uploadImageToFirebase = async (
    file: File,
    id: string,
    previousImageUrl: string | null
  ) => {
    fetchData();

    setLoadingImages((prev) => ({ ...prev, [id]: true }));

    try {
      // Delete the previous image from Firebase if it exists
      if (previousImageUrl) {
        await deleteImageFromFirebase(previousImageUrl);
      }

      // Compress the image to reduce its size by 50%
      const compressedFile = await compressImage(file, 0.5);

      // If compression failed, exit the function early
      if (!compressedFile) {
        throw new Error("Image compression failed");
      }

      fetchData();

      // Upload new image to Firebase
      const storageRef = ref(storage, `userRegistration/${userName}_${id}.jpg`);
      await uploadBytes(storageRef, compressedFile);
      const downloadUrl = await getDownloadURL(storageRef);

      // Update formData with the new image URL
      setFormData((prevData) => ({
        ...prevData,
        [id]: downloadUrl,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: "",
      }));
    } catch (error) {
      console.error("Error uploading image:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: "Failed to upload image",
      }));
      toast.error("Failed to upload image");
    } finally {
      setLoadingImages((prev) => ({ ...prev, [id]: false })); // Set loading to false after upload
    }
  };

  const isFirebaseUrl = (url: string | null) => {
    return url ? url.includes("https://firebasestorage.googleapis.com") : false;
  };

  // Define 'id' to be one of the keys of formData
  const handleFileSelect = (file: File | null, id: keyof typeof formData) => {
    if (file) {
      const previousImageUrl = formData[id];
      // Skip uploading if the previous URL is a valid Firebase URL
      if (isFirebaseUrl(previousImageUrl)) {
        return;
      }
      uploadImageToFirebase(file, id, previousImageUrl);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [id]: "", // Clear the URL if no file is selected
      }));
    }
  };

  // Function to handle registration step1
  const handleNext = async () => {
    setIsFirstValidation(true);
    const { isValid, newErrors } = validateForm(formData);

    if (isValid) {
      localStorage.setItem(
        "customerRegisterFormData2",
        JSON.stringify(formData)
      );
      setSelect(3);
    } else {
      scrollToFirstError(newErrors);
    }
  };

  const scrollToFirstError = (newErrors: Record<string, string>) => {
    const orderedErrors: Record<string, string> = {};

    Object.keys(formData).forEach((key) => {
      if (newErrors[key]) {
        orderedErrors[key] = newErrors[key];
      }
    });

    const firstErrorKey = Object.keys(orderedErrors)[0];

    if (firstErrorKey && inputRefs.current[firstErrorKey]) {
      const element = inputRefs.current[firstErrorKey];
      const elementOffsetTop =
        element.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({
        top: elementOffsetTop - 80,
        behavior: "smooth",
      });
    }
  };

  // Populate formData from localStorage first, then from customerDetails
  useEffect(() => {
    const savedData = localStorage.getItem("customerRegisterFormData2");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setFormData((prevData) => ({ ...prevData, ...parsedData }));
      setBorrowerIdUploadDropdown(true);
      setSelectedOption(parsedData.verificationMethod);
    } else if (customerDetails) {
      const sanitizedDetails = Object.fromEntries(
        Object.entries(customerDetails).map(([key, value]) => [
          key,
          value === null ? "" : value,
        ])
      );
      setFormData((prevData) => ({ ...prevData, ...sanitizedDetails }));
    }
    if (customerDetails?.verificationMethod) {
      setBorrowerIdUploadDropdown(true);
    }
  }, [customerDetails]);

  const customKeyPressHandler = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "-" || event.key === "e") {
      event.preventDefault();
    }
  };

  return (
    <div className="space-y-3">
      <div ref={(el) => (inputRefs.current.verificationMethod = el)}>
        <Description
          content="අනන්‍යතාව සහතික කිරීම සඳහා පහත ඒවායින් එකක් තෝරාගන්න*"
          fontWeight="font-bold"
        />
      </div>

      <div className="flex flex-wrap gap-5 mt-3 lg:gap-10">
        <RadioButton
          id={"nic"}
          label={"ජාතික හැඳුනුම්පත"}
          checked={
            verificationMethod === "NIC" ||
            formData.verificationMethod === "NIC"
          }
          onChange={() => handleRadioButtonChange("NIC")}
        />
        <RadioButton
          id={"drivingLicense"}
          label={"රියදුරු බලපත්‍රය"}
          checked={
            verificationMethod === "DRIVER_LICENCE" ||
            formData.verificationMethod === "DRIVER_LICENCE"
          }
          onChange={() => handleRadioButtonChange("DRIVER_LICENCE")}
        />
        <RadioButton
          id={"passport"}
          label={"විදේශ ගමන් බලපත්‍රය"}
          checked={
            verificationMethod === "PASSPORT" ||
            formData.verificationMethod === "PASSPORT"
          }
          onChange={() => handleRadioButtonChange("PASSPORT")}
        />
      </div>
      {errors.verificationMethod && (
        <div className="font-normal text-left text-red-500 font-poppins text-[12px] md:text-[13px] ml-[1px] ">
          {errors.verificationMethod}
        </div>
      )}

      {borrowerIdUploadDropdown && (
        <div>
          <Description content=" ඡායාරූප අමුණන්න*" fontWeight="font-bold" />

          <div className="grid mb-3 xxs:gap-4 xxs:grid-cols-2">
            <UploadImageInput
              ref={(el) => (inputRefs.current.frontImageURL = el)}
              id={"frontImageURL"}
              name={"frontImageURL"}
              text={"ඉදිරිපස"}
              placeholder={"ඉදිරිපස"}
              onFileSelect={(file) => handleFileSelect(file, "frontImageURL")}
              selectImage={
                formData.frontImageURL ? `${formData.frontImageURL}` : ""
              }
              errorMessage={errors.frontImageURL}
              isLoading={loadingImages.frontImageURL}
            />

            <UploadImageInput
              ref={(el) => (inputRefs.current.backImageURL = el)}
              id={"backImageURL"}
              name={"backImageURL"}
              text={"පසුපස"}
              placeholder={"පසුපස"}
              onFileSelect={(file) => handleFileSelect(file, "backImageURL")}
              selectImage={
                formData.backImageURL ? `${formData.backImageURL}` : ""
              }
              errorMessage={errors.backImageURL}
              isLoading={loadingImages.backImageURL}
            />
          </div>
        </div>
      )}

      <div>
        <Description content="ණයගැනුම්කරුගෙ ඡායාරූපය*" fontWeight="font-bold" />

        <UploadImageInput
          ref={(el) => (inputRefs.current.customerImageURL = el)}
          id={"customerImageURL"}
          name={"customerImageURL"}
          text={" "}
          placeholder={""}
          onFileSelect={(file) => handleFileSelect(file, "customerImageURL")}
          errorMessage={errors.customerImageURL}
          selectImage={
            formData.customerImageURL ? `${formData.customerImageURL}` : ""
          }
          isLoading={loadingImages.customerImageURL} // Display loading animation
        />
      </div>

      <div>
        <Description
          content="ජල බිල හෝ විදුලි බිලෙහි පිටපතක්  අමුණන්න*"
          fontWeight="font-bold"
        />

        <UploadImageInput
          ref={(el) => (inputRefs.current.electricityBillImageURL = el)}
          id={"electricityBillImageURL"}
          name={"electricityBillImageURL"}
          text={""}
          placeholder={""}
          onFileSelect={(file) =>
            handleFileSelect(file, "electricityBillImageURL")
          }
          errorMessage={errors.electricityBillImageURL}
          selectImage={
            formData.electricityBillImageURL
              ? `${formData.electricityBillImageURL}`
              : ""
          }
          isLoading={loadingImages.electricityBillImageURL} // Display loading animation
        />
      </div>

      <div className="grid md:gap-3 md:grid-cols-2">
        <Input
          ref={(el) => (inputRefs.current.phoneNumber = el)}
          id={"phoneNumber"}
          name={"phoneNumber"}
          placeholder={"දුරකථන අංකය"}
          type="number"
          label="දුරකථන අංකය*"
          labelWeight="font-bold"
          onChange={handleChange}
          value={formData.phoneNumber}
          errorMessage={errors.phoneNumber}
          handleNegativeMark={customKeyPressHandler}
          restrictSpaces
        />

        <Input
          ref={(el) => (inputRefs.current.email = el)}
          id={"email"}
          name={"email"}
          placeholder={"ඊමේල් ලිපිනය"}
          label="ඊමේල් ලිපිනය*"
          labelWeight="font-bold"
          onChange={handleChange}
          value={formData.email}
          errorMessage={errors.email}
          restrictSpaces
        />
      </div>
      <div className="grid md:gap-3 md:grid-cols-2">
        <Input
          ref={(el) => (inputRefs.current.optionalContactNumber = el)}
          id={"optionalContactNumber"}
          name={"optionalContactNumber"}
          type="number"
          placeholder={"දුරකථන අංකය "}
          label="දුරකථන අංකය (අමතර)"
          labelWeight="font-bold"
          onChange={handleChange}
          value={formData.optionalContactNumber}
          errorMessage={errors.optionalContactNumber}
          handleNegativeMark={customKeyPressHandler}
          restrictSpaces
        />

        <Input
          ref={(el) => (inputRefs.current.whatsAppNumber = el)}
          id={"whatsAppNumber"}
          name={"whatsAppNumber"}
          placeholder={"වට්ස් ඇප්"}
          label="වට්ස් ඇප්*"
          type="number"
          labelWeight="font-bold"
          onChange={handleChange}
          value={formData.whatsAppNumber}
          errorMessage={errors.whatsAppNumber}
          handleNegativeMark={customKeyPressHandler}
          restrictSpaces
        />
      </div>

      <div className="flex justify-center">
        <Button text="ඊළඟ පිටුවට යන්න" onClick={handleNext} />
      </div>
    </div>
  );
};

export default Step2;
