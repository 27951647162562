import React, { useEffect, useState } from "react";
import type { TableProps } from "antd";
import { Empty, Form, Table, Typography } from "antd";
import { Dropdown, Input } from "../../common";
import { allEmployees } from "../../../api/employee";
import { useNavigate } from "react-router-dom";

interface Item {
  id: number;
  userId: string;
  username: string;
  contactNumber: string;
  role: string;
  allDetails?: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "number" | "text";
  record: Item;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        ></Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const A_EmployeeListTable = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [employeeData, setEmployeeData] = useState<Item[]>([]);
  const [selectedUserRole, setSelectedUserRole] = useState<string>("All");
  const [searchText, setSearchText] = useState<string>("");

  // handle navigate employee overview
  const handlenavigate = (userId: string) => {
    navigate(`/user-overview/${userId}`);
  };

  const columns = [
    {
      title: "User ID",
      dataIndex: "userId",
      fixed: "left" as const,
      align: "center" as const,
      sorter: (a: Item, b: Item) => Number(a.userId) - Number(b.userId),
    },
    {
      title: "User Name",
      dataIndex: "username",
      align: "center" as const,
      sorter: (a: Item, b: Item) => a.username.localeCompare(b.username),
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      align: "center" as const,
      sorter: (a: Item, b: Item) =>
        a.contactNumber.localeCompare(b.contactNumber),
    },
    {
      title: "User Role",
      dataIndex: "role",
      align: "center" as const,
      render: (text: string) => {
        let color = "text-black"; // Default color
        switch (text) {
          case "ADMIN":
            color = "text-black";
            break;
          case "MANAGER":
            color = "text-black";
            break;
          case "COLLECTOR":
            color = "text-black";
            break;
          case "UNASSIGNED":
            color = "text-gray-400"; // Light gray color for UNASSIGNED
            break;
          default:
            color = "text-black";
        }
        return <span className={`font-bold ${color}`}>{text}</span>;
      },
    },
    {
      title: "View",
      dataIndex: "allDetails",
      align: "center" as const,
      width: 150,
      render: (_: number, record: Item) => {
        return (
          <div className="flex justify-center">
            <Typography.Link>
              <div
                className="flex justify-center px-4 font-semibold text-center text-green-600 rounded active:shadow-inner-hard active:text-primaryColor-900"
                onClick={() => handlenavigate(record.userId)}
              >
                View
              </div>
            </Typography.Link>
          </div>
        );
      },
    },
  ];

  const mergedColumns: TableProps["columns"] = columns.map((col) => {
    if (!col) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === "number" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  // Fetch all employees data
  useEffect(() => {
    const getEmployeeData = async () => {
      const token = localStorage.getItem("token") || "";

      try {
        const response = await allEmployees(token);
        const sortedData = response.sort(
          (a: Item, b: Item) => Number(b.userId) - Number(a.userId)
        );
        setEmployeeData(sortedData);
      } catch (error) {
        console.log(error);
      }
    };

    getEmployeeData();
  }, []);

  const userRoles = ["All", "ADMIN", "MANAGER", "COLLECTOR", "UNASSIGNED"];

  // Filter data based on selected user role and search text
  const filteredData = employeeData
    .filter(
      (employee) =>
        selectedUserRole === "All" || employee.role === selectedUserRole
    )
    .filter((employee) => {
      const searchValue = searchText.toLowerCase();
      const username = employee.username.toLowerCase();
      const contactNumber = employee.contactNumber.toLowerCase();
      return (
        username.includes(searchValue) || contactNumber.includes(searchValue)
      );
    });

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  return (
    <div>
      {/* Filter user role and search */}
      <div className="justify-between gap-2 xxs:flex">
        <Dropdown
          label="User Role"
          fontSize="text-h9"
          fontColor={"text-gray-400"}
          options={userRoles}
          selectedOption={selectedUserRole}
          onOptionSelect={(option: string) => setSelectedUserRole(option)}
          placeholder="User Role"
          width="w-[100%] xxs:max-w-60"
        />

        <Input
          id={"filterEmployees"}
          name={"filterEmployees"}
          placeholder="Search"
          value={searchText}
          onChange={handleSearchChange}
          width="w-[100%] xxs:max-w-60 xxs:mt-[15px]"
        />
      </div>

      {/* Table */}
      <Form form={form} component={false}>
        <Table
          scroll={{ x: "max-content" }} // Ensure the table scrolls horizontally if needed
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={filteredData}
          columns={mergedColumns}
          rowClassName={(record) =>
            record.role === "UNASSIGNED" ? " text-gray-400" : ""
          }
          className="font-semibold"
          locale={{
            emptyText: <Empty description={<span>No data available</span>} />,
          }}
        />
      </Form>
    </div>
  );
};

export default A_EmployeeListTable;
